import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../services/danhmuc.service';
import { SuatVonDauTuService } from './../../services/suatvondautu.service';
import { MyService } from './../../services/my.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { CapnhatcongtrinhdienhinhComponent } from './NgbModal/capnhatcongtrinhdienhinh/capnhatcongtrinhdienhinh.component';
import { ToastrService } from 'ngx-toastr';
import { FileUploader, FileItem, ParsedResponseHeaders } from "ng2-file-upload";
import { HostService } from 'src/app/services/host.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TreeNode } from './../../scriptAll/TreeNode';
import { LuachoncongtrinhComponent } from 'src/app/suatvondautu/congtrinhdienhinh1/NgbModal/luachoncongtrinh/luachoncongtrinh.component';
import { CapnhatdanhmucdungchungComponent } from 'src/app/suatvondautu/capnhatdanhmucdungchung/capnhatdanhmucdungchung.component';
@Component({
  selector: 'app-congtrinhdienhinh',
  templateUrl: './congtrinhdienhinh.component.html',
  styleUrls: ['./congtrinhdienhinh.component.css']
})
export class CongtrinhdienhinhComponent implements OnInit {

  listtinh: any = [];
  listchitieu: any = [];
  listChiPhi: any = [];
  listcapcongtrinh: any = [];
  listnhomcongtrinh: any = [];
  objloaddata: any = {};
  listItem: any = [];
  uploader: FileUploader;
  title: any = "";
  files: TreeNode[];
  listkhuvuc:any = [];
  header: "";
  TenThongTinUser: "";
  listdonViTien: any = [];
  listChuongCongTrinh: any = [];
  constructor(
    private modalService: NgbModal,
    private SuatVonDauTuService: SuatVonDauTuService,
    private MyService: MyService,
    private toastr: ToastrService,
    private DanhmucService: DanhmucService,
    private activatedRoute: ActivatedRoute,private router: Router,
  ) {
     this.router.events.pipe( filter(event => event instanceof NavigationEnd)).subscribe(() => {  
    const rt = this.getChild(this.activatedRoute);  
    rt.data.subscribe(data => {  
      this.title = data.title;
    });  
  });
}

  ngOnInit() {
    this.objloaddata.KhuVuc = 0;
    if(JSON.parse(localStorage.getItem('thongtinuser')) != undefined){
      this.objloaddata.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
      this.objloaddata.Nam = JSON.parse(localStorage.getItem('thongtinuser')).Nam;
      this.TenThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).Ten;
    }
    this.GetListDonViTien();
    this.GetListdmKhuVuc();
    this.uploader = new FileUploader({
      url: `${HostService.serverUrl}FileUploader/Post`,
      headers: [{ name: 'Accept', value: 'application/json' }],
      autoUpload: true,
    });
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = true;
    };
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteItem = (item, response, status, headers) => this.onCompleteItem(item, response, status, headers);
    // this.getnam();
    this.GetListdmLoaiCongTrinhParent();
  }
  GetListDonViTien() {
    this.listdonViTien = [];
    let data2: any={
      "ma": 0,
      "ten":"Đồng"
    }
    let data: any={
      "ma": 1,
      "ten":"Nghìn đồng"
    }
    let data1: any={
      "ma": 2,
      "ten":"Triệu đồng"
    }
    this.listdonViTien.push(data2)
    this.listdonViTien.push(data)
    this.listdonViTien.push(data1)
    this.objloaddata.DonViTien = 2;
  }
  GetListdmKhuVuc() {
    let data1: any = {
      Ma: 1,
      Ten: "Khu vực 1",
    }
    let data2: any = {
      Ma: 2,
      Ten: "Khu vực 2",
    }
    let data3: any = {
      Ma: 3,
      Ten: "Khu vực 3",
    }
    let data4: any = {
      Ma: 4,
      Ten: "Toàn quốc",
    }
    this.listkhuvuc.push(data1);
    this.listkhuvuc.push(data2);
    this.listkhuvuc.push(data3);
    this.listkhuvuc.push(data4);
    this.objloaddata.KhuVuc = 1;
  }
  GetListdmLoaiCongTrinhParent() {
    this.DanhmucService.GetListdmLoaiCongTrinhParent(this.objloaddata.IDThongTinUser,'GiaBoPhan').subscribe(res => {
      // res.forEach(element => {
      //   if(element.Ma == this.title){
      //     this.objloaddata.IDdmLoaiCongTrinh = element.ID;
      //     this.header = element.Ten.toLowerCase();
      //   }
      // });
      this.listChuongCongTrinh = res;
      this.objloaddata.IDdmLoaiCongTrinh = this.listChuongCongTrinh[0].ID;
      this.refresh();
    });
  }
  getChild(activatedRoute: ActivatedRoute) {  
    if (activatedRoute.firstChild) {  
      return this.getChild(activatedRoute.firstChild);  
    } else {  
      return activatedRoute;  
    }  
  } 
  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {

  }

  onCompleteItem = (item: any, response: any, status: any, headers: any) => {
    let res = JSON.parse(response);
    res[0].Id = 0;
    // this.lstFileKyThuatUpload.push(res[0]);  
    this.SuatVonDauTuService.ImportdmCongTrinhDienHinhBPKC(res[0].Name, this.objloaddata.IDThongTinUser, this.objloaddata.KhuVuc).subscribe(res => {
      if (res.State === 1) {
        this.toastr.success(res.message);
        this.loaddata();
      }
      else {
        this.toastr.error(res.message);
      }
    });
  };

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
  }


  GetListdmCapCongTrinh() {
    this.DanhmucService.GetListdmCapCongTrinh(this.objloaddata).subscribe(res => {
      this.listcapcongtrinh = res;
    });
  }

  GetListdmNhomCongTrinh() {
    this.DanhmucService.GetListdmNhomCongTrinh(this.objloaddata).subscribe(res => {
      this.listnhomcongtrinh = res;
    });
  }
  refresh() {
    this.objloaddata = {
      IDdmTinh: this.objloaddata.IDdmTinh,
      Nam: this.objloaddata.Nam,
      IDThongTinUser: this.objloaddata.IDThongTinUser,
      IDdmLoaiCongTrinh: this.objloaddata.IDdmLoaiCongTrinh,
      LoaiDanhMuc: "GiaBoPhan",
      KhuVuc: this.objloaddata.KhuVuc,
      DonViTien: this.objloaddata.DonViTien,
    };
    this.loaddata();
  }

  loaddata() {
    this.SuatVonDauTuService.GetListChiPhiCongTrinhBoPhanKetCau(this.objloaddata).subscribe(res => {
        if(this.objloaddata.DonViTien == 1){
            res.forEach(element => {
            this.dequyGia(element, 1000)
          });
        }
        else if(this.objloaddata.DonViTien == 2){
            res.forEach(element => {
            this.dequyGia(element, 1000000)
          });
        }
      this.files = res;
    });
  }
  dequyGia(element, giatri){
    if(element.data.ChiPhiChung.VonDauTu != null)
      element.data.ChiPhiChung.VonDauTu = element.data.ChiPhiChung.VonDauTu/ giatri;
    if(element.data.ChiPhiChung.DuToan != null)
      element.data.ChiPhiChung.DuToan = element.data.ChiPhiChung.DuToan/ giatri;
    if(element.data.ChiPhiChung.ChiPhiXayDung != null)
      element.data.ChiPhiChung.ChiPhiXayDung = element.data.ChiPhiChung.ChiPhiXayDung/ giatri;
    if(element.children.length > 0){
      element.children.forEach(i => {
        this.dequyGia(i, giatri)
      });
    }
  }
  themmoi(item) {
    let objloaicongtrinh: any = {
      IDdmLoaiCongTrinh: item.IDParent,
      TendmLoaiCongTrinh: item.Ten,
      IDThongTinUser: this.objloaddata.IDThongTinUser,
      MadmLoaiCongTrinh: item.Ma,
    }
    const modalRef = this.modalService.open(CapnhatcongtrinhdienhinhComponent, { size: 'fullscreen', backdrop: 'static', scrollable: true });
    modalRef.componentInstance.type = 'themmoi';
    modalRef.componentInstance.IDParent = item;
    modalRef.componentInstance.KhuVuc = this.objloaddata.KhuVuc;
    modalRef.componentInstance.objchitieu = objloaicongtrinh;
    this.listchitieu.filter(obj => {
      if (this.objloaddata.IDdmChiTieu == obj.ID) {
        return modalRef.componentInstance.objchitieu = obj;
      }
    });
    modalRef.componentInstance.IDdmTinh = this.objloaddata.IDdmTinh;
    modalRef.componentInstance.listcapcongtrinh = this.listcapcongtrinh;
    modalRef.componentInstance.listnhomcongtrinh = this.listnhomcongtrinh;
    modalRef.componentInstance.Nam = this.objloaddata.Nam;
    modalRef.result.then((data) => {
      this.loaddata();
    }, (reason) => {
      // không
    });
  }

  capnhat(item) {    
    this.SuatVonDauTuService.GetCongTrinhDienHinhGiaBoPhanKetCau(item.ID).subscribe(res => {
      let objloaicongtrinh: any = {
        IDdmLoaiCongTrinh: item.IDParent,
        TendmLoaiCongTrinh: res.TendmLoaiCongTrinhParent,
        IDThongTinUser: this.objloaddata.IDThongTinUser,
        MadmLoaiCongTrinh: res.MadmLoaiCongTrinhParent,
      }
      res.HeSoMayThiCong = res.HeSoMayThiCong.toString().replace('.',',');
      res.HeSoNhanCong = res.HeSoNhanCong.toString().replace('.',',');
      res.HeSoVatLieu = res.HeSoVatLieu.toString().replace('.',',');
      res.listChiPhiVatLieu.forEach(element => {
        if(element.KhoiLuong != null && element.KhoiLuong != undefined)
          element.KhoiLuong = element.KhoiLuong.toString().replace('.',',');
      });
      res.listChiPhiNhanCong.forEach(element => {
        if(element.KhoiLuong != null && element.KhoiLuong != undefined)
          element.KhoiLuong = element.KhoiLuong.toString().replace('.',',');
      });
      res.listChiPhiMay.forEach(element => {
        if(element.KhoiLuong != null && element.KhoiLuong != undefined)
          element.KhoiLuong = element.KhoiLuong.toString().replace('.',',');
      });
      res.listChiPhiCongViec.forEach(element => {
        if(element.KhoiLuong != null && element.KhoiLuong != undefined)
          element.KhoiLuong = element.KhoiLuong.toString().replace('.',',');
      });
      const modalRef = this.modalService.open(CapnhatcongtrinhdienhinhComponent, { size: 'fullscreen', backdrop: 'static', scrollable: true });
      modalRef.componentInstance.type = 'capnhat';
      modalRef.componentInstance.IDParent = item;
      modalRef.componentInstance.KhuVuc = this.objloaddata.KhuVuc;
      modalRef.componentInstance.objchitieu = objloaicongtrinh;
      this.listchitieu.filter(obj => {
        if (this.objloaddata.IDdmChiTieu == obj.ID) {
          return modalRef.componentInstance.objchitieu = obj;
        }
      });
      modalRef.componentInstance.item = res;
      modalRef.componentInstance.IDdmTinh = this.objloaddata.IDdmTinh;
      modalRef.componentInstance.listcapcongtrinh = this.listcapcongtrinh;
      modalRef.componentInstance.listnhomcongtrinh = this.listnhomcongtrinh;
      modalRef.componentInstance.Nam = this.objloaddata.Nam;
      modalRef.result.then((data) => {
        this.loaddata();
      }, (reason) => {
        // không
      });
    });  
  }

  xoa(item) {
    const modalRef = this.modalService.open(ThongbaoComponent, { backdrop: 'static' });
    modalRef.componentInstance.thongbao = "Bạn có chắc chắn muốn xóa?";
    modalRef.result.then((any) => {
      this.SuatVonDauTuService.DeleteChiPhiCongTrinh(item).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.loaddata();
        }
        else {
          this.toastr.error(res.message);
        }
      });
    }, (reason) => {
      // không
    });
  }

  xuatfile() {
    let data: any =
    {
      IDThongTinUser: this.objloaddata.IDThongTinUser,
      IDdmLoaiCongTrinh: this.objloaddata.IDdmLoaiCongTrinh,
      LoaiDanhMuc: "GiaBoPhan",
      Nam: this.objloaddata.Nam,
      KhuVuc: this.objloaddata.KhuVuc,
    };
    this.SuatVonDauTuService.ExportdmCongTrinhDienHinhBPKC(data).subscribe(res => {
      if (res.TenFile !== undefined) {
        this.MyService.taixuong(res.TenFile);
      }
      else {
        this.toastr.error(res.message);
      }
    });
  }
  LuaChon(){
    this.DanhmucService.GetListdmLoaiCongTrinhForID(this.objloaddata.IDdmLoaiCongTrinh,
      this.objloaddata.IDThongTinUser, 'GiaBoPhan').subscribe(res => {
        const modalRef = this.modalService.open(LuachoncongtrinhComponent, { size: 'lg', backdrop: 'static', scrollable: true });
        modalRef.componentInstance.Listtree = Object.assign([], res);
        modalRef.result.then((data) => {
          this.refresh();
        }, (reason) => {
          // không
        });
    });
  }
  LuaChonCongTrinhParent(){
    this.DanhmucService.GetListdmLoaiCongTrinhParentFull(this.objloaddata.IDThongTinUser,'GiaBoPhan').subscribe(res => {
        const modalRef = this.modalService.open(LuachoncongtrinhComponent, { size: 'lg', backdrop: 'static', scrollable: true });
        modalRef.componentInstance.Listtree = Object.assign([], res);
        modalRef.result.then((data) => {
          this.GetListdmLoaiCongTrinhParent();
        }, (reason) => {
          // không
        });
    });
  }
  saveDanhMucdungChung(){
    const modalRef = this.modalService.open(CapnhatdanhmucdungchungComponent, { backdrop: 'static' });
    modalRef.componentInstance.LoaiDanhMuc = 'GiaBoPhan';
    modalRef.result.then((data) => {
    }, (reason) => {
    });
  }
}
