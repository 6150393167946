import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../services/danhmuc.service';
import { SuatVonDauTuService } from './../../services/suatvondautu.service';
import { MyService } from './../../services/my.service';
import { ToastrService } from 'ngx-toastr';
import { TreeNode } from './../../scriptAll/TreeNode';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dinhmucsuatvon',
  templateUrl: './dinhmucsuatvon.component.html',
  styleUrls: ['./dinhmucsuatvon.component.css']
})
export class DinhmucsuatvonComponent implements OnInit {
  listItem: TreeNode[];
  listkhuvuc: any = [];
  listnhomcongtrinh: any = [];
  listloaicongtrinh: any = [];
  listloaiheso: any = [];
  listnam: any = [];
  listloaicap1: any = [];
  listloaicap2: any = [];
  listloaicap3: any = [];
  objloaddata: any = {};
  title: any = {}
  header: "";
  TenThongTinUser: "";
  listChuongCongTrinh: any = [];
  constructor(
    private modalService: NgbModal,
    private SuatVonDauTuService: SuatVonDauTuService,
    private MyService: MyService,
    private toastr: ToastrService,
    private DanhmucService: DanhmucService,
    private activatedRoute: ActivatedRoute,private router: Router,
    public spinner: NgxSpinnerService,
  ) { 
    this.router.events.pipe( filter(event => event instanceof NavigationEnd)).subscribe(() => {  
    const rt = this.getChild(this.activatedRoute); 
    rt.data.subscribe(data => {  
      this.title = data.title;
    });  
  });
  }
  ngOnInit() {
    this.objloaddata.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
    this.objloaddata.Nam = JSON.parse(localStorage.getItem('thongtinuser')).Nam;
    this.TenThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).Ten;
    this.GetListdmKhuVuc();
    this.GetListdmLoaiCongTrinhParent();
  }
  GetListdmKhuVuc() {
    let data1: any = {
      Ma: 1,
      Ten: "Khu vực 1",
    }
    let data2: any = {
      Ma: 2,
      Ten: "Khu vực 2",
    }
    let data3: any = {
      Ma: 3,
      Ten: "Khu vực 3",
    }
    let data4: any = {
      Ma: 4,
      Ten: "Toàn quốc",
    }
    this.listkhuvuc.push(data1);
    this.listkhuvuc.push(data2);
    this.listkhuvuc.push(data3);
    this.listkhuvuc.push(data4);
    this.objloaddata.KhuVuc = 1;
  }
  getChild(activatedRoute: ActivatedRoute) {  
    if (activatedRoute.firstChild) {  
      return this.getChild(activatedRoute.firstChild);  
    } else {  
      return activatedRoute;  
    }  
  } 
  GetListdmLoaiCongTrinhParent() {
    this.DanhmucService.GetListdmLoaiCongTrinhParent(this.objloaddata.IDThongTinUser,'SuatVon').subscribe(res => {
      this.listChuongCongTrinh = res;
      this.objloaddata.IDdmLoaiCongTrinh = this.listChuongCongTrinh[0].ID;
      this.refresh();
    });
  }
  TinhListSuatVonDauTu() {
    let data: any = {
      Nam: this.objloaddata.Nam,
      IDThongTinUser: this.objloaddata.IDThongTinUser,
      KhuVuc: this.objloaddata.KhuVuc,
      IDdmLoaiCongTrinh: this.objloaddata.IDdmLoaiCongTrinh,
      LoaiDanhMuc: "SuatVon",
    };
    this.spinner.show();
    this.SuatVonDauTuService.TinhListSuatVonDauTu(data).subscribe(res => {
      if (res.State === 1) {
        this.toastr.success(res.message);
        this.refresh();
      }
      else {
        this.toastr.error(res.message);
      }
      this.spinner.hide();

    });
  }
  // GetListdmTinhTheoUser() {
  //   this.objloaddata.IDdmTinh = undefined;
  //   this.listtinh = [];
  //   let data: any = {
  //     IDdmVung: this.objloaddata.IDdmVung != undefined ? this.objloaddata.IDdmVung : 0,
  //     IDThongTinUser: this.objloaddata.IDThongTinUser != undefined ? this.objloaddata.IDThongTinUser : 0,
  //   };
  //   this.DanhmucService.GetListdmTinhTheoUser(data).subscribe(res => {
  //     this.listtinh = res;
  //     this.objloaddata.IDdmTinh = res[0].ID;

  //   });
  // }
  refresh() {
    this.objloaddata = {
      KhuVuc: this.objloaddata.KhuVuc,
      IDdmLoaiCongTrinh: this.objloaddata.IDdmLoaiCongTrinh,
      Nam: this.objloaddata.Nam,
      IDThongTinUser: this.objloaddata.IDThongTinUser,
    };
    this.loaddata();
  }

  loaddata() {
    this.SuatVonDauTuService.GetListSuatVonDauTu(this.objloaddata).subscribe(res => {
      this.listItem = res;
    });
  }

  xuatfile() {
    let data: any =
    {
      KhuVuc: this.objloaddata.KhuVuc,
      IDdmLoaiCongTrinh: this.objloaddata.IDdmLoaiCongTrinh,
      Nam: this.objloaddata.Nam,
      IDThongTinUser: this.objloaddata.IDThongTinUser,
    };
    this.SuatVonDauTuService.ExportSuatVon(data).subscribe(res => {
      if (res.TenFile !== undefined) {
        this.MyService.taixuong(res.TenFile);
      }
      else {
        this.toastr.error(res.message);
      }
    });
  }
  xuatWord() {
    let data: any =
    {
      KhuVuc: this.objloaddata.KhuVuc,
      IDdmLoaiCongTrinh: this.objloaddata.IDdmLoaiCongTrinh,
      Nam: this.objloaddata.Nam,
      IDThongTinUser: this.objloaddata.IDThongTinUser,
    };
    this.SuatVonDauTuService.XuatBaoCaoSuatVonWord(data).subscribe(res => {
      console.log(res)
      if (res.TenFile !== undefined) {
        this.MyService.taixuong(res.TenFile);
      }
      else {
        this.toastr.error(res.message);
      }
    });
  }
}
