import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../services/danhmuc.service';
import { MyService } from './../../services/my.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { CapnhatcongtrinhComponent } from './NgbModal/capnhatcongtrinh/capnhatcongtrinh.component';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-danhmuccongtrinh',
  templateUrl: './danhmuccongtrinh.component.html',
  styleUrls: ['./danhmuccongtrinh.component.css']
})
export class DanhmuccongtrinhComponent implements OnInit {

  objloaddata: any = {};

  dataitem: any = {};

  listnhomcongtrinh: any = [];

  constructor(
    private modalService: NgbModal,
    private DanhmucService: DanhmucService,
    private MyService: MyService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.dataitem.items = [];
    this.dataitem.paging = {};
    this.dataitem.paging.TotalItem = 0;
    this.GetListdmLoaiCongTrinhCap3();
    this.refresh();
  }

  refresh() {
    this.objloaddata = {
      CurrentPage: 1,
      PageSize: 15,
      sFilter: "",
    };
    this.loaddata();
  }

  loaddata() {
    this.DanhmucService.GetListdmLoaiCongTrinhCap4(this.objloaddata).subscribe(res => {
      this.dataitem = res;
    });
  }

  GetListdmLoaiCongTrinhCap3() {
    let data: any = {
      CurrentPage: 0,
      PageSize: 0,
      sFilter: "",
    };
    this.DanhmucService.GetListdmLoaiCongTrinhCap3(data).subscribe(res => {
      this.listnhomcongtrinh = res;
    });
  }

  themmoi() {
    const modalRef = this.modalService.open(CapnhatcongtrinhComponent, { backdrop: 'static' });
    modalRef.componentInstance.type = 'themmoi';
    modalRef.componentInstance.listnhomcongtrinh = this.listnhomcongtrinh;   
    modalRef.result.then((data) => {
      this.loaddata();
    }, (reason) => {
      // không
    });
  }

  capnhat(item){
    const modalRef = this.modalService.open(CapnhatcongtrinhComponent, { backdrop: 'static' });
    modalRef.componentInstance.type = 'capnhat';
    modalRef.componentInstance.item = item;
    modalRef.componentInstance.listnhomcongtrinh = this.listnhomcongtrinh;  
    modalRef.result.then((data) => {   
      this.loaddata();
    }, (reason) => {
      // không
    });
  }

  xoa(item) {
    const modalRef = this.modalService.open(ThongbaoComponent, { backdrop: 'static' });
    modalRef.componentInstance.thongbao = "Bạn có chắc chắn muốn xóa?";
    modalRef.result.then((any) => {
      this.DanhmucService.GetListdmLoaiCongTrinhCap4(item).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.loaddata();
        }
        else {
          this.toastr.error(res.message);
        }
      });
    }, (reason) => {
      // không
    });
  }

}
