import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../services/danhmuc.service';
import { MyService } from './../../services/my.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LuaChonCSGComponent } from './NgbModal/luachon/luachon.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  dataitem: any = {};
  currentUser: any = {};
  isLuu: any = false;
  today: any = new Date();
  date: any;
  thoigian:string = '';

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private DanhmucService: DanhmucService,
    private MyService: MyService,
    private toastr: ToastrService,
    private authService: AuthenticationService,
  ) {
    this.currentUser = Object.assign({}, JSON.parse(localStorage.getItem('currentUser')));
    if (this.currentUser.Id == undefined) {
      this.router.navigate(['/Login']);
    }
    else {
      this.router.navigate(['/ThongTinUser']);
    }
  }

  ngOnInit() {
    this.dataitem.username = '';
    this.dataitem.password = '';
    this.date = this.today.getDay();
    this.getDate( this.date)
  }

  getDate(date:any) {
    switch (date) {
      case 0:
          this.thoigian = "Chủ nhật";
          break;
      case 1:
          this.thoigian = "Thứ hai";
          break;
      case 2:
          this.thoigian = "Thứ ba";
          break;
      case 3:
          this.thoigian = "Thứ tư";
          break;
      case 4:
          this.thoigian = "Thứ năm";
          break;
      case 5:
          this.thoigian = "Thứ sau";
          break;
      case 6:
          this.thoigian = "Thứ bảy";
      }
  }

  keydownenter(e) {
    if (this.isLuu == false)
      this.login();
  }

  login() {
    if (this.isLuu === false) {
      this.isLuu = true;
      let isCheck: any = false;
      localStorage.setItem('checkPM04', JSON.stringify({"isCheck":false}));
      let data = {
        userName: this.dataitem.username,
        passWord:this.dataitem.password
      }
      this.DanhmucService.listLogin(data).subscribe(res => {
        if (res.length > 0 || (this.dataitem.username === "admin" && res.length > -1)) {
          if(this.dataitem.username === "admin")
              isCheck = true;
          let indexOf = res.indexOf(" lỗi ");
          if (indexOf !== -1) {
            this.isLuu = false;
            this.toastr.warning('Tài khoản hoặc mật khẩu không đúng', 'Thông báo')
          }
          else {
            res.forEach(element => {
              if (element.Ma == 'PM03') {
                isCheck = true;
              }
              if (element.Ma == 'PM04') {
                localStorage.setItem('checkPM04', JSON.stringify({"isCheck":true}));
              }
            });
            if (isCheck === true) {
              this.authService.login(this.dataitem.username, "123456").subscribe(res => {
                if (res.State == 0) {
                  let data: any = {
                    "Id": "",
                    "UserName": this.dataitem.username !== undefined && this.dataitem.username !== "" ? this.dataitem.username : "",
                    "PasswordHash": "123456",
                    "HoTen": this.dataitem.username !== undefined && this.dataitem.username !== "" ? this.dataitem.username : "",
                    "NamGoc": 0,
                    "listUserTinh": [],
                  }
                  this.DanhmucService.SetUser(data).subscribe(res => {
                    if (res.State === 1) {
                      this.authService.login(this.dataitem.username, "123456").subscribe(res => {
                        this.MyService.checklogin = true;
                        this.router.navigate(['/ThongTinUser']);
                        localStorage.setItem('currentUser', JSON.stringify(res));
                        localStorage.setItem('thongtinuser', JSON.stringify({}));

                        const modalRef = this.modalService.open(LuaChonCSGComponent, { size: 'sm', backdrop: 'static', centered: true });
                        modalRef.componentInstance.Nam = this.dataitem.Nam;
                        modalRef.componentInstance.IDdmKhuVuc = this.dataitem.IDdmKhuVuc;
                        modalRef.componentInstance.IDdmTinh = this.dataitem.IDdmTinh;
                        modalRef.result.then((data) => {
                        }).catch(er => { console.log(er)
                          location.reload();
                          });
                      });
                    }
                    else {
                      this.isLuu = false;
                      this.toastr.error(res.message);
                    }
                  });
                }
                else {
                  this.MyService.checklogin = true;
                  this.router.navigate(['/ThongTinUser']);
                  localStorage.setItem('currentUser', JSON.stringify(res));
                  localStorage.setItem('thongtinuser', JSON.stringify({}));
                  const modalRef = this.modalService.open(LuaChonCSGComponent, { size: 'sm', backdrop: 'static', centered: true });
                  modalRef.componentInstance.Nam = this.dataitem.Nam;
                  modalRef.componentInstance.IDdmKhuVuc = this.dataitem.IDdmKhuVuc;
                  modalRef.componentInstance.IDdmTinh = this.dataitem.IDdmTinh;
                  modalRef.result.then((data) => {
                  }).catch(er => { console.log(er)
                    location.reload();
                    });
                }
              });
            }
            else {
              this.isLuu = false;
              this.toastr.warning('Bạn không có quyền đăng nhập', 'Thông báo')
            }
          }
        }
        else {
          this.isLuu = false;
          this.toastr.warning('Tài khoản hoặc mật khẩu không đúng', 'Thông báo')
        }
      });
    }
  }
}