import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../services/danhmuc.service';
import { MyService } from './../../services/my.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { CapnhatloaicongtrinhComponent } from './NgbModal/capnhatloaicongtrinh/capnhatloaicongtrinh.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-danhmucloaicongtrinh',
  templateUrl: './danhmucloaicongtrinh.component.html',
  styleUrls: ['./danhmucloaicongtrinh.component.css']
})
export class DanhmucloaicongtrinhComponent implements OnInit {

  objloaddata: any = {};

  dataitem: any = {};

  listchuongcongtrinh: any = [];

  constructor(
    private modalService: NgbModal,
    private DanhmucService: DanhmucService,
    private MyService: MyService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.dataitem.items = [];
    this.dataitem.paging = {};
    this.dataitem.paging.TotalItem = 0;
    this.GetListdmLoaiCongTrinhCap1();
    this.refresh();
  }

  refresh() {
    this.objloaddata = {
      CurrentPage: 1,
      PageSize: 15,
      sFilter: "",
    };
    this.loaddata();
  }

  loaddata() {
    this.DanhmucService.GetListdmLoaiCongTrinhCap2(this.objloaddata).subscribe(res => {
      this.dataitem = res;
    });
  }

  GetListdmLoaiCongTrinhCap1() {
    let data: any = {
      CurrentPage: 0,
      PageSize: 0,
      sFilter: "",
    };
    this.DanhmucService.GetListdmLoaiCongTrinhCap1(data).subscribe(res => {
      this.listchuongcongtrinh = res;
    });
  }

  themmoi() {
    const modalRef = this.modalService.open(CapnhatloaicongtrinhComponent, { backdrop: 'static' });
    modalRef.componentInstance.type = 'themmoi';
    modalRef.componentInstance.listchuongcongtrinh = this.listchuongcongtrinh;
    modalRef.result.then((data) => {
      this.loaddata();
    }, (reason) => {
      // không
    });
  }

  capnhat(item) {
    const modalRef = this.modalService.open(CapnhatloaicongtrinhComponent, { backdrop: 'static' });
    modalRef.componentInstance.type = 'capnhat';
    modalRef.componentInstance.listchuongcongtrinh = this.listchuongcongtrinh;
    modalRef.componentInstance.item = item;
    modalRef.result.then((data) => {
      this.loaddata();
    }, (reason) => {
      // không
    });
  }

  xoa(item) {
    const modalRef = this.modalService.open(ThongbaoComponent, { backdrop: 'static' });
    modalRef.componentInstance.thongbao = "Bạn có chắc chắn muốn xóa?";
    modalRef.result.then((any) => {
      this.DanhmucService.DeletedmLoaiCongTrinhCap2(item).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.loaddata();
        }
        else {
          this.toastr.error(res.message);
        }
      });
    }, (reason) => {
      // không
    });
  }

}
