import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA,LOCALE_ID } from '@angular/core';
import { CommonModule, LocationStrategy, HashLocationStrategy, registerLocaleData } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbPaginationModule, NgbAlertModule,NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from "ngx-spinner";

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccordionModule } from 'primeng/accordion';
import { TreeTableModule } from 'primeng/treetable';
import { TableModule } from 'primeng/table';
import { AuthGuard } from './_guard/auth.guard';
import { AuthenticationService } from './services/authentication.service';
// import { MenuItem } from 'primeng/api';              

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { HeaderComponent } from './layout/header/header.component';
import { LoginComponent } from './login/login/login.component';
import { ThongbaoComponent } from './NgbModalAll/thongbao/thongbao.component';
import { DanhmucchuongcongtrinhComponent } from './danhmuc/danhmucchuongcongtrinh/danhmucchuongcongtrinh.component';
import { DanhmucloaicongtrinhComponent } from './danhmuc/danhmucloaicongtrinh/danhmucloaicongtrinh.component';
import { DanhmucnhomcongtrinhComponent } from './danhmuc/danhmucnhomcongtrinh/danhmucnhomcongtrinh.component';
import { DanhmuccongtrinhComponent } from './danhmuc/danhmuccongtrinh/danhmuccongtrinh.component';
import { DanhmucchitieuComponent } from './danhmuc/danhmucchitieu/danhmucchitieu.component';
import { CapnhatchuongcongtrinhComponent } from './danhmuc/danhmucchuongcongtrinh/NgbModal/capnhatchuongcongtrinh/capnhatchuongcongtrinh.component';
import { CapnhatchitieuComponent } from './danhmuc/danhmucchitieu/NgbModal/capnhatchitieu/capnhatchitieu.component';
import { CapnhatloaicongtrinhComponent } from './danhmuc/danhmucloaicongtrinh/NgbModal/capnhatloaicongtrinh/capnhatloaicongtrinh.component';
import { CapnhatnhomcongtrinhComponent } from './danhmuc/danhmucnhomcongtrinh/NgbModal/capnhatnhomcongtrinh/capnhatnhomcongtrinh.component';
import { CapnhatcongtrinhComponent } from './danhmuc/danhmuccongtrinh/NgbModal/capnhatcongtrinh/capnhatcongtrinh.component';
import { HesodieuchinhComponent } from './suatvondautu/hesodieuchinh/hesodieuchinh.component';
import { DinhmucsuatvonComponent } from './suatvondautu/dinhmucsuatvon/dinhmucsuatvon.component';
import { CapnhathesodieuchinhComponent } from './suatvondautu/hesodieuchinh/NgbModal/capnhathesodieuchinh/capnhathesodieuchinh.component';
import { GiaComponent } from './giaxaydung/gia/gia.component';
import { HesokhoanmucchiphiComponent } from './giaxaydung/hesokhoanmucchiphi/hesokhoanmucchiphi.component';
import { GiabophanketcaucongtrinhComponent } from './giaxaydung/giabophanketcaucongtrinh/giabophanketcaucongtrinh.component';
import { DanhmuccapComponent } from './danhmuc/danhmuccap/danhmuccap.component';
import { CapnhatcapcongtrinhComponent } from './danhmuc/danhmuccap/NgbModal/capnhatcapcongtrinh/capnhatcapcongtrinh.component';
import { DanhmuckhuvucComponent } from './danhmuc/danhmuckhuvuc/danhmuckhuvuc.component';
import { DanhmuctinhComponent } from './danhmuc/danhmuctinh/danhmuctinh.component';
import { CapnhatdanhmuckhuvucComponent } from './danhmuc/danhmuckhuvuc/NgbModal/capnhatdanhmuckhuvuc/capnhatdanhmuckhuvuc.component';
import { CapnhatdanhmuctinhComponent } from './danhmuc/danhmuctinh/NgbModal/capnhatdanhmuctinh/capnhatdanhmuctinh.component';
import { Loaicapcongtrinh3Component } from './danhmuc/loaicapcongtrinh3/loaicapcongtrinh3.component';
import { Capnhatloaicongtrinh3Component } from './danhmuc/loaicapcongtrinh3/NgbModal/capnhatloaicongtrinh3/capnhatloaicongtrinh3.component';
import { CongtrinhdienhinhComponent } from './giaxaydung/congtrinhdienhinh/congtrinhdienhinh.component';
import { CapnhatcongtrinhdienhinhComponent } from './giaxaydung/congtrinhdienhinh/NgbModal/capnhatcongtrinhdienhinh/capnhatcongtrinhdienhinh.component';
import { Congtrinhdienhinh1Component } from './suatvondautu/congtrinhdienhinh1/congtrinhdienhinh1.component';
import { Capnhatcongtrinhdienhinh1Component } from './suatvondautu/congtrinhdienhinh1/NgbModal/capnhatcongtrinhdienhinh1/capnhatcongtrinhdienhinh1.component';
import { CapnhatvatlieuComponent } from './giaxaydung/congtrinhdienhinh/NgbModal/capnhatvatlieu/capnhatvatlieu.component';
import { CapnhatnhancongComponent } from './giaxaydung/congtrinhdienhinh/NgbModal/capnhatnhancong/capnhatnhancong.component';
import { CapnhatmaythicongComponent } from './giaxaydung/congtrinhdienhinh/NgbModal/capnhatmaythicong/capnhatmaythicong.component';
import { DanhsachtinhComponent } from './danhmuc/danhmuckhuvuc/NgbModal/danhsachtinh/danhsachtinh.component';
import { VatlieuCongnhanMayComponent } from './danhmuc/vatlieu-congnhan-may/vatlieu-congnhan-may.component';
import { ThemmoitreeComponent } from './NgbModalAll/themmoitree/themmoitree.component';
import { ThongtinuserComponent } from './thongtinuser/thongtinuser/thongtinuser.component';
import { CapnhapthongtinuserComponent } from './thongtinuser/thongtinuser/NgbModal/capnhapthongtinuser/capnhapthongtinuser.component';
import { LuaChonCSGComponent } from './login/login/NgbModal/luachon/luachon.component';
import { LuaChonPhuongThucCSGComponent } from './login/login/NgbModal/luachoncsg/luachoncsg.component';
import { DanhMucDungChungComponent } from './danhmucdungchung/danhmucdungchung.component';
import { HienThiVLNCMComponent } from './danhmucdungchung/NgbModal/hienthi-vlncm/hienthi-vlncm.component';
import { LuachoncongtrinhComponent } from './suatvondautu/congtrinhdienhinh1/NgbModal/luachoncongtrinh/luachoncongtrinh.component';
import { DanhmucuserComponent } from './danhmuc/danhmucuser/danhmucuser.component';
import { UpdateuserComponent } from './danhmuc/danhmucuser/NgbModal/updateuser/updateuser.component';
import { DscongboComponent } from './giaxaydung/dscongbo/dscongbo.component';
import { CongboComponent } from './giaxaydung/dscongbo/congbo/congbo.component';
import localeVi from '@angular/common/locales/vi';
registerLocaleData(localeVi);
import { FormatdatetimeService } from './services/formatdatetime.service';
// import { ConvertNumberPipe } from './services/convertnumber.service';
import { ThemMoiDuAnDanhMucDungChungComponent } from './login/login/NgbModal/themmoiduancongtrinhdienhinh/themmoiduancongtrinhdienhinh.component';
import { BaoCaoComponent } from './baocao/baocao/baocao.component';
import { ThongTinBanDauComponent } from './login/login/NgbModal/luachontinh/luachontinh.component';
import { DoimatkhauComponent } from './danhmuc/danhmucuser/NgbModal/doimatkhau/doimatkhau.component';
import { DmgiaidoanduanComponent } from './danhmuc/dmgiaidoanduan/dmgiaidoanduan.component';
import { DmgiaidoanduanmodalComponent } from './danhmuc/dmgiaidoanduanmodal/dmgiaidoanduanmodal.component';
import { FilemauComponent } from './danhmuc/filemau/filemau.component';
import { HienthicongtrinhdaidienmodalComponent } from './danhmucdungchung/NgbModal/hienthicongtrinhdaidienmodal/hienthicongtrinhdaidienmodal.component';
import { FilterTrueFalsePipe } from './pipe/filter-true-false.pipe';
import { HuongdandangnhapComponent } from './huongdansudung/huongdandangnhap/huongdandangnhap.component';
import { HuongdannhapsuatvondautuComponent } from './huongdansudung/huongdannhapsuatvondautu/huongdannhapsuatvondautu.component';
import { HuongdansuatvondautuComponent } from './huongdansudung/huongdansuatvondautu/huongdansuatvondautu.component';
import { HuongdannhapgiabophankecauComponent } from './huongdansudung/huongdannhapgiabophankecau/huongdannhapgiabophankecau.component';
import { HuongdangiabophankecauComponent } from './huongdansudung/huongdangiabophankecau/huongdangiabophankecau.component';
import { HuongdancongboComponent } from './huongdansudung/huongdancongbo/huongdancongbo.component';
import { HuongdandanhmucComponent } from './huongdansudung/huongdandanhmuc/huongdandanhmuc.component';
import { CapnhatdanhmucdungchungComponent } from './suatvondautu/capnhatdanhmucdungchung/capnhatdanhmucdungchung.component';
import { LuachondanhmucloaicongtrinhdungchungComponent } from './login/login/NgbModal/luachontinh/luachondanhmucloaicongtrinhdungchung/luachondanhmucloaicongtrinhdungchung.component';
import { ViewloaicongtrinhdungchungComponent } from './danhmucdungchung/viewloaicongtrinhdungchung/viewloaicongtrinhdungchung.component';
import { HuongdanthuviendungchungComponent } from './huongdansudung/huongdanthuviendungchung/huongdanthuviendungchung.component';
import { HuongdanphimtatComponent } from './huongdansudung/huongdanphimtat/huongdanphimtat.component';
import {CalendarModule} from 'primeng/calendar';

@NgModule({
  declarations: [
    FilterTrueFalsePipe,
    AppComponent,
    LuaChonCSGComponent,
    DscongboComponent,
    LuachoncongtrinhComponent,
    CongboComponent,
    LuaChonPhuongThucCSGComponent,
    HienThiVLNCMComponent,
    DanhMucDungChungComponent,
    ThongtinuserComponent,
    BaoCaoComponent,
    ThongTinBanDauComponent,
    CapnhapthongtinuserComponent,
    SidebarComponent,
    HeaderComponent,
    LoginComponent,
    ThongbaoComponent,
    DanhmucchuongcongtrinhComponent,
    DanhmucloaicongtrinhComponent,
    DanhmucnhomcongtrinhComponent,
    ThemMoiDuAnDanhMucDungChungComponent,
    DanhmuccongtrinhComponent,
    DanhmucchitieuComponent,
    CapnhatchuongcongtrinhComponent,
    CapnhatchitieuComponent,
    CapnhatloaicongtrinhComponent,
    CapnhatnhomcongtrinhComponent,
    CapnhatcongtrinhComponent,
    HesodieuchinhComponent,
    DinhmucsuatvonComponent,
    CapnhathesodieuchinhComponent,
    GiabophanketcaucongtrinhComponent,
    GiaComponent,
    HesokhoanmucchiphiComponent,
    DanhmuccapComponent,
    CapnhatcapcongtrinhComponent,
    DanhmuckhuvucComponent,
    DanhmuctinhComponent,
    CapnhatdanhmuckhuvucComponent,
    CapnhatdanhmuctinhComponent,
    Loaicapcongtrinh3Component,
    Capnhatloaicongtrinh3Component,
    CongtrinhdienhinhComponent,
    CapnhatcongtrinhdienhinhComponent,
    Congtrinhdienhinh1Component,
    Capnhatcongtrinhdienhinh1Component,
    CapnhatvatlieuComponent,
    CapnhatnhancongComponent,
    CapnhatmaythicongComponent,
    UpdateuserComponent,
    DanhsachtinhComponent,
    VatlieuCongnhanMayComponent,
    DanhmucuserComponent,
    ThemmoitreeComponent,
    DoimatkhauComponent,
    DmgiaidoanduanComponent,
    DmgiaidoanduanmodalComponent,
    FilemauComponent,
    HienthicongtrinhdaidienmodalComponent,
    HuongdandangnhapComponent,
    HuongdannhapsuatvondautuComponent,
    HuongdansuatvondautuComponent,
    HuongdannhapgiabophankecauComponent,
    HuongdangiabophankecauComponent,
    HuongdancongboComponent,
    HuongdandanhmucComponent,
    CapnhatdanhmucdungchungComponent,
    LuachondanhmucloaicongtrinhdungchungComponent,
    ViewloaicongtrinhdungchungComponent,
    HuongdanthuviendungchungComponent,
    HuongdanphimtatComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgSelectModule,
    FormsModule,
    CommonModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgbModule,
    NgbPaginationModule,
    NgbAlertModule,
    HttpClientModule,
    FileUploadModule,
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(),
    AccordionModule,
    TreeTableModule, 
    TableModule,
    CalendarModule

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  // providers: [
  //   AuthGuard,
  //   AuthenticationService,
  //   { provide: LocationStrategy, useClass: HashLocationStrategy }
  // ],
  providers: [
    AuthGuard,
    AuthenticationService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: LOCALE_ID, useValue: 'vi-VN' },
    { provide: NgbDateParserFormatter, useClass: FormatdatetimeService },
  ],
  bootstrap: [AppComponent],  
  entryComponents: [
    LuaChonCSGComponent,
    LuachoncongtrinhComponent,
    HienThiVLNCMComponent,
    LuaChonPhuongThucCSGComponent,
    ThongbaoComponent,
    CapnhatchuongcongtrinhComponent,
    CapnhatchitieuComponent,
    CapnhatloaicongtrinhComponent,
    CapnhatnhomcongtrinhComponent,
    CapnhatcongtrinhComponent,
    CapnhathesodieuchinhComponent,
    CapnhatcapcongtrinhComponent,
    CapnhatdanhmuckhuvucComponent,
    CapnhatdanhmuctinhComponent,
    Capnhatloaicongtrinh3Component,
    CapnhatcongtrinhdienhinhComponent,
    ThongTinBanDauComponent,
    UpdateuserComponent,
    Capnhatcongtrinhdienhinh1Component,    
    CapnhatvatlieuComponent,
    CapnhatnhancongComponent,
    CapnhatmaythicongComponent,
    DanhsachtinhComponent,
    ThemmoitreeComponent,
    CapnhapthongtinuserComponent,
    ThemMoiDuAnDanhMucDungChungComponent,
    CongboComponent,
    DoimatkhauComponent,
    DmgiaidoanduanmodalComponent,
    HienthicongtrinhdaidienmodalComponent,
    CapnhatdanhmucdungchungComponent,
    LuachondanhmucloaicongtrinhdungchungComponent,
    ViewloaicongtrinhdungchungComponent,

  ]
})
export class AppModule { }
