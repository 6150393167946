import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../services/danhmuc.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-themmoitree',
  templateUrl: './themmoitree.component.html',
  styleUrls: ['./themmoitree.component.css']
})
export class ThemmoitreeComponent implements OnInit {

  public dataitem: any;

  type: any = '';
  item: any = {}
  ItemPOST: any = {};
  header: any = "";
  IDParent: any = {};
  List: any = [];
  IDdmTinh: any = 0;
  objloaddata: any = {};
  Loai: any = "";
  check: boolean = false;
  constructor(
    private activeModal: NgbActiveModal,
    private DanhmucService: DanhmucService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() { 
    if(JSON.parse(localStorage.getItem('thongtinuser')) != undefined){
      this.objloaddata.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
      this.objloaddata.Nam = JSON.parse(localStorage.getItem('thongtinuser')).Nam;
      this.objloaddata.TenThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).Ten;
      this.ItemPOST.LoaiDanhMuc = this.Loai;
    }   
    if (this.type == 'themmoi') {
      this.header = "Thêm mới";
      this.ItemPOST.cap = "cungcap";
      this.ItemPOST.IDParent = this.IDParent.IDParent;
      this.ItemPOST.check = this.check;
    }
    else {
      this.header = "Cập nhật";
      this.ItemPOST = Object.assign({}, this.IDParent);
      this.List.filter(obj => { this.ItemPOST.IDParent == obj.ID });
    }
  }
  SetData() {
    let data: any = {
      "ID": this.type == 'themmoi' ? 0 : this.ItemPOST.ID,
      "Ma": this.ItemPOST.Ma !== undefined && this.ItemPOST.Ma !== "" ? this.ItemPOST.Ma : "",
      "Ten": this.ItemPOST.Ten !== undefined && this.ItemPOST.Ten !== "" ? this.ItemPOST.Ten : "",
      "GhiChu": this.ItemPOST.GhiChu !== undefined && this.ItemPOST.GhiChu !== "" ? this.ItemPOST.GhiChu : "",
      "Loai": 0,
      "isdmChiTieu":this.ItemPOST.isdmChiTieu,
      // "LoaiDanhMuc":this.ItemPOST.LoaiDanhMuc!== undefined && this.ItemPOST.LoaiDanhMuc !== ""? this.ItemPOST.LoaiDanhMuc : this.LoaidanhMuc,
      "IDThongTinUser":this.objloaddata.IDThongTinUser,
      "DonViTinh":this.ItemPOST.DonViTinh,
      "check":this.ItemPOST.check!== undefined ? this.ItemPOST.check : false,
      "STT":this.ItemPOST.STT,
    }
    // if (this.type == 'themmoi') {
    //   data.IDParent = this.ItemPOST.cap == 'cungcap' ? this.IDParent.IDParent : this.IDParent.ID;
    //   data.LoaiDanhMuc = this.LoaidanhMuc;
    // }  
    // else{
      data.IDParent = this.ItemPOST.IDParent;
      data.LoaiDanhMuc = this.ItemPOST.LoaiDanhMuc;

    // }
    return data;
  }

  checkdata() {  
    if (this.ItemPOST.Ma != undefined && this.ItemPOST.Ma != null && this.ItemPOST.Ma != "" &&
      this.ItemPOST.Ten != undefined && this.ItemPOST.Ten != null && this.ItemPOST.Ten != ""
    ) {
      return true;
    }
    else {
      return false;
    }
  }

  XacNhan() {
    if (this.checkdata()) {
      this.DanhmucService.SetdmLoaiCongTrinh(this.SetData()).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.activeModal.close(
            {data:res.objectReturn}
        );
        }
        else {
          this.toastr.error(res.message);        
        }
      });
    }    
    else{
      this.toastr.error("Yêu cầu nhập đầy đủ trường bắt buộc");
    }  
  }

  Onclose() {
    this.activeModal.close();
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }

}
