import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../../../services/danhmuc.service';
import { SuatVonDauTuService } from './../../../../services/suatvondautu.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-capnhathesodieuchinh',
  templateUrl: './capnhathesodieuchinh.component.html',
  styleUrls: ['./capnhathesodieuchinh.component.css']
})
export class CapnhathesodieuchinhComponent implements OnInit {

  type: any = '';
  item: any = {};
  ItemPOST: any = {};
  header: any = "";
  IDParent: any = {};
  listloaiheso: any = [];

  constructor(
    private activeModal: NgbActiveModal,
    private DanhmucService: DanhmucService,
    private SuatVonDauTuService: SuatVonDauTuService,    
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    if (this.type == 'themmoi') {
      this.header = "Thêm mới";
    }
    else {
      this.header = "Cập nhật";
      this.ItemPOST = Object.assign({}, this.item);
    }
  }
 
  XacNhan() {
    this.SuatVonDauTuService.SetHeSoDieuChinh(this.ItemPOST).subscribe(res => {
      if (res.State === 1) {
        this.toastr.success(res.message);
        this.Onclose();
      }
      else {
        this.toastr.error(res.message);
      }
    });
  }

  Onclose() {
    this.activeModal.close();
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }

}
