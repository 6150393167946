import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../services/danhmuc.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { ToastrService } from 'ngx-toastr';
import { ThemmoitreeComponent } from 'src/app/NgbModalAll/themmoitree/themmoitree.component';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TreeNode } from 'src/app/scriptAll/TreeNode';

@Component({
  selector: 'app-danhmucchuongcongtrinh',
  templateUrl: './danhmucchuongcongtrinh.component.html',
  styleUrls: ['./danhmucchuongcongtrinh.component.css']
})
export class DanhmucchuongcongtrinhComponent implements OnInit {

  objloaddata: any = {};

  dataitem: any = {};
  itemSELeCT: any = {};
  Listtree: any = [];
  List: any = [];
  dataCheck: any = {
    itemCheck: {},
    List_Check: [],
  }
  title: any = "";
  files: TreeNode[];

  constructor(
    private modalService: NgbModal,
    private DanhmucService: DanhmucService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute, private router: Router,

  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const rt = this.getChild(this.activatedRoute);
      rt.data.subscribe(data => {
        this.title = data.title;
      });
    });
  }
  ngOnInit() {
    if (JSON.parse(localStorage.getItem('thongtinuser')) != undefined) {
      this.objloaddata.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
      this.objloaddata.Nam = JSON.parse(localStorage.getItem('thongtinuser')).Nam;
      this.objloaddata.TenThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).Ten;
    }
    this.GetListdmLoaiCongTrinh();

  }
  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
 
  GetListdmLoaiCongTrinh(isThemMoi?) {
    this.DanhmucService.GetListdmLoaiCongTrinh(this.objloaddata.IDThongTinUser || 0, this.title, this.objloaddata.sFilter || "", this.objloaddata.sOrder || "").subscribe(res => {
      this.files = [];
      this.List = Object.assign([], res);
      if (isThemMoi !== true) {
        let item = res.find(ele => ele.ID === (this.itemSELeCT || {}).ID);
        if (item !== undefined)
          item.select = true;
      }
      let listParent = res.filter(e => e.IDParent === 0);
      if (listParent.length > 0) {
        listParent.forEach(element => {
          if (element.STT === listParent.length) {
            element.isKhongXuong = true;
          }
          let data: any = { "data": element, "children": [], "expanded": true };
          
          data = (this.deQuydmLoaiCongTrinh(element, res, this.dataCheck.List_Check || []));
          this.files.push(data);
        });
      }

      if (isThemMoi === true) {
        this.itemSELeCT = this.List.find(ele => ele.ID === this.dataCheck.itemCheck.ID)
        this.itemSELeCT.select = true;
        this.scrollToCenter();
      }
    });
  }
  deQuydmLoaiCongTrinh(itemParent, listdata, listCheck) {
    let children = listdata.filter(e => e.IDParent === itemParent.ID);
    let childrenAdd = [];
    let dataCheck = listCheck.find(ele => ele.data.ID == itemParent.ID)
    let data = { "data": itemParent, "children": [], "expanded": true };
    if (dataCheck !== undefined) {
      if (dataCheck.expanded === false) {
        (dataCheck.children || []).forEach(element => {
          element.expanded = false
        });
        data.expanded = false;
      }
    }
    children.forEach(elChi => {
      if (elChi.STT === children.length) {
        elChi.isKhongXuong = true;
      }
      let dataAdd = (this.deQuydmLoaiCongTrinh(elChi, listdata, (dataCheck || {}).children || []));


      childrenAdd.push(dataAdd);
    });

    data.children = childrenAdd;
    return data;
  }
  SelectTREE(item) {
    for (let i = 0; i < this.files.length; i++) {
      this.files[i].data.select = false;
      if (this.files[i].children.length > 0) {
        this.SelectchildrenTREE(this.files[i].children, item);
      }
    }
    this.itemSELeCT = item;
    item.select = true;
  }

  SelectchildrenTREE(Listchildren, item) {
    for (let i = 0; i < Listchildren.length; i++) {
      Listchildren[i].data.select = false;
      if (Listchildren[i].children.length > 0) {
        this.SelectchildrenTREE(Listchildren[i].children, item);
      }
    }
  }
  themmoi() {
    this.initTable();
    this.dataCheck.List_Check = this.files;
    const modalRef = this.modalService.open(ThemmoitreeComponent, { backdrop: 'static', size: 'lg', });
    modalRef.componentInstance.type = 'themmoi';
    modalRef.componentInstance.Loai = this.title;
    modalRef.componentInstance.IDParent = this.itemSELeCT;
    modalRef.componentInstance.List = this.List;
    modalRef.result.then((data) => {
this.dataCheck.itemCheck = data.data;
      this.GetListdmLoaiCongTrinh(true);
    }, (reason) => {
      // không
    });
  }

  capnhat(item) {
    this.dataCheck.List_Check = this.files;
    const modalRef = this.modalService.open(ThemmoitreeComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.type = "capnhat";
    modalRef.componentInstance.Loai = this.title;
    modalRef.componentInstance.IDParent = item;
    modalRef.componentInstance.List = this.List;
    modalRef.result.then((data) => {
      this.GetListdmLoaiCongTrinh();
    }, (reason) => {
      // không
    });
  }

  xoa(item) {
    const modalRef = this.modalService.open(ThongbaoComponent, { backdrop: 'static' });
    modalRef.componentInstance.thongbao = "Bạn có chắc chắn muốn xóa?";
    modalRef.result.then((any) => {
      this.DanhmucService.DeletedmLoaiCongTrinh(item).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
        }
        else {
          this.toastr.error(res.message);
        }
        this.GetListdmLoaiCongTrinh();
      });
    }, (reason) => {
      // không
    });
  }

  refresh() {
    this.objloaddata.sFilter = "";
    this.GetListdmLoaiCongTrinh();
  }
  scrollToCenter() {
    let i = 0;
    for (let j = 0; j < this.files.length; j++) {
      i++;
      if(this.files[j].expanded === false)
       continue;
      if ((this.files[j].data || {}).ID == this.itemSELeCT.ID) {
        var elmnt = document.getElementById((i || 0).toString());
        if(elmnt=== null)
          elmnt = document.getElementById(((i || 0) - 1).toString());
        elmnt.scrollIntoView();
        break;
      }
      if (this.files[j].children.length > 0)
        i = this.dequytinhnode(this.files[j], i);
    }
  }
  dequytinhnode(item, i) {
    for (let j = 0; j < item.children.length; j++) {
      i++;
      if(item.children[j].expanded === false)
       continue;
      if ((item.children[j].data || {}).ID == this.itemSELeCT.ID) {
        var elmnt = document.getElementById((i || 0).toString());
        if(elmnt=== null)
          elmnt = document.getElementById(((i || 0) - 1).toString());
        elmnt.scrollIntoView();
        break;
      }
      if (item.children[j].children.length > 0)
        i = this.dequytinhnode(item.children[j], i);
    }
    return i;
  }
  
  initTable() {
    var tables = document
      .getElementsByTagName("tr");
    for (let i = 0; i < tables.length; i++) {
      var table = tables[i];
      table.setAttribute("id", (i + 1).toString());
    }
  }
  SetViTridmLoaiCongTrinh(ID, isLen) {
    this.DanhmucService.SetViTridmLoaiCongTrinh(ID, isLen).subscribe(res => {
      if (res.State === 1) {
        this.toastr.success(res.message);
      }
      else {
        this.toastr.error(res.message);
      }
      this.GetListdmLoaiCongTrinh();
    });
  }
}
