import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

export const httpOptions = {
    headers: new HttpHeaders({
        //'Content-Type':  'application/x-www-form-urlencoded',
        'Content-Type': 'application/json',
        //'Accept':  'application/json',
        //'Origin': 'http://serverduan:2626'

        //,'Authorization': 'my-auth-token',
    })
    , withCredentials: true
};


@Injectable({
  providedIn: 'root'
})
export class HostService {

  constructor() { }

  // location
   public static serverUrl = 'https://svdt.cuckinhtexd.gov.vn/SuatVonDauTuApi/';
  public static taianh = 'https://svdt.cuckinhtexd.gov.vn/SuatVonDauTuApi/';

  // build
  // public static serverUrl = '/SuatVonDauTuApi/';
  // public static taianh = '/SuatVonDauTuApi';

 
}
