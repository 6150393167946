import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../../../services/danhmuc.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-capnhatchitieu',
  templateUrl: './capnhatchitieu.component.html',
  styleUrls: ['./capnhatchitieu.component.css']
})
export class CapnhatchitieuComponent implements OnInit {
  
  type: any = '';
  item: any = {};
  ItemPOST: any = {};
  header: any = "";
  IDParent: any = {};
  listcongtrinhCap1: any = [];
  listcongtrinhCap2: any = [];
  listcongtrinhCap3: any = [];
  listcongtrinhCap4: any = [];
  IDdmLoaiCongTrinhCap1: any = {};
  IDdmLoaiCongTrinhCap2: any = {};
  IDdmLoaiCongTrinhCap3: any = {};
  IDdmLoaiCongTrinhCap4: any = {};

  constructor(
    private activeModal: NgbActiveModal,
    private DanhmucService: DanhmucService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    console.log(this.listcongtrinhCap1)
    if (this.type == 'themmoi') {
      this.header = "Thêm mới";
    }
    else {
      this.header = "Cập nhật";
      this.ItemPOST = Object.assign({},this.item);
    }
    this.GetListdmLoaiCongTrinhCap1();
  }
  GetListdmLoaiCongTrinhCap1() {
    let data: any = {
      CurrentPage: 0,
      PageSize: 0,
      sFilter: "",
    };
    this.DanhmucService.GetListdmLoaiCongTrinhCap1(data).subscribe(res => {
      this.listcongtrinhCap1 = res;
      this.ItemPOST.IDdmLoaiCongTrinhCap1 = this.listcongtrinhCap1[0].ID;
      this.GetListdmLoaiCongTrinhCap2();
    });
  }
  GetListdmLoaiCongTrinhCap2() {
    let data: any = {
      CurrentPage: 0,
      PageSize: 0,
      sFilter: "",
      IDdmLoaiCongTrinhCap1 : this.ItemPOST.IDdmLoaiCongTrinhCap1,
    };
    this.DanhmucService.GetListdmLoaiCongTrinhCap2(data).subscribe(res => {
      this.listcongtrinhCap2 = res;
      this.ItemPOST.IDdmLoaiCongTrinhCap2 = this.listcongtrinhCap2[0].ID;
      this.GetListdmLoaiCongTrinhCap3();
    });
  }
  GetListdmLoaiCongTrinhCap3() {
    let data: any = {
      CurrentPage: 0,
      PageSize: 0,
      sFilter: "",
      IDdmLoaiCongTrinhCap2 : this.ItemPOST.IDdmLoaiCongTrinhCap2,
    };
    this.DanhmucService.GetListdmLoaiCongTrinhCap3(data).subscribe(res => {
      this.listcongtrinhCap3 = res;
      this.ItemPOST.IDdmLoaiCongTrinhCap1 = this.listcongtrinhCap1[0].ID;
      this.GetListdmLoaiCongTrinhCap4();
    });
  }
  GetListdmLoaiCongTrinhCap4() {
    let data: any = {
      CurrentPage: 0,
      PageSize: 0,
      sFilter: "",
      IDdmLoaiCongTrinhCap3 : this.ItemPOST.IDdmLoaiCongTrinhCap3,
    };
    this.DanhmucService.GetListdmLoaiCongTrinhCap4(data).subscribe(res => {
      this.listcongtrinhCap4 = res;
    });
  }
  SetData() {
    let data: any = {
      "ID": this.type == 'themmoi' ? 0 : this.item.ID,
      "Ma": this.ItemPOST.Ma !== undefined && this.ItemPOST.Ma !== "" ? this.ItemPOST.Ma : "",
      "Ten": this.ItemPOST.Ten !== undefined && this.ItemPOST.Ten !== "" ? this.ItemPOST.Ten : "",
      "IDdmLoaiCongTrinhCap4": this.ItemPOST.IDdmLoaiCongTrinhCap4 !== undefined ? this.ItemPOST.IDdmLoaiCongTrinhCap4 : 0,      
      "IDdmLoaiCongTrinhCap3": this.ItemPOST.IDdmLoaiCongTrinhCap3 !== undefined ? this.ItemPOST.IDdmLoaiCongTrinhCap3 : 0,      
      "IDdmLoaiCongTrinhCap2": this.ItemPOST.IDdmLoaiCongTrinhCap2 !== undefined ? this.ItemPOST.IDdmLoaiCongTrinhCap2 : 0,      
      "IDdmLoaiCongTrinhCap1": this.ItemPOST.IDdmLoaiCongTrinhCap1 !== undefined ? this.ItemPOST.IDdmLoaiCongTrinhCap1 : 0,      
      "MoTa": this.ItemPOST.MoTa !== undefined && this.ItemPOST.MoTa !== "" ? this.ItemPOST.MoTa : "",
      "GhiChu": this.ItemPOST.GhiChu !== undefined && this.ItemPOST.GhiChu !== "" ? this.ItemPOST.GhiChu : "",
      "Loai": this.ItemPOST.Loai !== undefined ? this.ItemPOST.Loai : 0,
    }
    return data;
  }

  checkdata() {
    if (this.ItemPOST.Ma != undefined && this.ItemPOST.Ma != null && this.ItemPOST.Ma != "" &&
      this.ItemPOST.Ten != undefined && this.ItemPOST.Ten != null && this.ItemPOST.Ten != ""
    ) {
      return true;
    }
    else {
      return false;
    }
  }

  XacNhan() {
    if (this.checkdata()) {
      this.DanhmucService.SetdmChiTieu(this.SetData()).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.Onclose();
        }
        else {
          this.toastr.error(res.message);
        }
      });
    }
    else {
      this.toastr.error("Yêu cầu nhập đầy đủ trường bắt buộc");
    }   
  }


  Onclose() {
    this.activeModal.close();
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }


}
