import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../../../services/danhmuc.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
@Component({
  selector: 'app-luachonptcsg',
  templateUrl: './luachoncsg.component.html',
  styleUrls: ['./luachoncsg.component.css']
})
export class LuaChonPhuongThucCSGComponent implements OnInit {

  type: any = '';
  item: any = {};
  ItemPOST: any = {};
  thongtinuser: any = {};
  header: any = "";
  IDParent: any = {};
  dataitem: any = {};
  dataitemThongTinUser: any = {};
  ListItemPost: any = [];
  ListTinhPost: any = [];
  Listtree: any = [];
  ListVLNCM: any = [];
  
  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private DanhmucService: DanhmucService,
    private toastr: ToastrService,
    private router: Router,
  ) {}
  ngOnInit() {
    this.ItemPOST.LoaiTinhCSG = 'DiaPhuong';
  }
  
  // themmoi() {
  //   this.activeModal.close();
  //   const modalRef = this.modalService.open(LuaChonTinhCSGComponent, {size:'lg', backdrop: 'static', centered: true });
  //   modalRef.componentInstance.ItemPOST.LoaiTinhCSG = this.ItemPOST.LoaiTinhCSG;  
  //   modalRef.componentInstance.ListTinhPost = this.ListTinhPost;
  //   modalRef.componentInstance.Listtree = this.Listtree;
  //   modalRef.componentInstance.ListtreeVLNCM = this.ListVLNCM;
  //   modalRef.result.then((data) => {
  //     this.dataitemThongTinUser = data;
  //   }, (reason) => {
  //     // không
  //   });
  //     // this.router.navigate(['/ThongTinChung/GiaVatLieuNhanCongMay']);
  // }
  Onclose() {
    this.router.navigate(['/ThongTinUser']);
    location.reload();
    this.activeModal.close();
  }
  Ondismiss() {
    this.activeModal.dismiss();
  }
}
