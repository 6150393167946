import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HostService, httpOptions } from './host.service';

@Injectable({
  providedIn: 'root'
})
export class DanhmucService {

  constructor(
    private http: HttpClient
  ) { }

  public danhmucUrl = `${HostService.serverUrl}DanhMuc`;

  GetListdmLoaiCongTrinhCap1(data) {
    let url = `${this.danhmucUrl}/GetListdmLoaiCongTrinhCap1`;
    return this.http.post<any>(url, data, httpOptions);
  }
  GetListdsCongBo(data) {
    let url = `${this.danhmucUrl}/GetListdsCongBo`;
    return this.http.post<any>(url, data, httpOptions);
  }
  GetdsCongBo(Id) {
    let url = `${this.danhmucUrl}/GetdsCongBo?ID=${Id}`;
    return this.http.get<any>(url, httpOptions);
  }
  DeleteCongBo(data) {
    let url = `${this.danhmucUrl}/DeleteCongBo`;
    return this.http.post<any>(url, data, httpOptions);
  }
  SetCongBo(ID) {
    let url = `${this.danhmucUrl}/SetCongBo?ID=${ID}`;
    return this.http.get<any>(url, httpOptions);
  }
  ExportCongBo(data) {
    let url = `${this.danhmucUrl}/ExportCongBo`;
    return this.http.post<any>(url, data, httpOptions);
  }
  ExportCongBoTrenCuc(data) {
    let url = `http://cuckinhtexd.gov.vn/api/pushdata/svdt`;
    return this.http.post<any>(url, data, httpOptions);
  }
  KiemTraTrungdmDungChung(TenCongTrinh, UserName) {
    let url = `${this.danhmucUrl}/KiemTraTrungdmDungChung?TenCongTrinh=${TenCongTrinh}&UserName=${UserName}`;
    return this.http.get<any>(url, httpOptions);
  }
  SaveCongTrinhDienHinh(data) {
    let url = `${this.danhmucUrl}/SaveCongTrinhDienHinh`;
    return this.http.post<any>(url, data, httpOptions);
  }
  DeletedmDungChungSVDT(IddmDungChung) {
    let url = `${this.danhmucUrl}/DeletedmDungChungSVDT?IddmDungChung=${IddmDungChung}`;
    return this.http.get<any>(url, httpOptions);
  }
  GetListUser() {
    let url = `${this.danhmucUrl}/GetListUser`;
    return this.http.get<any>(url, httpOptions);
  }
  DeleteUser(data) {
    let url = `${this.danhmucUrl}/DeleteUser`;
    return this.http.post<any>(url, data, httpOptions);
  }
  SetUser(data) {
    let url = `${this.danhmucUrl}/SetUser`;
    return this.http.post<any>(url, data, httpOptions);
  }
  KiemTraPass(Password) {
    let url = `${this.danhmucUrl}/KiemTraPass?Password=${Password}`;
    return this.http.get<any>(url, httpOptions);
  }
  GetListdmLoaiCongTrinhForID(ID, IDThongTinUser, LoaiDanhMuc) {
    let url = `${this.danhmucUrl}/GetListdmLoaiCongTrinhForID?ID=${ID}&IDThongTinUser=${IDThongTinUser}&LoaiDanhMuc=${LoaiDanhMuc}`;
    return this.http.get<any>(url, httpOptions);
  }
  GetListdsDungChung() {
    let url = `${this.danhmucUrl}/GetListdsDungChung`;
    return this.http.get<any>(url, httpOptions);
  }
  GetdsDungChungCongTrinhDienHinhSVDT(IDdsDungChungLoaiCongTrinh) {
    let url = `${this.danhmucUrl}/GetdsDungChungCongTrinhDienHinhSVDT?IDdsDungChungLoaiCongTrinh=${IDdsDungChungLoaiCongTrinh}`;
    return this.http.get<any>(url, httpOptions);
  }
  SetdmLoaiCongTrinhCap1(data) {
    let url = `${this.danhmucUrl}/SetdmLoaiCongTrinhCap1`;
    return this.http.post<any>(url, data, httpOptions);
  }
  SetdmLoaiCongTrinh(data) {
    let url = `${this.danhmucUrl}/SetdmLoaiCongTrinh`;
    return this.http.post<any>(url, data, httpOptions);
  }
  SetListdmLoaiCongTrinh(data) {
    let url = `${this.danhmucUrl}/SetListdmLoaiCongTrinh`;
    return this.http.post<any>(url, data, httpOptions);
  }
  DeletedmCapCongTrinh(data) {
    let url = `${this.danhmucUrl}/DeletedmCapCongTrinh`;
    return this.http.post<any>(url, data, httpOptions);
  }
  DeletedmLoaiCongTrinh(data) {
    let url = `${this.danhmucUrl}/DeletedmLoaiCongTrinh`;
    return this.http.post<any>(url, data, httpOptions);
  }
  SetViTridmLoaiCongTrinh(ID, isLen) {
    let url = `${this.danhmucUrl}/SetViTridmLoaiCongTrinh?ID=${ID}&isLen=${isLen}`;
    return this.http.get<any>(url, httpOptions);
  }
  DeletedmLoaiCongTrinhCap1(data) {
    let url = `${this.danhmucUrl}/DeletedmLoaiCongTrinhCap1`;
    return this.http.post<any>(url, data, httpOptions);
  }
  GetListThongTinUser(data) {
    let url = `${this.danhmucUrl}/GetListThongTinUser`;
    return this.http.post<any>(url, data, httpOptions);
  }
  SetThongTinUser(data) {
    let url = `${this.danhmucUrl}/SetThongTinUser`;
    return this.http.post<any>(url, data, httpOptions);
  }
  DeleteThongTinUser(data) {
    let url = `${this.danhmucUrl}/DeleteThongTinUser`;
    return this.http.post<any>(url, data, httpOptions);
  }
  GetListdmLoaiCongTrinhParent(IDThongTinUser,LoaiDanhMuc) {
    let url = `${this.danhmucUrl}/GetListdmLoaiCongTrinhParent?IDThongTinUser=${IDThongTinUser}&LoaiDanhMuc=${LoaiDanhMuc}`;
    return this.http.get<any>(url, httpOptions);
  }
  GetListdmLoaiCongTrinhParentFull(IDThongTinUser,LoaiDanhMuc) {
    let url = `${this.danhmucUrl}/GetListdmLoaiCongTrinhParentFull?IDThongTinUser=${IDThongTinUser}&LoaiDanhMuc=${LoaiDanhMuc}`;
    return this.http.get<any>(url, httpOptions);
  }
  GetListdmLoaiCongTrinh(IDThongTinUser, LoaiDanhMuc, sFilter, sOrder) {
    let url = `${this.danhmucUrl}/GetListdmLoaiCongTrinh?IDThongTinUser=${IDThongTinUser}&LoaiDanhMuc=${LoaiDanhMuc}&sFilter=${sFilter}&sOrder=${sOrder}`;
    return this.http.get<any>(url, httpOptions);
  }
  GetListdmLoaiCongTrinhThietLapBanDau() {
    let url = `${this.danhmucUrl}/GetListdmLoaiCongTrinhThietLapBanDau`;
    return this.http.get<any>(url, httpOptions);
  }
  GetListdmLoaiCongTrinhCap2(data) {
    let url = `${this.danhmucUrl}/GetListdmLoaiCongTrinhCap2`;
    return this.http.post<any>(url, data, httpOptions);
  }
  SetdmLoaiCongTrinhCap2(data) {
    let url = `${this.danhmucUrl}/SetdmLoaiCongTrinhCap2`;
    return this.http.post<any>(url, data, httpOptions);
  }

  DeletedmLoaiCongTrinhCap2(data) {
    let url = `${this.danhmucUrl}/DeletedmLoaiCongTrinhCap2`;
    return this.http.post<any>(url, data, httpOptions);
  }

  GetListdmLoaiCongTrinhCap3(data) {
    let url = `${this.danhmucUrl}/GetListdmLoaiCongTrinhCap3`;
    return this.http.post<any>(url, data, httpOptions);
  }

  SetdmLoaiCongTrinhCap3(data) {
    let url = `${this.danhmucUrl}/SetdmLoaiCongTrinhCap3`;
    return this.http.post<any>(url, data, httpOptions);
  }

  DeletedmLoaiCongTrinhCap3(data) {
    let url = `${this.danhmucUrl}/DeletedmLoaiCongTrinhCap3`;
    return this.http.post<any>(url, data, httpOptions);
  }

  GetListdmLoaiCongTrinhCap4(data) {
    let url = `${this.danhmucUrl}/GetListdmLoaiCongTrinhCap4`;
    return this.http.post<any>(url, data, httpOptions);
  }

  SetdmLoaiCongTrinhCap4(data) {
    let url = `${this.danhmucUrl}/SetdmLoaiCongTrinhCap4`;
    return this.http.post<any>(url, data, httpOptions);
  }

  DeletedmLoaiCongTrinhCap4(data) {
    let url = `${this.danhmucUrl}/DeletedmLoaiCongTrinhCap4`;
    return this.http.post<any>(url, data, httpOptions);
  }

  GetListdmChiTieu(data) {
    let url = `${this.danhmucUrl}/GetListdmChiTieu`;
    return this.http.post<any>(url, data, httpOptions);
  }

  SetdmChiTieu(data) {
    let url = `${this.danhmucUrl}/SetdmChiTieu`;
    return this.http.post<any>(url, data, httpOptions);
  }

  DeletedmChiTieu(data) {
    let url = `${this.danhmucUrl}/DeletedmChiTieu`;
    return this.http.post<any>(url, data, httpOptions);
  }

  GetListdmCapCongTrinh(data) {
    let url = `${this.danhmucUrl}/GetListdmCapCongTrinh`;
    return this.http.post<any>(url, data, httpOptions);
  }

  SetdmCapCongTrinh(data) {
    let url = `${this.danhmucUrl}/SetdmCapCongTrinh`;
    return this.http.post<any>(url, data, httpOptions);
  }

  GetListdmKhuVuc(data) {
    let url = `${this.danhmucUrl}/GetListdmKhuVuc`;
    return this.http.post<any>(url, data, httpOptions);
  }

  SetdmKhuVuc(data) {
    let url = `${this.danhmucUrl}/SetdmKhuVuc`;
    return this.http.post<any>(url, data, httpOptions);
  }

  DeletedmKhuVuc(data) {
    let url = `${this.danhmucUrl}/DeletedmKhuVuc`;
    return this.http.post<any>(url, data, httpOptions);
  }

  GetListdmTinh(data) {
    let url = `${this.danhmucUrl}/GetListdmTinh`;
    return this.http.post<any>(url, data, httpOptions);
  }

  SetdmTinh(data) {
    let url = `${this.danhmucUrl}/SetdmTinh`;
    return this.http.post<any>(url, data, httpOptions);
  }

  DeletedmTinh(data) {
    let url = `${this.danhmucUrl}/DeletedmTinh`;
    return this.http.post<any>(url, data, httpOptions);
  }

  GetListdmNhomCongTrinh(data) {
    let url = `${this.danhmucUrl}/GetListdmNhomCongTrinh`;
    return this.http.post<any>(url, data, httpOptions);
  }

  SetdmNhomCongTrinh(data) {
    let url = `${this.danhmucUrl}/SetdmNhomCongTrinh`;
    return this.http.post<any>(url, data, httpOptions);
  }

  DeletedmNhomCongTrinh(data) {
    let url = `${this.danhmucUrl}/DeletedmNhomCongTrinh`;
    return this.http.post<any>(url, data, httpOptions);
  }

  GetListdmTinhTheoUser(data) {
    let url = `${this.danhmucUrl}/GetListdmTinhTheoUser`;
    return this.http.post<any>(url, data, httpOptions);
  }

  GetListdmVatLieuNhanCongMay(data) {
    let url = `${this.danhmucUrl}/GetListdmVatLieuNhanCongMay`;
    return this.http.post<any>(url, data, httpOptions);
  }

  SetdmVatLieuNhanCongMay(data) {
    let url = `${this.danhmucUrl}/SetdmVatLieuNhanCongMay`;
    return this.http.post<any>(url, data, httpOptions);
  }

  DeletedmVatLieuNhanCongMay(data) {
    let url = `${this.danhmucUrl}/DeletedmVatLieuNhanCongMay`;
    return this.http.post<any>(url, data, httpOptions);
  }

  //#endregion
  listLogin(data) {
    let api = `https://dmdgxd.cuckinhtexd.gov.vn/api/api/checkapp/check`;
    return this.http.post<any>(api, data, httpOptions);
  } 
  GetListdmGiaiDoanDuAn(data) {
    let url = `${this.danhmucUrl}/GetListdmGiaiDoanDuAn`;
    return this.http.post<any>(url, data, httpOptions);
  }

  SetdmGiaiDoanDuAn(data) {
    let url = `${this.danhmucUrl}/SetdmGiaiDoanDuAn`;
    return this.http.post<any>(url, data, httpOptions);
  }
  DeletedmGiaiDoanDuAn(data) {
    let url = `${this.danhmucUrl}/DeletedmGiaiDoanDuAn`;
    return this.http.post<any>(url, data, httpOptions);
  }
  GetListFileMau() {
    let url = `${this.danhmucUrl}/GetListFileMau`;
    return this.http.get<any>(url, httpOptions);
  }
  GetListDuAnCongTrinh(IddmTinh, IddmLoaiCongTrinh) {
    let url = `http://csg.cuckinhtexd.gov.vn/SmartCE/GetListDuAnCongTrinh?IddmTinh=${IddmTinh}&IddmLoaiCongTrinh=${IddmLoaiCongTrinh}`;
    return this.http.get<any>(url, httpOptions);
  }
  GetCongTrinhDienHinhTrenCuc(Id) {
    let url = `${this.danhmucUrl}/GetCongTrinhDienHinhTrenCuc?Id=${Id}`;
    return this.http.get<any>(url, httpOptions);
  }
  GetListdmDungChungCongTrinhDienHinh(LoaiDanhMuc, CurrentPage, UserName) {
    let url = `${this.danhmucUrl}/GetListdmDungChungCongTrinhDienHinh?LoaiDanhMuc=${LoaiDanhMuc}&CurrentPage=${CurrentPage}&UserName=${UserName}`;
    return this.http.get<any>(url, httpOptions);
  }
  GetListdmTinhCucKinhTe() {
    let url = `http://csg.cuckinhtexd.gov.vn/SmartCE/GetListdmTinh`;
    return this.http.get<any>(url, httpOptions);
  }
  GetListdmLoaiCongTrinhCucKinhTe() {
    let url = `http://csg.cuckinhtexd.gov.vn/SmartCE/GetListdmLoaiCongTrinh`;
    return this.http.get<any>(url, httpOptions);
  }
  GetListDonViBanHanh() {
    let url = `http://csg.cuckinhtexd.gov.vn/SmartCE/GetListDonViBanHanh`;
    return this.http.get<any>(url, httpOptions);
  }
  SetdmDungChung(data) {
    let url = `${this.danhmucUrl}/SetdmDungChung`;
    return this.http.post<any>(url, data, httpOptions);
  }
  DeletedmDungChung(ID) {
    let url = `${this.danhmucUrl}/DeletedmDungChung?ID=${ID}`;
    return this.http.get<any>(url, httpOptions);
  }
  getListdmDungChung(LoaiDanhMuc) {
    let url = `${this.danhmucUrl}/GetlistdmDungChung?LoaiDanhMuc=${LoaiDanhMuc}`;
    return this.http.get<any>(url, httpOptions);
  }
  getListdmLoaiCongTrinhDungChung(IDdmDungChung) {
    let url = `${this.danhmucUrl}/getListdmLoaiCongTrinhDungChung?IDdmDungChung=${IDdmDungChung}`;
    return this.http.get<any>(url, httpOptions);
  }
  CheckUser() {
    let url = `${this.danhmucUrl}/CheckUser`;
    return this.http.get<any>(url, httpOptions);
  }
  GetHDSD() {
    let url = `${this.danhmucUrl}/GetHDSD`;
    return this.http.get<any>(url, httpOptions);
  }
}

