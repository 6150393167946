import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-huongdanphimtat',
  templateUrl: './huongdanphimtat.component.html',
  styleUrls: ['./huongdanphimtat.component.css']
})
export class HuongdanphimtatComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
