import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login/login.component';
import { AuthGuard } from './_guard/auth.guard';
import { DanhmucchuongcongtrinhComponent } from './danhmuc/danhmucchuongcongtrinh/danhmucchuongcongtrinh.component';
import { DanhmucloaicongtrinhComponent } from './danhmuc/danhmucloaicongtrinh/danhmucloaicongtrinh.component';
import { DanhmucnhomcongtrinhComponent } from './danhmuc/danhmucnhomcongtrinh/danhmucnhomcongtrinh.component';
import { DanhmuccongtrinhComponent } from './danhmuc/danhmuccongtrinh/danhmuccongtrinh.component';
import { DanhmucchitieuComponent } from './danhmuc/danhmucchitieu/danhmucchitieu.component';
import { HesodieuchinhComponent } from './suatvondautu/hesodieuchinh/hesodieuchinh.component';
import { DinhmucsuatvonComponent } from './suatvondautu/dinhmucsuatvon/dinhmucsuatvon.component';
import { GiaComponent } from './giaxaydung/gia/gia.component';
import { HesokhoanmucchiphiComponent } from './giaxaydung/hesokhoanmucchiphi/hesokhoanmucchiphi.component';
import { GiabophanketcaucongtrinhComponent } from './giaxaydung/giabophanketcaucongtrinh/giabophanketcaucongtrinh.component';
import { DanhmuccapComponent } from './danhmuc/danhmuccap/danhmuccap.component';
import { DanhmuckhuvucComponent } from './danhmuc/danhmuckhuvuc/danhmuckhuvuc.component';
import { DanhmuctinhComponent } from './danhmuc/danhmuctinh/danhmuctinh.component';
import { Loaicapcongtrinh3Component } from './danhmuc/loaicapcongtrinh3/loaicapcongtrinh3.component';
import { CongtrinhdienhinhComponent } from './giaxaydung/congtrinhdienhinh/congtrinhdienhinh.component';
import { Congtrinhdienhinh1Component } from './suatvondautu/congtrinhdienhinh1/congtrinhdienhinh1.component';
import { VatlieuCongnhanMayComponent } from './danhmuc/vatlieu-congnhan-may/vatlieu-congnhan-may.component';
import { ThongtinuserComponent } from './thongtinuser/thongtinuser/thongtinuser.component';
import { DanhmucuserComponent } from './danhmuc/danhmucuser/danhmucuser.component';
import { CongboComponent } from './giaxaydung/dscongbo/congbo/congbo.component';
import { DscongboComponent } from './giaxaydung/dscongbo/dscongbo.component';
import { DanhMucDungChungComponent } from './danhmucdungchung/danhmucdungchung.component';
import { BaoCaoComponent } from './baocao/baocao/baocao.component';
import { DmgiaidoanduanComponent } from './danhmuc/dmgiaidoanduan/dmgiaidoanduan.component';
import { FilemauComponent } from './danhmuc/filemau/filemau.component';
import { HuongdandangnhapComponent } from './huongdansudung/huongdandangnhap/huongdandangnhap.component';
import { HuongdannhapsuatvondautuComponent } from './huongdansudung/huongdannhapsuatvondautu/huongdannhapsuatvondautu.component';
import { HuongdansuatvondautuComponent } from './huongdansudung/huongdansuatvondautu/huongdansuatvondautu.component';
import { HuongdannhapgiabophankecauComponent } from './huongdansudung/huongdannhapgiabophankecau/huongdannhapgiabophankecau.component';
import { HuongdangiabophankecauComponent } from './huongdansudung/huongdangiabophankecau/huongdangiabophankecau.component';
import { HuongdancongboComponent } from './huongdansudung/huongdancongbo/huongdancongbo.component';
import { HuongdandanhmucComponent } from './huongdansudung/huongdandanhmuc/huongdandanhmuc.component';
import { HuongdanthuviendungchungComponent } from './huongdansudung/huongdanthuviendungchung/huongdanthuviendungchung.component';
import { HuongdanphimtatComponent } from './huongdansudung/huongdanphimtat/huongdanphimtat.component';

const routes: Routes = [
  {
    path: "",
    redirectTo: "/Login",
    pathMatch: "full",
  },
  {
    path: "Login",
    component: LoginComponent,
    // canActivate: [!AuthGuard],
  },
  {
    path: "ThongTinUser",
    component: ThongtinuserComponent,
    // canActivate: [!AuthGuard],
  },
  {
    path: "DanhMuc",
    children: [
      {
        path: "LoaiCapCongTrinh1",
        // canActivate: [AuthGuard],
        component: DanhmucchuongcongtrinhComponent,
        data: {title : 'SuatVon'}
      },
      {
        path: "LoaiCongTrinhGiaBoPhan",
        // canActivate: [AuthGuard],
        component: DanhmucchuongcongtrinhComponent,
        data: {title : 'GiaBoPhan'}
      },
      {
        path: "LoaiCapCongTrinh2",
        // canActivate: [AuthGuard],
        component: DanhmucloaicongtrinhComponent,
      },      
      {
        path: "LoaiCapCongTrinh3",
        // canActivate: [AuthGuard],
        component: Loaicapcongtrinh3Component,
      },    
      {
        path: "LoaiCapCongTrinh4",
        // canActivate: [AuthGuard],
        component: DanhmuccongtrinhComponent,
      }, 
      {
        path: "DanhMucNhomCongTrinh",
        // canActivate: [AuthGuard],
        component: DanhmucnhomcongtrinhComponent,
      },
      {
        path: "DanhMucChiTieu",
        // canActivate: [AuthGuard],
        component: DanhmucchitieuComponent,
      },   
      {
        path: "DanhMucCapCongTrinh",
        // canActivate: [AuthGuard],
        component: DanhmuccapComponent,
      },  
      {
        path: "DanhMucKhuVuc",
        // canActivate: [AuthGuard],
        component: DanhmuckhuvucComponent,
      },     
      {
        path: "DanhMucTinh",
        // canActivate: [AuthGuard],
        component: DanhmuctinhComponent,
      },
      {
        path: "DanhMucUser",
        // canActivate: [AuthGuard],
        component: DanhmucuserComponent,
      }, 
      {
        path: "DanhMucDungChung",
        // canActivate: [AuthGuard],
        component: DanhMucDungChungComponent,
      }, 
      {
        path: "dmgiaidoanduan",
        // canActivate: [AuthGuard],
        component: DmgiaidoanduanComponent,
      }, 
      {
        path: "filemau",
        // canActivate: [AuthGuard],
        component: FilemauComponent,
      }, 
    ]
  },
  {
    path: "SuatVonDauTu",
    children: [   
      {
        path: "NhapDinhMucSuatVon",
        // canActivate: [AuthGuard],
        component: Congtrinhdienhinh1Component,
      },  
      {
        path: "TinhSuatVon",
        // canActivate: [AuthGuard],
        component: DinhmucsuatvonComponent,
      },      
      {
        path: "NhapDinhMucSuatVonDanDung",
        // canActivate: [AuthGuard],
        component: Congtrinhdienhinh1Component,
        data: {title : 'CONGTRINHDANDUNG'}
      },
      {
        path: "NhapDinhMucSuatVonCongNghiep",
        // canActivate: [AuthGuard],
        component: Congtrinhdienhinh1Component,
        data: {title : 'CONGTRINHCONGNGHIEP'}
      },
      {
        path: "NhapDinhMucSuatVonKyThuat",
        // canActivate: [AuthGuard],
        component: Congtrinhdienhinh1Component,
        data: {title : 'CONGTRINHHATANGKYTHUAT'}
      },
      {
        path: "NhapDinhMucSuatVonGiaoThong",
        // canActivate: [AuthGuard],
        component: Congtrinhdienhinh1Component,
        data: {title : 'CONGTRINHGIAOTHONG'}
      },
      {
        path: "NhapDinhMucSuatVonNongThon",
        // canActivate: [AuthGuard],
        component: Congtrinhdienhinh1Component,
        data: {title : 'CONGTRINHNONGNGHIEPVANONGTHON'}
      },
      {
        path: "HeSoDieuChinh",
        // canActivate: [AuthGuard],
        component: HesodieuchinhComponent,
      },
      {
        path: "DinhMucSuatVon",
        // canActivate: [AuthGuard],
        component: DinhmucsuatvonComponent,
      },
      {
        path: "DinhMucSuatVonDanDung",
        // canActivate: [AuthGuard],
        component: DinhmucsuatvonComponent,
        data: {title : 'CONGTRINHDANDUNG'}
      },    
      {
        path: "DinhMucSuatVonCongNghiep",
        // canActivate: [AuthGuard],
        component: DinhmucsuatvonComponent,
        data: {title : 'CONGTRINHCONGNGHIEP'}
      },
      {
        path: "DinhMucSuatVonKyThuat",
        // canActivate: [AuthGuard],
        component: DinhmucsuatvonComponent,
        data: {title : 'CONGTRINHHATANGKYTHUAT'}
      },
      {
        path: "DinhMucSuatVonGiaoThong",
        // canActivate: [AuthGuard],
        component: DinhmucsuatvonComponent,
        data: {title : 'CONGTRINHGIAOTHONG'}
      },
      {
        path: "DinhMucSuatVonNongThon",
        // canActivate: [AuthGuard],
        component: DinhmucsuatvonComponent,
        data: {title : 'CONGTRINHNONGNGHIEPVANONGTHON'}
      },
      {
        path: "NhapDinhMucSuatVonQuocPhong",
        // canActivate: [AuthGuard],
        component: Congtrinhdienhinh1Component,
        data: {title : 'CONGTRINHQUOCPHONG'}
      },
      {
        path: "DinhMucSuatVonQuocPhong",
        // canActivate: [AuthGuard],
        component: DinhmucsuatvonComponent,
        data: {title : 'CONGTRINHQUOCPHONG'}
      },
      {
        path: "NhapDinhMucSuatVonHonHop",
        // canActivate: [AuthGuard],
        component: Congtrinhdienhinh1Component,
        data: {title : 'CONGTRINHHONHOP'}
      },
      {
        path: "DinhMucSuatVonHonHop",
        // canActivate: [AuthGuard],
        component: DinhmucsuatvonComponent,
        data: {title : 'CONGTRINHHONHOP'}
      },
    ]
  },
  {
    path: "GiaXayDung",
    children: [
      {
        path: "NhapGiaCongTrinh",
        // canActivate: [AuthGuard],
        component: CongtrinhdienhinhComponent,
      },
      {
        path: "GiaBoPhanKetCauCongTrinh",
        // canActivate: [AuthGuard],
        component: GiabophanketcaucongtrinhComponent,
      },
      {
        path: "NhapGiaCongTrinhDanDung",
        // canActivate: [AuthGuard],
        component: CongtrinhdienhinhComponent,
        data: {title : 'CONGTRINHDANDUNG'}
      },
      {
        path: "NhapGiaCongTrinhCongNghiep",
        // canActivate: [AuthGuard],
        component: CongtrinhdienhinhComponent,
        data: {title : 'CONGTRINHCONGNGHIEP'}
      },
      {
        path: "NhapGiaCongTrinhHaTangKyThuat",
        // canActivate: [AuthGuard],
        component: CongtrinhdienhinhComponent,
        data: {title : 'CONGTRINHHATANGKYTHUAT'}
      },
      {
        path: "NhapGiaCongTrinhGiaoThong",
        // canActivate: [AuthGuard],
        component: CongtrinhdienhinhComponent,
        data: {title : 'CONGTRINHGIAOTHONG'}
      },
      {
        path: "NhapGiaCongTrinhNongThon",
        // canActivate: [AuthGuard],
        component: CongtrinhdienhinhComponent,
        data: {title : 'CONGTRINHNONGNGHIEPVANONGTHON'}
      },
      {
        path: "Gia",
        // canActivate: [AuthGuard],
        component: GiaComponent,
      },
      {
        path: "HeSoKhoanMucChiPhi",
        // canActivate: [AuthGuard],
        component: HesokhoanmucchiphiComponent,
      },
      {
        path: "GiaBoPhanKetCauCongTrinhDanDung",
        // canActivate: [AuthGuard],
        component: GiabophanketcaucongtrinhComponent,
        data: {title : 'CONGTRINHDANDUNG'}
      }, 
      {
        path: "GiaBoPhanKetCauCongTrinhCongNghiep",
        // canActivate: [AuthGuard],
        component: GiabophanketcaucongtrinhComponent,
        data: {title : 'CONGTRINHCONGNGHIEP'}
      }, 
      {
        path: "GiaBoPhanKetCauCongTrinhKyThuat",
        // canActivate: [AuthGuard],
        component: GiabophanketcaucongtrinhComponent,
        data: {title : 'CONGTRINHHATANGKYTHUAT'}
      }, 
      {
        path: "GiaBoPhanKetCauCongTrinhGiaoThong",
        // canActivate: [AuthGuard],
        component: GiabophanketcaucongtrinhComponent,
        data: {title : 'CONGTRINHGIAOTHONG'}
      }, 
      {
        path: "GiaBoPhanKetCauCongTrinhNongThon",
        // canActivate: [AuthGuard],
        component: GiabophanketcaucongtrinhComponent,
        data: {title : 'CONGTRINHNONGNGHIEPVANONGTHON'}
      },   
      {
        path: "NhapGiaCongTrinhQuocPhong",
        // canActivate: [AuthGuard],
        component: CongtrinhdienhinhComponent,
        data: {title : 'CONGTRINHQUOCPHONG'}
      }, 
      {
        path: "NhapGiaCongTrinhHonHop",
        // canActivate: [AuthGuard],
        component: CongtrinhdienhinhComponent,
        data: {title : 'CONGTRINHHONHOP'}
      }, 
      {
        path: "GiaBoPhanKetCauCongTrinhQuocPhong",
        // canActivate: [AuthGuard],
        component: GiabophanketcaucongtrinhComponent,
        data: {title : 'CONGTRINHQUOCPHONG'}
      }, 
      {
        path: "GiaBoPhanKetCauCongTrinhHonHop",
        // canActivate: [AuthGuard],
        component: GiabophanketcaucongtrinhComponent,
        data: {title : 'CONGTRINHHONHOP'}
      }, 
    ]
  }, 
  {
    path: "BaoCao",
    children: [
      {
        path: "BaoCao",
        // canActivate: [AuthGuard],
        component: BaoCaoComponent,
      },
      {
        path: "CongBo",
        component: DscongboComponent,
      },
    ]
  }, 
  {
    path: "HuongDanSuDung",
    children: [
      {
        path: "DangNhap",
        component: HuongdandangnhapComponent,
      },
      {
        path: "NhapSuatVonDauTu",
        component: HuongdannhapsuatvondautuComponent,
      },
      {
        path: "SuatVonDauTu",
        component: HuongdansuatvondautuComponent,
      },
      {
        path: "NhapGiaBoPhanKetCau",
        component: HuongdannhapgiabophankecauComponent,
      },
      {
        path: "GiaBoPhanKetCau",
        component: HuongdangiabophankecauComponent,
      },
      {
        path: "CongBo",
        component: HuongdancongboComponent,
      },
      {
        path: "DanhMuc",
        component: HuongdandanhmucComponent,
      },
      {
        path: "ThuVienDungChung",
        component: HuongdanthuviendungchungComponent,
      },
      {
        path: "PhimTat",
        component: HuongdanphimtatComponent,
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
