import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../../services/api.service';
import { DanhmucService } from './../../services/danhmuc.service';
import { MyService } from './../../services/my.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { ToastrService } from 'ngx-toastr';
import { SuatVonDauTuService } from './../../services/suatvondautu.service';

@Component({
  selector: 'app-baocao',
  templateUrl: './baocao.component.html',
  styleUrls: ['./baocao.component.css']
})
export class BaoCaoComponent implements OnInit {

  listItem: any = [];
  listtinh: any = [];
  listnam: any = [];
  listthoigian: any = [];
  dataitem: any = {};
  listkhuvuc: any = [];
  constructor(
    private DanhmucService: DanhmucService,
    private MyService: MyService,
    private toastr: ToastrService,
    private SuatVonDauTuService: SuatVonDauTuService,
  ) { }

  ngOnInit() {
    if(JSON.parse(localStorage.getItem('thongtinuser')) != null || JSON.parse(localStorage.getItem('thongtinuser')) != undefined)
    {
      this.dataitem.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
      this.dataitem.TenUser = JSON.parse(localStorage.getItem('thongtinuser')).Ten;
      this.dataitem.Nam = JSON.parse(localStorage.getItem('thongtinuser')).Nam;
    }
    this.GetListdmKhuVuc();
    this.GetListdsCongBo();
  }
  GetListdsCongBo() {
    let data: any = {
      "Nam": this.dataitem.Nam,
      "KhuVuc": this.dataitem.KhuVuc,
      "IDThongTinUser": this.dataitem.IDThongTinUser,
    }
    this.DanhmucService.GetListdsCongBo(data).subscribe(res => {
      this.listItem = res;
      for (let i = 0; i < this.listItem.length; i++) {
        for (let j = 0; j < this.listthoigian.length; j++) {
          if (this.listItem[i].LoaiThoiGian == this.listthoigian[j].Ma) {
            this.listItem[i].TenLoaiThoiGian = this.listthoigian[j].Ten;
          }
        }
      }
    });
  }
  GetListdmKhuVuc() {
    let data1: any = {
      Ma: 1,
      Ten: "Khu vực 1",
    }
    let data2: any = {
      Ma: 2,
      Ten: "Khu vực 2",
    }
    let data3: any = {
      Ma: 3,
      Ten: "Khu vực 3",
    }
    this.listkhuvuc.push(data1);
    this.listkhuvuc.push(data2);
    this.listkhuvuc.push(data3);
    this.dataitem.KhuVuc = 1;
  }
  taixuong(tenfile) {
    this.MyService.taixuong(tenfile);
  }
  xuatfile() {
    let data: any =
    {
     "IDThongTinUser": this.dataitem.IDThongTinUser,
     "KhuVuc": this.dataitem.KhuVuc,
     "Nam": this.dataitem.Nam,
    };
    this.SuatVonDauTuService.ExportCongBoFull(data).subscribe(res => {
      if (res.TenFile !== undefined) {
        this.MyService.taixuong(res.TenFile);
      }
      else {
        this.toastr.error(res.message);
      }
    });
  }
}
