import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../../services/danhmuc.service';
import { ToastrService } from 'ngx-toastr';
import { MyService } from './../../../services/my.service';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { HostService } from 'src/app/services/host.service';

@Component({
  selector: 'app-congbo',
  templateUrl: './congbo.component.html',
  styleUrls: ['./congbo.component.css']
})
export class CongboComponent implements OnInit {

  type: any = "";
  item: any = {};
  ItemPOST: any = {};
  header: any = "";
  IDParent: any = {};
  listtinh: any = [];
  listthoigian: any = [];
  listthang: any = [];
  Nam: any = 0;
  dataitem: any = {};
  listkhuvuc: any = [];
  listXuatBan: any = [{ "Ma": 0, "Ten": "Chưa xuất bản" },
  { "Ma": 1, "Ten": "Xuất bản" }]
  listHieuLuc: any = [{ "Ma": 0, "Ten": "Hết hiệu lực" },
  { "Ma": 1, "Ten": "Còn hiệu lực" }, { "Ma": 2, "Ten": "Hiệu lực 1 phần" }];
  listDonViBanHanh: any = [];
  uploaderDuLieu: FileUploader;
  uploaderFileDinhKem: FileUploader;
  obj: any = { FileUpload: [], FileUploadFileDinhKem: [] }
  constructor(
    private activeModal: NgbActiveModal,
    private DanhmucService: DanhmucService,
    private toastr: ToastrService,
    private MyService: MyService,
  ) {

  }

  ngOnInit() {
    if (this.ItemPOST.NgayUnix > 0)
      this.ItemPOST.Ngay = this.MyService.unixToDate(this.ItemPOST.NgayUnix);
    if (this.ItemPOST.HieuLucUnix > 0)
      this.ItemPOST.HieuLuc = this.MyService.unixToDate(this.ItemPOST.HieuLucUnix);
    this.dataitem.NamGoc = JSON.parse(localStorage.getItem('thongtinuser')).Nam;
    this.dataitem.Nam = JSON.parse(localStorage.getItem('thongtinuser')).NamTinh;
    this.dataitem.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
    this.ItemPOST.IDThongTinUser = this.dataitem.IDThongTinUser;
    this.ItemPOST.IDUser = JSON.parse(localStorage.getItem('currentUser')).UserName;

    this.DanhmucService.GetListDonViBanHanh().subscribe(res => {
      this.listDonViBanHanh = res;
    });
    this.DanhmucService.GetListdmTinhCucKinhTe().subscribe(res => {
      this.listtinh = res;
    });
    this.GetListdmKhuVuc();

    this.uploaderDuLieu = new FileUploader({
      url: `${HostService.serverUrl}FileUploader/Post`,
      headers: [{ name: 'Accept', value: 'application/json' }],
      autoUpload: true,
    });

    this.uploaderDuLieu.onBeforeUploadItem = (item) => {
      item.withCredentials = true;
    };
    this.uploaderDuLieu.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploaderDuLieu.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploaderDuLieu.onCompleteItem = (item, response, status, headers) => this.onCompleteItem(item, response, status, headers);

    this.uploaderFileDinhKem = new FileUploader({
      url: `${HostService.serverUrl}FileUploader/Post`,
      headers: [{ name: 'Accept', value: 'application/json' }],
      autoUpload: true,
    });
    this.uploaderFileDinhKem.onBeforeUploadItem = (item) => {
      item.withCredentials = true;
    };
    this.uploaderFileDinhKem.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploaderFileDinhKem.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploaderFileDinhKem.onCompleteItem = (item, response, status, headers) => this.onCompleteItemFileDinhKem(item, response, status, headers);

    if (this.ItemPOST.fileUploadFileDinhKem !== null)
      this.ItemPOST.fileUploadFileDinhKem.forEach(element => {
        let itemAdd = {
          NameLocal: element.TenGoc,
          Name: element.TenGui,
        }
        this.obj.FileUploadFileDinhKem.push(itemAdd);
      });
    if (this.ItemPOST.fileUpload !== null)
      this.ItemPOST.fileUpload.forEach(element => {
        let itemAdd = {
          NameLocal: element.TenGoc,
          Name: element.TenGui,
        }
        this.obj.FileUpload.push(itemAdd);
      });
  }
  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
  }
  onCompleteItem = (item: any, response: any, status: any, headers: any) => {
    let res = JSON.parse(response);
    this.obj.FileUpload[0] = (res[0]);
  };
  onCompleteItemFileDinhKem = (item: any, response: any, status: any, headers: any) => {
    let res = JSON.parse(response);
    this.obj.FileUploadFileDinhKem.push(res[0]);
  };
  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
  }
  GetListdmKhuVuc() {
    let data1: any = {
      Ma: "1",
      Ten: "Khu vực 1",
    }
    let data2: any = {
      Ma: "2",
      Ten: "Khu vực 2",
    }
    let data3: any = {
      Ma: "3",
      Ten: "Khu vực 3",
    }
    let data4: any = {
      Ma: "4",
      Ten: "Toàn quốc",
    }
    this.listkhuvuc.push(data1);
    this.listkhuvuc.push(data2);
    this.listkhuvuc.push(data3);
    this.listkhuvuc.push(data4);
  }
  SetDataTrenCuc() {
    let dmTinh = this.listtinh.find(ele=> ele.IDTinh == this.ItemPOST.IDdmTinh);
    if(dmTinh == undefined || dmTinh == null)
      dmTinh = {};
    let data: any =
    {
      "IDSuatVonDauTu": 0,
      "HieuLucNew": this.ItemPOST.LoaiHieuLuc || 0,
      "MaTinh": dmTinh.Ma,
      "SoQuyetDinh": this.ItemPOST.SoHieu != undefined && this.ItemPOST.SoHieu != null ? this.ItemPOST.SoHieu : "",
      "IdDonViBanHanh": this.ItemPOST.IDCoQuan || "",
      "NguoiKy": this.ItemPOST.NguoiKy || "",
      "Ten": this.ItemPOST.Ten || "",
      "TrichYeu": this.ItemPOST.TrichYeu || "",
      "MoTa": this.ItemPOST.ThuyetMinh || "",
      "NamGoc": this.ItemPOST.Nam,
      "LoaiThoiGian": this.ItemPOST.LoaiThoiGian || 0,
      "IsPublic": false,
      "DungLuongTapTin": 0,
      "TongFile": 0,
      "TotalRow": 0,
      "ConHieuLuc": false,
      "IDUser": this.ItemPOST.IDUser,
      "Files": ""
    }
    data.NgayBanHanh = `${this.ItemPOST.Ngay.day}/${this.ItemPOST.Ngay.month}/${this.ItemPOST.Ngay.year} 12:00:00 AM`;
    data.HieuLuc = `${this.ItemPOST.HieuLuc.day}/${this.ItemPOST.HieuLuc.month}/${this.ItemPOST.HieuLuc.year} 12:00:00 AM`;

    this.obj.FileUploadFileDinhKem.forEach(element => {
      data.Files += element.Name + ", ";
    });
    if (data.Files.length > 2)
      data.Files = data.Files.substring(0, data.Files.length - 2)
    if (this.obj.FileUpload.length > 0) {
      data.TenTapTin = this.obj.FileUpload[0].Name;
    }
    return data;
  }

  XacNhan(isCongBo?) {
    if (this.ItemPOST.PhamVi === undefined || this.ItemPOST.PhamVi === null) {
      this.toastr.error("Bạn chưa chọn phạm vi công bố");
    }
    else {
      let coQuan = this.listDonViBanHanh.filter(e => e.Id === this.ItemPOST.IDCoQuan);
      if (coQuan !== undefined) {
        this.ItemPOST.CoQuan = coQuan[0].Ten;
      }

      this.ItemPOST.NgayUnix = (this.ItemPOST.Ngay != undefined && this.ItemPOST.Ngay != null) ? this.MyService.ghepdateUnix(this.ItemPOST.Ngay) : 0;
      this.ItemPOST.HieuLucUnix = (this.ItemPOST.HieuLuc != undefined && this.ItemPOST.HieuLuc != null) ? this.MyService.ghepdateUnix(this.ItemPOST.HieuLuc) : 0;
      this.ItemPOST.fileUpload = [];
      this.ItemPOST.fileUploadFileDinhKem = [];
      this.obj.FileUpload.forEach(element => {
        let itemAdd = {
          TenGoc: element.NameLocal,
          TenGui: element.Name,
        }
        this.ItemPOST.fileUpload.push(itemAdd);
      });
      this.obj.FileUploadFileDinhKem.forEach(element => {
        let itemAdd = {
          TenGoc: element.NameLocal,
          TenGui: element.Name,
        }
        this.ItemPOST.fileUploadFileDinhKem.push(itemAdd);
      });

      if (isCongBo === true) {
        if (this.ItemPOST.ID > 0) {
          this.DanhmucService.SetCongBo(this.ItemPOST.ID).subscribe(res => {
            if (res.State === 1) {
              this.DanhmucService.ExportCongBoTrenCuc(this.SetDataTrenCuc()).subscribe(res1 => {
                if (res1.Loai === 0) {
                  this.toastr.success("Cập nhật thành công");
                  this.Onclose();
                }
                else {
                  this.toastr.error("Xảy ra lỗi");
                }
              });
            }
            else {
              this.toastr.error("Xảy ra lỗi");
            }
          });
        }
        else {
          this.toastr.error("Bạn cần xác nhận trước!!!");
        }
      }
      else {
        delete this.ItemPOST.Ngay;
        delete this.ItemPOST.HieuLuc;
        this.DanhmucService.ExportCongBo(this.ItemPOST).subscribe(res => {
          if (res.TenFile0 !== undefined && res.TenFile1 !== undefined) {
            this.toastr.success("Cập nhật thành công");
            this.MyService.taixuong(res.TenFile0);
            this.MyService.taixuong(res.TenFile1);
            this.Onclose();
          }
          else if(res == ""){
            this.toastr.success("Cập nhật thành công");
            this.Onclose();
          }
          else {
            this.toastr.error("Xảy ra lỗi");
          }
        });
      }
    }

  }

  Onclose() {
    this.activeModal.close();
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }

}
