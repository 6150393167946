import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../../services/api.service';
import { DanhmucService } from './../../services/danhmuc.service';
import { MyService } from './../../services/my.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { ToastrService } from 'ngx-toastr';
import { CongboComponent } from './congbo/congbo.component';
import { SuatVonDauTuService } from './../../services/suatvondautu.service';

@Component({
  selector: 'app-dscongbo',
  templateUrl: './dscongbo.component.html',
  styleUrls: ['./dscongbo.component.css']
})
export class DscongboComponent implements OnInit {

  listItem: any = [];
  listtinh: any = [];
  listnam: any = [];
  listthoigian: any = [];
  dataitem: any = {};
  listkhuvuc: any = [];
  constructor(
    private modalService: NgbModal,
    private DanhmucService: DanhmucService,
    private MyService: MyService,
    private toastr: ToastrService,
    private SuatVonDauTuService: SuatVonDauTuService,
  ) { }

  ngOnInit() {
    if(JSON.parse(localStorage.getItem('thongtinuser')) != null || JSON.parse(localStorage.getItem('thongtinuser')) != undefined)
    {
      this.dataitem.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
      this.dataitem.TenUser = JSON.parse(localStorage.getItem('thongtinuser')).Ten;
      this.dataitem.Nam = JSON.parse(localStorage.getItem('thongtinuser')).Nam;
    }
    this.GetListdmKhuVuc();
    this.GetListdsCongBo();
  }
  GetListdsCongBo() {
    let data: any = {
      "Nam": this.dataitem.Nam,
      "IDThongTinUser": this.dataitem.IDThongTinUser,
      "KhuVuc": this.dataitem.KhuVuc,
    }
    this.DanhmucService.GetListdsCongBo(data).subscribe(res => {
      res.forEach(element => {
        if(element.PhamVi == 1)
          element.PhamVi = 'Khu vực 1';
        else if(element.PhamVi == 2)
          element.PhamVi = 'Khu vực 2';
        else if(element.PhamVi == 3)
          element.PhamVi = 'Khu vực 3';
        else if(element.PhamVi == 4)
          element.PhamVi = 'Toàn quốc';
      });
      this.listItem = res;
      for (let i = 0; i < this.listItem.length; i++) {
        for (let j = 0; j < this.listthoigian.length; j++) {
          if (this.listItem[i].LoaiThoiGian == this.listthoigian[j].Ma) {
            this.listItem[i].TenLoaiThoiGian = this.listthoigian[j].Ten;
          }
        }
      }
    });
  }

  congbo() {
    let item: any ={
      ID: 0,
      Ten: this.dataitem.TenUser,
      fileUploadFileDinhKem: [],
      fileUpload: [],
      isThayThe:true,
    }
    const modalRef = this.modalService.open(CongboComponent, { backdrop: 'static' , size:'xl'});
    modalRef.componentInstance.ItemPOST = JSON.parse(JSON.stringify(item));
    modalRef.componentInstance.Nam = this.dataitem.Nam;
    modalRef.result.then((data) => {
      this.GetListdsCongBo();
    }, (reason) => {
      // không
    });
  }
  edit(item) {
    this.DanhmucService.GetdsCongBo(item.ID).subscribe(res => {
      const modalRef = this.modalService.open(CongboComponent, { backdrop: 'static', size:'xl' });
      modalRef.componentInstance.ItemPOST = JSON.parse(JSON.stringify(res));
      modalRef.componentInstance.listtinh = this.listtinh;
      modalRef.componentInstance.listthoigian = this.listthoigian;
      modalRef.componentInstance.Nam = this.dataitem.Nam;
      modalRef.result.then((data) => {
        this.GetListdsCongBo();
      }, (reason) => {
        // không
      });
    });
  }
  taixuong(tenfile) {
    console.log(tenfile)
    this.MyService.taixuong(tenfile);
  }
  xoa(item){
    const modalRef = this.modalService.open(ThongbaoComponent, { backdrop: 'static' });
    modalRef.componentInstance.thongbao = "Bạn có chắc chắn muốn xóa?";
    modalRef.result.then((any) => {
      this.DanhmucService.DeleteCongBo(item).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.GetListdsCongBo();
        }
        else {
          this.toastr.error(res.message);      
        }
      });      
    }, (reason) => {
      // không
    });
  }
  GetListdmKhuVuc() {
    let data1: any = {
      Ma: 1,
      Ten: "Khu vực 1",
    }
    let data2: any = {
      Ma: 2,
      Ten: "Khu vực 2",
    }
    let data3: any = {
      Ma: 3,
      Ten: "Khu vực 3",
    }
    let data4: any = {
      Ma: 4,
      Ten: "Toàn quốc",
    }
    this.listkhuvuc.push(data1);
    this.listkhuvuc.push(data2);
    this.listkhuvuc.push(data3);
    this.listkhuvuc.push(data4);
    this.dataitem.KhuVuc = 1;
  }
  xuatfile() {
    let data: any =
    {
     "IDThongTinUser": this.dataitem.IDThongTinUser,
     "KhuVuc": this.dataitem.KhuVuc,
     "Nam": this.dataitem.Nam,
    };
    this.SuatVonDauTuService.ExportCongBoFull(data).subscribe(res => {
      if (res.TenFile !== undefined) {
        this.MyService.taixuong(res.TenFile);
      }
      else {
        this.toastr.error(res.message);
      }
    });
  }
}
