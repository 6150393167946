import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../services/danhmuc.service';
import { SuatVonDauTuService } from './../../services/suatvondautu.service';
import { MyService } from './../../services/my.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { CapnhathesodieuchinhComponent } from './NgbModal/capnhathesodieuchinh/capnhathesodieuchinh.component';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Component({
  selector: 'app-hesodieuchinh',
  templateUrl: './hesodieuchinh.component.html',
  styleUrls: ['./hesodieuchinh.component.css']
})
export class HesodieuchinhComponent implements OnInit {

  listItem: any = [];
  dataitem: any = {};
  listnhomcongtrinh: any = [];
  listloaiheso: any = [];
  objloaddata: any = {};
  listnam: any = []

  constructor(
    private modalService: NgbModal,
    private SuatVonDauTuService: SuatVonDauTuService,
    private MyService: MyService,
    private toastr: ToastrService,
    private DanhmucService: DanhmucService,
  ) { }

  ngOnInit() {
    this.getnam();
    this.GetListdmLoaiCongTrinhCap3();    
    this.refresh();
  }

  getnam() {    
    for (let i = moment().year() - 10; i <= moment().year() + 1; i++) {
      let data: any = { nam: i }
      this.listnam.push(data);
    }
  }

  refresh() {
    this.objloaddata = {
      IDdmNhomCongTrinh: 0,
      Nam: moment().year()
    };
    this.loaddata();
  }

  GetListdmLoaiCongTrinhCap3() {
    let data: any = {
      CurrentPage: 0,
      PageSize: 0,
      sFilter: "",
    };
    this.DanhmucService.GetListdmLoaiCongTrinhCap3(data).subscribe(res => {
      this.listnhomcongtrinh = res;
    });
  }

  loaddata() {  
    this.SuatVonDauTuService.GetListHeSoDieuChinh(this.objloaddata).subscribe(res => {
      this.listItem = res;
    });
  }

  themmoi() {
    const modalRef = this.modalService.open(CapnhathesodieuchinhComponent, { backdrop: 'static' });
    modalRef.componentInstance.type = 'themmoi';
    modalRef.result.then((data) => {
      this.loaddata();
    }, (reason) => {
      // không
    });
  }

  capnhat(item) {
    const modalRef = this.modalService.open(CapnhathesodieuchinhComponent, { backdrop: 'static' });
    modalRef.componentInstance.type = 'capnhat';
    modalRef.componentInstance.item = item;
    modalRef.result.then((data) => {
      this.loaddata();
    }, (reason) => {
      // không
    });
  }

  TinhListSuatVonDauTu() {    
    this.SuatVonDauTuService.TinhListSuatVonDauTu(this.objloaddata).subscribe(res => {
      if (res.State === 1) {
        this.toastr.success(res.message);        
      }
      else {
        this.toastr.error(res.message);
      }
    });
  }
}
