import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../services/danhmuc.service';
import { MyService } from './../../services/my.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { CapnhatchitieuComponent } from './NgbModal/capnhatchitieu/capnhatchitieu.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-danhmucchitieu',
  templateUrl: './danhmucchitieu.component.html',
  styleUrls: ['./danhmucchitieu.component.css']
})
export class DanhmucchitieuComponent implements OnInit {

  objloaddata: any = {};

  dataitem: any = {};

  listcongtrinhCap1: any = [];
  listcongtrinhCap2: any = [];
  listcongtrinhCap3: any = [];
  listcongtrinhCap4: any = [];
  IDdmLoaiCongTrinhCap1: any = {};
  IDdmLoaiCongTrinhCap2: any = {};
  IDdmLoaiCongTrinhCap3: any = {};
  IDdmLoaiCongTrinhCap4: any = {};

  constructor(
    private modalService: NgbModal,
    private DanhmucService: DanhmucService,
    private MyService: MyService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.dataitem.items = [];
    this.dataitem.paging = {};
    this.dataitem.paging.TotalItem = 0;
    this.refresh();
  }

  refresh() {
    this.objloaddata = {
      CurrentPage: 1,
      PageSize: 15,
      sFilter: "",
    };
    this.loaddata();
  }

  loaddata() {
    this.DanhmucService.GetListdmChiTieu(this.objloaddata).subscribe(res => {
      this.dataitem = res;
    });
  }
  
  themmoi() {
    const modalRef = this.modalService.open(CapnhatchitieuComponent, { backdrop: 'static' });
    modalRef.componentInstance.type = 'themmoi';
    modalRef.result.then((data) => {
    }, (reason) => {
      // không
    });
  }

  capnhat(item){
    const modalRef = this.modalService.open(CapnhatchitieuComponent, { backdrop: 'static' });
    modalRef.componentInstance.type = 'capnhat';
    modalRef.componentInstance.item = item;
    modalRef.result.then((data) => {   
    }, (reason) => {
      // không
    });
  }

  xoa(item) {
    const modalRef = this.modalService.open(ThongbaoComponent, { backdrop: 'static' });
    modalRef.componentInstance.thongbao = "Bạn có chắc chắn muốn xóa?";
    modalRef.result.then((any) => {
      this.DanhmucService.DeletedmChiTieu(item).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.loaddata();
        }
        else {
          this.toastr.error(res.message);
        }
      });
    }, (reason) => {
      // không
    });
  }

}
