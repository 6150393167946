import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../services/danhmuc.service';
import { MyService } from './../../services/my.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { ThemmoitreeComponent } from 'src/app/NgbModalAll/themmoitree/themmoitree.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-vatlieu-congnhan-may',
  templateUrl: './vatlieu-congnhan-may.component.html',
  styleUrls: ['./vatlieu-congnhan-may.component.css']
})
export class VatlieuCongnhanMayComponent implements OnInit {

  Listtree: any = [];

  List: any = [];

  itemSELeCT: any = {};
  listtinh: any = [];
  objloaddata: any = {};

  constructor(
    private modalService: NgbModal,
    private DanhmucService: DanhmucService,
    private MyService: MyService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.GetListdmTinhTheoUser();
  }

  GetListdmTinhTheoUser() {
    this.objloaddata.IDdmTinh = undefined;
    this.listtinh = [];
    let data: any = {
      IDdmVung: this.objloaddata.IDdmVung != undefined ? this.objloaddata.IDdmVung : 0,
    };
    this.DanhmucService.GetListdmTinhTheoUser(data).subscribe(res => {
      this.listtinh = res;
      this.refresh();
    });
  }

  refresh() {
    this.objloaddata = {
      IDdmTinh: this.listtinh[0].ID,
    };
    this.loaddata();
  }

  loaddata() {   
    this.DanhmucService.GetListdmVatLieuNhanCongMay(this.objloaddata).subscribe(res => {
      this.List = Object.assign([], res);
      res.filter(obj => {
        obj.collspan = true;
        obj.check = false;
        obj.select = false;
      });
      res[0].select = true;
      this.itemSELeCT = res[0];
      this.Listtree = this.MyService.unflatten(res);
    });
  }

  SelectTREE(item) {
    this.itemSELeCT = item;
    item.select = true;
    for (let i = 0; i < this.Listtree.length; i++) {
      this.Listtree[i].select = this.Listtree[i].ID == item.ID ? item.select : false;
      if (this.Listtree[i].children.length > 0) {
        this.SelectchildrenTREE(this.Listtree[i].children, item);
      }
    }
  }

  SelectchildrenTREE(Listchildren, item) {
    for (let i = 0; i < Listchildren.length; i++) {
      Listchildren[i].select = Listchildren[i].ID == item.ID ? item.select : false;
      if (Listchildren[i].children.length > 0) {
        this.SelectchildrenTREE(Listchildren[i].children, item);
      }
    }
  }

  themmoiTREE() {
    const modalRef = this.modalService.open(ThemmoitreeComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.type = "themmoi";
    modalRef.componentInstance.LoaidanhMuc = "Loaivattu";
    modalRef.componentInstance.IDParent = this.itemSELeCT;
    modalRef.componentInstance.IDdmTinh = this.objloaddata.IDdmTinh;
    modalRef.result.then((data) => {
      this.loaddata();
    }, (reason) => {
      // không
    });
  }

  capnhatTREE() {
    const modalRef = this.modalService.open(ThemmoitreeComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.type = "capnhat";
    modalRef.componentInstance.LoaidanhMuc = "Loaivattu";
    modalRef.componentInstance.IDParent = this.itemSELeCT;
    modalRef.componentInstance.Listtree = this.Listtree;
    modalRef.componentInstance.List = this.List;
    modalRef.componentInstance.IDdmTinh = this.objloaddata.IDdmTinh;
    modalRef.result.then((data) => {
      this.loaddata();
    }, (reason) => {
      // không
    });
  }

  xoaTREE() {
    const modalRef = this.modalService.open(ThongbaoComponent, { backdrop: 'static' });
    modalRef.componentInstance.thongbao = "Bạn có chắc chắn muốn xóa?";
    modalRef.result.then((any) => {
      this.DanhmucService.DeletedmVatLieuNhanCongMay(this.itemSELeCT).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.loaddata();
        }
        else {
          this.toastr.error(res.message);
        }
      });
    }, (reason) => {
      // không
    });
  }


}
