import { Component, OnInit } from '@angular/core';
import { NgbActiveModal , NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../../../services/danhmuc.service';
import { ToastrService } from 'ngx-toastr';
import { MyService } from './../../../../services/my.service';
import { ThemmoitreeComponent } from 'src/app/NgbModalAll/themmoitree/themmoitree.component';

@Component({
  selector: 'app-luachoncongtrinh',
  templateUrl: './luachoncongtrinh.component.html',
  styleUrls: ['./luachoncongtrinh.component.css']
})
export class LuachoncongtrinhComponent implements OnInit {

  Listtree: any = [];
  itemSELeCT: any = {};
  Listpost: any = [];
  TimChaTree: any = [];
  checkListtree: any = [];
  IDdmKhuVuc: any = 0;
  IDdmTinh: any = 0;
  Listcha: any = [];
  Nam: any = 0;
  dataitem: any = {};
  ListpostNew: any = [];
  List: any = [];
  constructor(
    private activeModal: NgbActiveModal,
    private DanhmucService: DanhmucService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private MyService: MyService,
  ) { }

  ngOnInit() {
    this.dataitem.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
    this.List = JSON.parse(JSON.stringify(this.Listtree));

    this.Listpost = this.Listtree;
    this.Listtree.filter(obj => {
      obj.collspan = true;
      obj.select = false;
    });
    this.Listtree[0].select = true;
    this.Listtree = this.MyService.unflatten(this.Listtree);
    this.itemSELeCT = this.Listtree[0];
  }
  CheckTREE(item) {
    item.check = !item.check;
    if(item.children.length > 0){
      this.DeQuyCheckTreeChaCon(item.children, item.check);
    }
    if(item.check){
        this.DeQuyCheckTreeConCha(item, item.check);
    }
  }
  DeQuyCheckTreeChaCon(Listchildren, giatri){
    Listchildren.forEach(element => {
        element.check = giatri;
      this.DeQuyCheckTreeChaCon(element.children, giatri)

    });
  }
  DeQuyCheckTreeConCha(item, giatri){
    this.Listpost.forEach(element => {
      if(element.ID == item.IDParent){
        element.check = giatri;
        this.DeQuyCheckTreeConCha( element, giatri)
      }
    });
  }
  XacNhan() {
    this.DanhmucService.SetListdmLoaiCongTrinh(this.Listpost).subscribe(res => {
      if (res.State === 1) {
        this.toastr.success(res.message);
        this.Onclose();
      }
      else {
        this.toastr.error(res.message);
      }
    });
  }

  Onclose() {
    this.activeModal.close();
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }
  ThemMoi() {
    const modalRef = this.modalService.open(ThemmoitreeComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.type = "themmoi";
    modalRef.componentInstance.Loai = this.itemSELeCT.LoaiDanhMuc;
    modalRef.componentInstance.IDParent = this.itemSELeCT;
    modalRef.componentInstance.IDdmTinh = this.dataitem.IDdmTinh; 
    modalRef.componentInstance.List = this.List;
    modalRef.componentInstance.check = true;
    modalRef.result.then((data) => {
      this.Listpost.push(data.data);
      this.Listtree = this.Listpost;
      this.Listtree[0].select = true;
      this.Listtree = this.MyService.unflatten(this.Listtree);
      this.itemSELeCT = this.Listtree[0];
      }, (reason) => {
      // không
    });
  }
  SelectTREE(item) {
    this.itemSELeCT = item;
    item.select = true;
    for (let i = 0; i < this.Listtree.length; i++) {
      this.Listtree[i].select = this.Listtree[i].ID == item.ID ? item.select : false;
      if (this.Listtree[i].children.length > 0) {
        this.SelectchildrenTREE(this.Listtree[i].children, item);
      }
    }
  }

  SelectchildrenTREE(Listchildren, item) {
    for (let i = 0; i < Listchildren.length; i++) {
      Listchildren[i].select = Listchildren[i].ID == item.ID ? item.select : false;
      if (Listchildren[i].children.length > 0) {
        this.SelectchildrenTREE(Listchildren[i].children, item);
      }
    }
  }
}
