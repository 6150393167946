import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from 'src/app/services/danhmuc.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { MyService } from './../../../../services/my.service';

@Component({
  selector: 'app-themmoiduancongtrinhdienhinh',
  templateUrl: './themmoiduancongtrinhdienhinh.component.html',
  styleUrls: ['./themmoiduancongtrinhdienhinh.component.css']
})
export class ThemMoiDuAnDanhMucDungChungComponent implements OnInit {
  public IDParent: any;
  public Nam: any;
  public itemADD: any = {};
  public ThongTinChung: any = {};
  public ChiPhiChung: any = {};
  public ChiPhiVatLieu: any = [];
  public ChiPhiNhanCong: any = [];
  public ChiPhiMay: any = [];

  header: any = '';
  type: any = '';
  item: any = {};
  ItemPOST: any = {};
  listcapcongtrinh: any = [];
  listnhomcongtrinh: any = [];
  objloaicongtrinh: any = {};
  IDdsLoaiCongTrinh: any = 0;
  IDdmTinh: any = 0;
  IDdmKhuVuc: any = 0;
  dataitem: any = {};
  listDanhMucDungChung: any = [];
  ListtreeVL: any = [];
  ListtreeNC: any = [];
  ListtreeMTC: any = [];
  ListtreeMS: any = [];
  isView: any = false;
  listNam: any = [];
  listThang: any = [];
  listdmgiaidoanduan: any = [];
  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private DanhmucService: DanhmucService,
    private toastr: ToastrService,
    private MyService: MyService,
  ) { }
  ngOnInit() {
    let data = JSON.parse(localStorage.getItem('thongtinuser'));
    if(data != undefined && data != {}){
      this.dataitem.IDThongTinUser = data.ID; 
    }
    this.dataitem.IDUser = JSON.parse(localStorage.getItem('currentUser')).Id;  
    if(this.isView == false){
      this.header = "Thêm mới";
      this.ItemPOST = {
        "ThongTinChung": {
        },
        "ChiPhiChung": {
        },
        "ThongTinKinhTe": {
        },
        "listChiPhiVatLieu": [],
        "listChiPhiNhanCong": [],
        "listChiPhiMay": [],
        "listChiPhiCongViec": [],
      };
    }
    else{
      this.ItemPOST = this.item;
    }
      this.GetListdmCapCongTrinh();
      this.GetListdmNhomCongTrinh();
      this.GetListNam();
      this.GetListThang();
      this.GetListdmGiaiDoanDuAn();
  }
  GetListdmGiaiDoanDuAn() {
    let data: any = {
      CurrentPage: 0,
    };
    this.DanhmucService.GetListdmGiaiDoanDuAn(data).subscribe(res => {
      this.listdmgiaidoanduan = res
    });
  }
  SetData() {
    let data: any =
    {
      "ID": this.type == 'themmoi' ? 0 : this.ItemPOST.ID,
      "Ten": this.ItemPOST.ThongTinChung.Ten != undefined && this.ItemPOST.ThongTinChung.Ten != null ? this.ItemPOST.ThongTinChung.Ten : "",
      "IDParent": this.IDParent,
      "ThongTinChung": {
        "ID": this.type == 'themmoi' ? 0 : this.ItemPOST.ThongTinChung.ID,
        "IDdsLoaiCongTrinh": this.IDdsLoaiCongTrinh,
        "Ten": this.ItemPOST.ThongTinChung.Ten != undefined && this.ItemPOST.ThongTinChung.Ten != null ? this.ItemPOST.ThongTinChung.Ten : "",
        "IDdmLoaiCongTrinh": this.objloaicongtrinh.IDdmLoaiCongTrinh,
        "IDdmCapCongTrinh": this.ItemPOST.ThongTinChung.IDdmCapCongTrinh != undefined ? this.ItemPOST.ThongTinChung.IDdmCapCongTrinh : 0,
        "IDdmNhomCongTrinh": this.ItemPOST.ThongTinChung.IDdmNhomCongTrinh != undefined ? this.ItemPOST.ThongTinChung.IDdmNhomCongTrinh : 0,
        "DiaDiem": this.ItemPOST.ThongTinChung.DiaDiem != undefined && this.ItemPOST.ThongTinChung.DiaDiem != null ? this.ItemPOST.ThongTinChung.DiaDiem : "",
        "CongSuat": this.ItemPOST.ThongTinChung.CongSuat != undefined && this.ItemPOST.ThongTinChung.CongSuat != null ? this.ItemPOST.ThongTinChung.CongSuat : "",
        "NangLuc": this.ItemPOST.ThongTinChung.NangLuc != undefined && this.ItemPOST.ThongTinChung.NangLuc != null ? this.ItemPOST.ThongTinChung.NangLuc : "",
        "QuyChuan": this.ItemPOST.ThongTinChung.QuyChuan != undefined && this.ItemPOST.ThongTinChung.QuyChuan != null ? this.ItemPOST.ThongTinChung.QuyChuan : "",
        "TieuChuan": this.ItemPOST.ThongTinChung.TieuChuan != undefined && this.ItemPOST.ThongTinChung.TieuChuan != null ? this.ItemPOST.ThongTinChung.TieuChuan : "",
        "ThoiGianSuDung": this.ItemPOST.ThongTinChung.ThoiGianSuDung != undefined && this.ItemPOST.ThongTinChung.ThoiGianSuDung != null ? this.ItemPOST.ThongTinChung.ThoiGianSuDung : "",
        "CoChe": this.ItemPOST.ThongTinChung.CoChe != undefined && this.ItemPOST.ThongTinChung.CoChe != null ? this.ItemPOST.ThongTinChung.CoChe : "",
        "DienTich": this.ItemPOST.ThongTinChung.DienTich != undefined && this.ItemPOST.ThongTinChung.DienTich != null ? this.ItemPOST.ThongTinChung.DienTich : "",
        "ThongTinKhac": this.ItemPOST.ThongTinChung.ThongTinKhac != undefined && this.ItemPOST.ThongTinChung.ThongTinKhac != null ? this.ItemPOST.ThongTinChung.ThongTinKhac : "",
        "IDThongTinUser": this.dataitem.IDThongTinUser,
        "CongNgheThiCong": this.ItemPOST.ThongTinChung.CongNgheThiCong != undefined && this.ItemPOST.ThongTinChung.CongNgheThiCong != null ? this.ItemPOST.ThongTinChung.CongNgheThiCong : "",
        "GiaiPhapKetCau": this.ItemPOST.ThongTinChung.GiaiPhapKetCau != undefined && this.ItemPOST.ThongTinChung.GiaiPhapKetCau != null ? this.ItemPOST.ThongTinChung.GiaiPhapKetCau : "",
        "IDdmGiaiDoanDuAn":this.ItemPOST.ThongTinChung.IDdmGiaiDoanDuAn != undefined && this.ItemPOST.ThongTinChung.IDdmGiaiDoanDuAn != null ? this.ItemPOST.ThongTinChung.IDdmGiaiDoanDuAn : "",
        "NamXayDung":this.ItemPOST.ThongTinChung.NamXayDung,
        "NamSuDung":this.ItemPOST.ThongTinChung.NamSuDung,
        "ThangSuDung":this.ItemPOST.ThongTinChung.ThangSuDung,
        "ChuDauTu":this.ItemPOST.ThongTinChung.ChuDauTu,
        "ThoiGianLapDuLieuUnix": this.MyService.ghepdateUnix(this.ItemPOST.ThongTinChung.ThoiGianLapDuLieu),

      },
      "ChiPhiChung": {
        "ID": this.type == 'themmoi' ? 0 : this.ItemPOST.ChiPhiChung.ID,
        "IDdsLoaiCongTrinh": this.IDdsLoaiCongTrinh,
        "IDdmKhuVuc": this.IDdmKhuVuc,
        "ChiPhiXayDung": this.ItemPOST.ChiPhiChung.ChiPhiXayDung != undefined && this.ItemPOST.ChiPhiChung.ChiPhiXayDung != null ? this.ItemPOST.ChiPhiChung.ChiPhiXayDung : 0,
        "ChiPhiVatLieu": this.ItemPOST.ChiPhiChung.ChiPhiVatLieu != undefined && this.ItemPOST.ChiPhiChung.ChiPhiVatLieu != null ? this.ItemPOST.ChiPhiChung.ChiPhiVatLieu : 0,
        "ChiPhiMay": this.ItemPOST.ChiPhiChung.ChiPhiMay != undefined && this.ItemPOST.ChiPhiChung.ChiPhiMay != null ? this.ItemPOST.ChiPhiChung.ChiPhiMay : 0,
        "ChiPhiNhanCong": this.ItemPOST.ChiPhiChung.ChiPhiNhanCong != undefined && this.ItemPOST.ChiPhiChung.ChiPhiNhanCong != null ? this.ItemPOST.ChiPhiChung.ChiPhiNhanCong : 0,
        "ChiPhiThietBi": this.ItemPOST.ChiPhiChung.ChiPhiThietBi != undefined && this.ItemPOST.ChiPhiChung.ChiPhiThietBi != null ? this.ItemPOST.ChiPhiChung.ChiPhiThietBi : 0,
        "ChiPhiMuaSam": this.ItemPOST.ChiPhiChung.ChiPhiMuaSam != undefined && this.ItemPOST.ChiPhiChung.ChiPhiMuaSam != null ? this.ItemPOST.ChiPhiChung.ChiPhiMuaSam : 0,
        "ChiPhiLapDat": this.ItemPOST.ChiPhiChung.ChiPhiLapDat != undefined && this.ItemPOST.ChiPhiChung.ChiPhiLapDat != null ? this.ItemPOST.ChiPhiChung.ChiPhiLapDat : 0,
        "ChiPhiKhac": this.ItemPOST.ChiPhiChung.ChiPhiKhac != undefined && this.ItemPOST.ChiPhiChung.ChiPhiKhac != null ? this.ItemPOST.ChiPhiChung.ChiPhiKhac : 0,
        "ChiPhiQuanLiDuAn": this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn != undefined && this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn != null ? this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn : 0,
        "ChiPhiQuanLiKhaoSat": this.ItemPOST.ChiPhiChung.ChiPhiQuanLiKhaoSat != undefined && this.ItemPOST.ChiPhiChung.ChiPhiQuanLiKhaoSat != null ? this.ItemPOST.ChiPhiChung.ChiPhiQuanLiKhaoSat : 0,
        "ChiPhiQuanLiThietKe": this.ItemPOST.ChiPhiChung.ChiPhiQuanLiThietKe != undefined && this.ItemPOST.ChiPhiChung.ChiPhiQuanLiThietKe != null ? this.ItemPOST.ChiPhiChung.ChiPhiQuanLiThietKe : 0,
        "ChiPhiTuVanDauTu": this.ItemPOST.ChiPhiChung.ChiPhiTuVanDauTu != undefined && this.ItemPOST.ChiPhiChung.ChiPhiTuVanDauTu != null ? this.ItemPOST.ChiPhiChung.ChiPhiTuVanDauTu : 0,
        "ChiPhiDuPhong": this.ItemPOST.ChiPhiChung.ChiPhiDuPhong != undefined && this.ItemPOST.ChiPhiChung.ChiPhiDuPhong != null ? this.ItemPOST.ChiPhiChung.ChiPhiDuPhong : 0,
        "ChiPhiGiamSatXD": this.ItemPOST.ChiPhiChung.ChiPhiGiamSatXD != undefined && this.ItemPOST.ChiPhiChung.ChiPhiGiamSatXD != null ? this.ItemPOST.ChiPhiChung.ChiPhiGiamSatXD : 0,
        "Khac": this.ItemPOST.ChiPhiChung.Khac != undefined && this.ItemPOST.ChiPhiChung.Khac != null ? this.ItemPOST.ChiPhiChung.Khac : 0,
        "ChiPhiGiamSatTB": this.ItemPOST.ChiPhiChung.ChiPhiGiamSatTB != undefined && this.ItemPOST.ChiPhiChung.ChiPhiGiamSatTB != null ? this.ItemPOST.ChiPhiChung.ChiPhiGiamSatTB : 0,
        "VonDauTu": this.ItemPOST.ChiPhiChung.VonDauTu != undefined && this.ItemPOST.ChiPhiChung.VonDauTu != null ? this.ItemPOST.ChiPhiChung.VonDauTu : 0,
        "DuToan": this.ItemPOST.ChiPhiChung.DuToan != undefined && this.ItemPOST.ChiPhiChung.DuToan != null ? this.ItemPOST.ChiPhiChung.DuToan : 0,
        "Nam": this.Nam,
        "IDThongTinUser": this.dataitem.IDThongTinUser,
        "DonViCongSuat": this.ItemPOST.ChiPhiChung.DonViCongSuat != undefined && this.ItemPOST.ChiPhiChung.DonViCongSuat != null ? this.ItemPOST.ChiPhiChung.DonViCongSuat : 0,
        "HeSoDieuChinhThoiGian": this.ItemPOST.ChiPhiChung.HeSoDieuChinhThoiGian != undefined && this.ItemPOST.ChiPhiChung.HeSoDieuChinhThoiGian != null ? this.ItemPOST.ChiPhiChung.HeSoDieuChinhThoiGian : 0,
        "HeSoDieuChinhKhuVuc": this.ItemPOST.ChiPhiChung.HeSoDieuChinhKhuVuc != undefined && this.ItemPOST.ChiPhiChung.HeSoDieuChinhKhuVuc != null ? this.ItemPOST.ChiPhiChung.HeSoDieuChinhKhuVuc : 0,
      },
      "ThongTinKinhTe": {
        "ID": this.type == 'themmoi' ? 0 : this.ItemPOST.ThongTinKinhTe.ID,
        "IDdmChiTieu": this.ItemPOST.ID,
        "IDCongTrinh": this.type == 'themmoi' ? 0 : this.ItemPOST.ThongTinChung.ID,
        "NguonVon": this.ItemPOST.ThongTinKinhTe.NguonVon != undefined && this.ItemPOST.ThongTinKinhTe.NguonVon != null ? this.ItemPOST.ThongTinKinhTe.NguonVon : "",
        "ChiTieuTaiChinhKinhTe": this.ItemPOST.ThongTinKinhTe.ChiTieuTaiChinhKinhTe != undefined && this.ItemPOST.ThongTinKinhTe.ChiTieuTaiChinhKinhTe != null ? this.ItemPOST.ThongTinKinhTe.ChiTieuTaiChinhKinhTe : "",
        "HinhThucDauTu": this.ItemPOST.ThongTinKinhTe.HinhThucDauTu != undefined && this.ItemPOST.ThongTinKinhTe.HinhThucDauTu != null ? this.ItemPOST.ThongTinKinhTe.HinhThucDauTu : "",
        "TyGiaNgoaiTe": this.ItemPOST.ThongTinKinhTe.TyGiaNgoaiTe != undefined && this.ItemPOST.ThongTinKinhTe.TyGiaNgoaiTe != null ? this.ItemPOST.ThongTinKinhTe.TyGiaNgoaiTe : 0,
        "Nam": this.Nam,
        "ThongTinKhac": this.ItemPOST.ThongTinKinhTe.ThongTinKhac!= undefined && this.ItemPOST.ThongTinKinhTe.ThongTinKhac != null ? this.ItemPOST.ThongTinKinhTe.ThongTinKhac : "",
      },
      "Loai": 1,
      "IDdmTinh": this.IDdmTinh,
      "IDdmKhuVuc": this.IDdmKhuVuc,
      "listChiPhiVatLieu": this.ItemPOST.listChiPhiVatLieu,
      "listChiPhiNhanCong": this.ItemPOST.listChiPhiNhanCong,
      "listChiPhiMay": this.ItemPOST.listChiPhiMay,
      "listChiPhiMuaSam": this.ItemPOST.listChiPhiMuaSam,
      "listChiPhiCongViec": this.ItemPOST.listChiPhiCongViec,
      "IDThongTinUser": this.dataitem.IDThongTinUser,
      "HeSoVatLieu": this.ItemPOST.HeSoVatLieu,
      "HeSoNhanCong": this.ItemPOST.HeSoNhanCong,
      "HeSoMayThiCong": this.ItemPOST.HeSoMayThiCong,
    };
    data.ChiPhiChung.ChiPhiVatLieu = 0;
    data.ChiPhiChung.ChiPhiNhanCong = 0;
    data.ChiPhiChung.ChiPhiMay = 0;
    data.ChiPhiChung.ChiPhiMuaSamThietBi = 0;
    return data;
  }
  XacNhan() {
    if(this.ItemPOST.ThongTinChung.Ten == undefined || this.ItemPOST.ThongTinChung.Ten == null || this.ItemPOST.ThongTinChung.Ten == ""){
      this.toastr.error("Bạn chưa nhập tên công trình");
    }
    else{
      this.DanhmucService.SetdmLoaiCongTrinh(this.SetData()).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.Onclose();
        }
        else {
          this.toastr.error(res.message);
        }
      });
    }
  }
  
  Onclose() {
    this.activeModal.close();
  }
  Ondismiss() {
    this.activeModal.dismiss();
  }
  TinhGiaTri(item) {
    item.GiaTri = item.DonGia * item.KhoiLuong;
  } 
    saveDanhMucdungChung(){
      if(this.ItemPOST.ThongTinChung.Ten == undefined || this.ItemPOST.ThongTinChung.Ten == null || this.ItemPOST.ThongTinChung.Ten == ""){
        this.toastr.error("Bạn chưa nhập tên công trình");
      }
      else{
        let data:any={
          "Ten":this.ItemPOST.ThongTinChung.Ten,
          "LoaiDanhMuc": 'CongTrinhDienHinh',
          "IDUser": this.dataitem.IDUser,
          "dmCongTrinhDienHinh": this.SetData()
        }
        
        this.DanhmucService.KiemTraTrungdmDungChung(this.ItemPOST.ThongTinChung.Ten, this.dataitem.IDUser).subscribe(resGhiDe => {
          if (resGhiDe.State === 0) {
            const modalRef = this.modalService.open(ThongbaoComponent, { backdrop: 'static' });
            modalRef.componentInstance.thongbao = "Bạn có chắc chắn muốn ghi đè?";
            modalRef.result.then((any) => {
              data.ID = resGhiDe.objectReturn.ID;
              this.DanhmucService.SaveCongTrinhDienHinh(data).subscribe(res => {
                if (res.State === 1) {
                  this.toastr.success(res.message);
                  this.activeModal.dismiss();
                  // this.Onclose();
                  // location.reload();
                }
                else {
                  this.toastr.error(res.message);      
                }
              });      
            }, (reason) => {
            });
          }
          else {
            this.DanhmucService.SaveCongTrinhDienHinh(data).subscribe(res => {
              if (res.State === 1) {
                this.toastr.success(res.message);
                this.activeModal.dismiss();
              }
              else {
                this.toastr.error(res.message);      
              }
            }); 
          }
        });
      }
    }
  GetListdmCapCongTrinh() {
    let data: any = {
      CurrentPage: 0,
      PageSize: 0,
      sFilter: "",
    };
    this.DanhmucService.GetListdmCapCongTrinh(data).subscribe(res => {
      this.listcapcongtrinh = res;
    });
  }
  GetListdmNhomCongTrinh() {
    let data: any = {
      CurrentPage: 0,
      PageSize: 0,
      sFilter: "",
    };
    this.DanhmucService.GetListdmNhomCongTrinh(data).subscribe(res => {
      this.listnhomcongtrinh = res;
    });
  }
  GetdsVatLieu(index) {
    if(index == -1){
      let data: any = {
        TendmVatLieuNhanCongMay: "",
        DonGia: 0,
        KhoiLuong: 0,
        LoaiDanhMuc: 0,
        ThueGiaTriGiaTang: 0,
        GiaTriSauThue: 0,
      }
      this.ItemPOST.listChiPhiVatLieu.unshift(data);
    }
    else{
      this.ItemPOST.listChiPhiVatLieu.splice(index, 1);
    }
  }
  GetdsNhanCong(index) {
    if(index == -1){
      let data: any = {
        TendmVatLieuNhanCongMay: "",
        DonGia: 0,
        KhoiLuong: 0,
        LoaiDanhMuc: 1,
        ThueGiaTriGiaTang: 0,
        GiaTriSauThue: 0,
      }
      this.ItemPOST.listChiPhiNhanCong.unshift(data);
    }
    else{
      this.ItemPOST.listChiPhiNhanCong.splice(index, 1);
    }
  }
  GetdsMayThiCong(index) {
    if(index == -1){
      let data: any = {
        TendmVatLieuNhanCongMay: "",
        DonGia: 0,
        KhoiLuong: 0,
        LoaiDanhMuc: 2,
        ThueGiaTriGiaTang: 0,
        GiaTriSauThue: 0,
      }
      this.ItemPOST.listChiPhiMay.unshift(data);
    }
    else{
      this.ItemPOST.listChiPhiMay.splice(index, 1);
    }
  }
  GetdsCongViec(index){
    if(index == -1){
      let data: any = {
        TendmVatLieuNhanCongMay: "",
        DonGia: 0,
        KhoiLuong: 0,
        LoaiDanhMuc: 100,
        ThueGiaTriGiaTang: 0,
        GiaTriSauThue: 0,
      }
      this.ItemPOST.listChiPhiCongViec.unshift(data);
    }
    else{
      this.ItemPOST.listChiPhiCongViec.splice(index, 1);
    }
  }
  GetListNam() {
    var listNam = [];
    this.listNam = [];
    for(let i = 1970; i <= 2050; i++){
      let data: any={
        "ma": i,
        "ten":i
      }
      listNam.push(data)
    }
    this.listNam = listNam;
  }
  GetListThang() {
    var listThang = [];
    this.listThang = [];
    for(let i = 1; i <= 12; i++){
      let data: any={
        "ma": i,
        "ten":i
      }
      listThang.push(data)
    }
    this.listThang = listThang;
  }
}