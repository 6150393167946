import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../services/danhmuc.service';
import { MyService } from './../../services/my.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { UpdateuserComponent } from './NgbModal/updateuser/updateuser.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-danhmucuser',
  templateUrl: './danhmucuser.component.html',
  styleUrls: ['./danhmucuser.component.css']
})
export class DanhmucuserComponent implements OnInit {

  Listtree: any = [];

  List: any = [];

  itemSELeCT: any = {};
  listUserTinh: any = [];

  constructor(
    private modalService: NgbModal,
    private DanhmucService: DanhmucService,
    private MyService: MyService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.GetListUser();
    this.listUserTinh = JSON.parse(localStorage.getItem('currentUser')).listUserTinh;

  }

  GetListUser() {
    this.DanhmucService.GetListUser().subscribe(res => {
      this.List = res;    
      // for (let i = 0; i < res.length; i++) {
      //   if (res[i].listUserTinh.length > 0 || this.listUserTinh.length > 0) {
      //     for (let j = 0; j < res[i].listUserTinh.length; j++) {
      //       for (let k = 0; k < this.listUserTinh.length; k++) {
      //         if (res[i].listUserTinh[j] == this.listUserTinh[k]) {
      //           this.List.push(res[i]);
      //         }
      //       }
      //     }
      //   }
      //   else {
      //     this.List = res;
      //   }
      // }
      // res.forEach(obj => {
      //   if (obj.listUserTinh == this.listUserTinh) {
      //     this.List.push(obj);
      //   }
      //   else if (this.listUserTinh.length == 0) {
      //     this.List = res;
      //   }
      // });
      this.List[0].select = true;
      this.itemSELeCT = this.List[0];
    });
  }

  themmoi() {
    const modalRef = this.modalService.open(UpdateuserComponent, {  backdrop: 'static' });
    modalRef.componentInstance.type = "themmoi";
    // modalRef.componentInstance.LoaidanhMuc = "Danhmucuser";
    // modalRef.componentInstance.IDParent = this.itemSELeCT;
    modalRef.result.then((data) => {
      this.GetListUser();
    }, (reason) => {
      // không
    });
  }

  capnhat(item) {
    const modalRef = this.modalService.open(UpdateuserComponent, { backdrop: 'static' });
    modalRef.componentInstance.type = "capnhat";
    modalRef.componentInstance.item = item;
    modalRef.result.then((data) => {
      this.GetListUser();
    }, (reason) => {
      // không
    });
  }

  xoa(item) {
    const modalRef = this.modalService.open(ThongbaoComponent, { backdrop: 'static' });
    modalRef.componentInstance.thongbao = "Bạn có chắc chắn muốn xóa?";
    modalRef.result.then((any) => {
      this.DanhmucService.DeleteUser(item).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.GetListUser();
        }
        else {
          this.toastr.error(res.message);
        }
      });
    }, (reason) => {
      // không
    });
  }

  chonuser(item) {
    this.itemSELeCT = item;
    this.List.filter(obj => {
      if (obj.Id == item.Id) {
        item.select = true;
        // this.GetListdmKhuVuc(item.ID);
      }
      else {
        obj.select = false;
      }
    });
  }


}
