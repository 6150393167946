import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../../../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { MyService } from './../../../../services/my.service';
import * as moment from 'moment';

@Component({
  selector: 'app-capnhatnhancong',
  templateUrl: './capnhatnhancong.component.html',
  styleUrls: ['./capnhatnhancong.component.css']
})
export class CapnhatnhancongComponent implements OnInit {

  Listtree: any = [];
  itemSELeCT: any = {};
  Listpost: any = [];
  checkListtree: any = [];
  IDdmChiTieu: any = 0;
  IDCongTrinh: any = 0;
  Nam: any = 0;
  type: any = '';
  listvatlieu: any = [];
  listnhancong: any = [];
  listmaythicong: any = [];
  listchiphimuasam: any = [];
  IDdsLoaiCongTrinh: any = 0;

  constructor(
    private activeModal: NgbActiveModal,
    private ApiService: ApiService,
    private toastr: ToastrService,
    private MyService: MyService,
  ) { }

  ngOnInit() {
    this.Listtree.filter(obj => {
      obj.collspan = true;
      obj.check = false;
      obj.select = false;
    });

    if (this.checkListtree.length > 0) {
      for (let i = 0; i < this.Listtree.length; i++) {
        for (let j = 0; j < this.checkListtree.length; j++) {
          if (this.Listtree[i].ID == this.checkListtree[j].IDdmVatLieuNhanCongMay) {
            this.Listtree[i].check = true;
            this.Listtree[i].IDdatontai = this.checkListtree[j].IDdmVatLieuNhanCongMay;
          }
          else {
            this.Listtree[i].IDdatontai = 0;
          }
        }
      }
    }
    else {
      this.Listtree.filter(obj => {
        obj.check = false;
        obj.IDdatontai = 0;
      });
    }

    if (this.Listtree.length > 0) {
      // this.ListTINH = res;
      this.Listtree[0].select = true;
      // this.itemSELeCT = this.ListTINH[0];
      this.Listtree = this.MyService.unflatten(this.Listtree);
    }
  }

  SelectTREE(item) {
    this.itemSELeCT = item;
    item.select = true;
    for (let i = 0; i < this.Listtree.length; i++) {
      this.Listtree[i].select = this.Listtree[i].ID == item.ID ? item.select : false;
      if (this.Listtree[i].children.length > 0) {
        this.SelectchildrenTREE(this.Listtree[i].children, item);
      }
    }
  }

  SelectchildrenTREE(Listchildren, item) {
    for (let i = 0; i < Listchildren.length; i++) {
      Listchildren[i].select = Listchildren[i].ID == item.ID ? item.select : false;
      if (Listchildren[i].children.length > 0) {
        this.SelectchildrenTREE(Listchildren[i].children, item);
      }
    }
  }

  CheckTREE(item) {
    // 0 vật liệu
    // 1 Nhân công
    // 2 máy
    item.check = !item.check;
    if (item.check) {
      let data: any = {
        "ID": item.IDdatontai,
        "IDdmChiTieu": this.IDdmChiTieu,
        "IDCongTrinh": this.IDCongTrinh,
        "Ten": item.Ten,
        "IDdmVatLieuNhanCongMay": item.ID,
        "DonViTinh": 0,
        "GiaTri": 0,
        "DonGia": 0,
        "KhoiLuong": 0,
        "Loai": 0,
        "Nam": this.Nam
      }
      switch (this.type) {
        case "VatLieu":
          data.Loai = 0;
          this.listvatlieu.push(data);
          break;
        case "NhanCong":
          data.Loai = 1;
          this.listnhancong.push(data);
          break;
        case "ThiCong":
          data.Loai = 2;
          this.listmaythicong.push(data);
          break;
        case "MuaSam":
          data.Loai = 3;
          this.listchiphimuasam.push(data);
          break;
      }
    }
    else {
      switch (this.type) {
        case "VatLieu":
          this.Listpost = this.listvatlieu;
          this.spliceList(item, this.Listpost);
          break;
        case "NhanCong":
          this.Listpost = this.listnhancong;
          this.spliceList(item, this.Listpost);
          break;
        case "ThiCong":
          this.Listpost = this.listmaythicong;
          this.spliceList(item, this.Listpost);
          break;
        case "MuaSam":
          this.Listpost = this.listchiphimuasam;
          this.spliceList(item, this.Listpost);
          break;
      };
    }
  }

  spliceList(item, Listpost) {
    for (let i = 0; i < Listpost.length; i++) {
      if (item.ID == Listpost[i].IDdsVatLieuNhanCongMay) {
        switch (this.type) {
          case "VatLieu":
            item.IDdatontai = 0;
            this.Listpost.splice(i, 1);
            break;
          case "NhanCong":
            item.IDdatontai = 0;
            this.Listpost.splice(i, 1);
            break;
          case "ThiCong":
            item.IDdatontai = 0;
            this.Listpost.splice(i, 1);
            break;
          case "MuaSam":
            item.IDdatontai = 0;
            this.Listpost.splice(i, 1);
            break;
        };
        break;
      }
    }
  }

  // XacNhan() {

  //   this.ApiService.SetChiPhiVatLieuNhanCongMay(this.Listpost).subscribe(res => {
  //     if (res.State === 1) {
  //       this.toastr.success(res.message);
  //       this.Onclose();
  //     }
  //     else {
  //       this.toastr.error(res.message);
  //     }
  //   });
  // }

  Onclose() {
    this.activeModal.close(
      {
        vatlieu: this.listvatlieu,
        nhancong: this.listnhancong,
        maythicong: this.listmaythicong,
        chiphimuasam: this.listchiphimuasam,
      }
    );
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }

}
