import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from 'src/app/services/danhmuc.service';
import { MyService } from 'src/app/services/my.service';

@Component({
  selector: 'app-luachondanhmucloaicongtrinhdungchung',
  templateUrl: './luachondanhmucloaicongtrinhdungchung.component.html',
  styleUrls: ['./luachondanhmucloaicongtrinhdungchung.component.css']
})
export class LuachondanhmucloaicongtrinhdungchungComponent implements OnInit {
  listDanhMucDungChung: any  = [];
  loaiDanhMuc : string = "";
  filter: any = {};
  listdmLoaiCongTrinh : any = [];
  Listtree: any = [];
  constructor(
    private activeModal: NgbActiveModal,
    private DanhmucService: DanhmucService,
    private MyService: MyService,
  ) { }

  ngOnInit() {
    this.GetListdmDungChungLCT();
  }
  GetListdmDungChungLCT() {
    this.DanhmucService.getListdmDungChung( this.loaiDanhMuc).subscribe(res => {
      this.listDanhMucDungChung = res;
      this.filter.IDdmDungChung = this.listDanhMucDungChung[0].ID;
      this.dsLoaiCongTrinh();
    });
  }
  Onclose() {
    this.activeModal.close();
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }
  dsLoaiCongTrinh(){
    this.DanhmucService.getListdmLoaiCongTrinhDungChung(this.filter.IDdmDungChung).subscribe(res => {
      this.listdmLoaiCongTrinh = res
      this.listdmLoaiCongTrinh.filter(obj => {
        obj.collspan = true;
        obj.check = false;
        obj.select = false;
      });
      this.Listtree = this.MyService.unflatten(this.listdmLoaiCongTrinh);
  })
}
  XacNhan(){
    this.Listtree.forEach(element => {
      element.children.forEach(elementC => {
        elementC.children = [];
      });
    });
    let data: any ={
      Listtree: this.Listtree,
      IDdmDungChung: this.filter.IDdmDungChung,
    }
    this.activeModal.close(
      {data:data}
    );
  }
}
