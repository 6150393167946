import { Component, OnInit, HostListener } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SuatVonDauTuService } from './../../../../services/suatvondautu.service';
import { ToastrService } from 'ngx-toastr';
import { FileUploader, FileItem, ParsedResponseHeaders } from "ng2-file-upload";
import { HostService } from './../../../../services/host.service';
import { DanhmucService } from './../../../../services/danhmuc.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HienThiVLNCMComponent } from 'src/app/danhmucdungchung/NgbModal/hienthi-vlncm/hienthi-vlncm.component';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { MyService } from './../../../../services/my.service';
import { HienthicongtrinhdaidienmodalComponent } from 'src/app/danhmucdungchung/NgbModal/hienthicongtrinhdaidienmodal/hienthicongtrinhdaidienmodal.component';

declare var $: any;


@Component({
  selector: 'app-capnhatcongtrinhdienhinh1',
  templateUrl: './capnhatcongtrinhdienhinh1.component.html',
  styleUrls: ['./capnhatcongtrinhdienhinh1.component.css']
})
export class Capnhatcongtrinhdienhinh1Component implements OnInit {
  type: any = '';
  item: any = {};
  ItemPOST: any = {};
  dsCongTrinh: any = {};
  header: any = "";
  IDParent: any = {};
  obj: any = {};
  uploader: FileUploader;
  listcapcongtrinh: any = [];
  listnhomcongtrinh: any = [];
  objchitieu: any = {};
  Nam: any = 0;
  IDdmTinh: any = 0;
  dataitem: any = {};
  objloaicongtrinh: any = {};
  listDanhMucDungChung: any = [];
  listdmgiaidoanduan: any = [];
  listNam: any = [];
  listThang: any = [];
  KhuVuc: any = 0;
  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private SuatVonDauTuService: SuatVonDauTuService,
    private toastr: ToastrService,
    private DanhmucService: DanhmucService,
    private MyService: MyService,
  ) { }

  ngOnInit() {
    // this.reAdjust();
    this.dataitem.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
    this.dataitem.IDUser = Object.assign({}, JSON.parse(localStorage.getItem('currentUser'))).UserName;
    this.obj.FileUpload = [];
    this.obj.FileUploaded = [];
    this.uploader = new FileUploader({
      url: `${HostService.serverUrl}FileUploader/Post`,
      headers: [{ name: 'Accept', value: 'application/json' }],
      autoUpload: true,
    });
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = true;
    };
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteItem = (item, response, status, headers) => this.onCompleteItem(item, response, status, headers);
    if (this.type == 'themmoi') {
      this.header = "Thêm mới";
      this.ItemPOST = {
        "ChiPhiChung": {
        },
        "ThongTinChung": {
        },
        "ThongTinKinhTe": {
        }
      }
      this.ItemPOST.ChiPhiChung.HeSoDieuChinhThoiGian = 1;
      this.ItemPOST.ChiPhiChung.HeSoDieuChinhKhuVuc = 1;
    }
    else {
      this.header = "Cập nhật";
      this.ItemPOST = Object.assign({}, this.item);
      let dataFiles: any = [];
      this.ItemPOST.fileDinhKems.forEach(element => {
        let dataFile : any = {
          "NameLocal": element.TenGoc,
          "Name": element.TenGui,
          "DuongDan": element.DuongDan,
          "NoiDung": element.NoiDung,
        }
        dataFiles.push(dataFile)
      });
      this.obj.FileUpload = dataFiles;
      if(this.ItemPOST.ThongTinChung.ThoiGianLapDuLieuUnix !== 0 && this.ItemPOST.ThongTinChung.ThoiGianLapDuLieuUnix !== undefined
        && this.ItemPOST.ThongTinChung.ThoiGianLapDuLieuUnix !== null){
        this.ItemPOST.ThongTinChung.ThoiGianLapDuLieu =  this.MyService.unixToDate(this.ItemPOST.ThongTinChung.ThoiGianLapDuLieuUnix);
      }
    }
    this.GetListCapCongTrinh();
    this.GetListNhomCongTrinh();
    this.GetListdmDungChung();
    this.GetListdmGiaiDoanDuAn();
    this.GetListNam();
    this.GetListThang();
  }

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {

  }
  GetListdmGiaiDoanDuAn() {
    let data: any = {
      CurrentPage: 0,
    };
    this.DanhmucService.GetListdmGiaiDoanDuAn(data).subscribe(res => {
      this.listdmgiaidoanduan = res
    });
  }
  onCompleteItem = (item: any, response: any, status: any, headers: any) => {
    let res = JSON.parse(response);
    // res[0].DuongDan = res[0].Url;
    this.obj.FileUpload.push(res[0]);
  };

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
  }

  changetabthongtinchiphicongtrinh(){
    
  }
  GetListCapCongTrinh() {
    let data: any = {
      CurrentPage: 0,
    };
    this.DanhmucService.GetListdmCapCongTrinh(data).subscribe(res => {
      this.listcapcongtrinh = res
    });
  }
  GetListNhomCongTrinh() {
    let data: any = {
      CurrentPage: 0,
    };
    this.DanhmucService.GetListdmNhomCongTrinh(data).subscribe(res => {
      this.listnhomcongtrinh = res
    });
  }
  SetData() {
    let data: any =
    {
      "ID": this.type == 'themmoi' ? 0 : this.ItemPOST.ID,
      "Ten": this.ItemPOST.ThongTinChung.Ten != undefined && this.ItemPOST.ThongTinChung.Ten != null ? this.ItemPOST.ThongTinChung.Ten : "",
      "IDParent": this.type == 'themmoi' ? this.IDParent.ID : this.IDParent.IDParent,
      "Loai": 1,
      "IDdmTinh": this.IDdmTinh,
      "IDThongTinUser": this.dataitem.IDThongTinUser,
      "LoaiDanhMuc": "SuatVon",
      "KhuVuc": this.KhuVuc,
      "check": true,
      "ChiPhiChung": {
        "ID": this.type == 'themmoi' ? 0 : this.ItemPOST.ChiPhiChung.ID,
        "IDdmChiTieu": this.ItemPOST.ID,
        "ChiPhiXayDung": this.ItemPOST.ChiPhiChung.ChiPhiXayDung != undefined && this.ItemPOST.ChiPhiChung.ChiPhiXayDung != null ? this.ItemPOST.ChiPhiChung.ChiPhiXayDung : 0,
        "ChiPhiThietBi": this.ItemPOST.ChiPhiChung.ChiPhiThietBi != undefined && this.ItemPOST.ChiPhiChung.ChiPhiThietBi != null ? this.ItemPOST.ChiPhiChung.ChiPhiThietBi : 0,
        "ChiPhiQuanLiDuAn": this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn != undefined && this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn != null ? this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn : 0,
        "ChiPhiTuVanDauTu": this.ItemPOST.ChiPhiChung.ChiPhiTuVanDauTu != undefined && this.ItemPOST.ChiPhiChung.ChiPhiTuVanDauTu != null ? this.ItemPOST.ChiPhiChung.ChiPhiTuVanDauTu : 0,
        "ChiPhiKhac": this.ItemPOST.ChiPhiChung.ChiPhiKhac != undefined && this.ItemPOST.ChiPhiChung.ChiPhiKhac != null ? this.ItemPOST.ChiPhiChung.ChiPhiKhac : 0,
        "ChiPhiDuPhong": this.ItemPOST.ChiPhiChung.ChiPhiDuPhong != undefined && this.ItemPOST.ChiPhiChung.ChiPhiDuPhong != null ? this.ItemPOST.ChiPhiChung.ChiPhiDuPhong : 0,
        "DonViCongSuat": this.ItemPOST.ChiPhiChung.DonViCongSuat != undefined && this.ItemPOST.ChiPhiChung.DonViCongSuat != null ? this.ItemPOST.ChiPhiChung.DonViCongSuat : 0,
        "HeSoDieuChinhThoiGian": this.ItemPOST.ChiPhiChung.HeSoDieuChinhThoiGian != undefined && this.ItemPOST.ChiPhiChung.HeSoDieuChinhThoiGian != null ? this.ItemPOST.ChiPhiChung.HeSoDieuChinhThoiGian : 0,
        "HeSoDieuChinhKhuVuc": this.ItemPOST.ChiPhiChung.HeSoDieuChinhKhuVuc != undefined && this.ItemPOST.ChiPhiChung.HeSoDieuChinhKhuVuc != null ? this.ItemPOST.ChiPhiChung.HeSoDieuChinhKhuVuc : 0,
        "VonDauTu": this.ItemPOST.ChiPhiChung.VonDauTu != undefined && this.ItemPOST.ChiPhiChung.VonDauTu != null ? this.ItemPOST.ChiPhiChung.VonDauTu : 0,
        "DuToan": this.ItemPOST.ChiPhiChung.DuToan != undefined && this.ItemPOST.ChiPhiChung.DuToan != null ? this.ItemPOST.ChiPhiChung.DuToan : 0,
        "Nam": this.Nam,
        "Ten": this.ItemPOST.ThongTinChung.Ten != undefined && this.ItemPOST.ThongTinChung.Ten != null ? this.ItemPOST.ThongTinChung.Ten : "",
        "IDCongTrinh": this.ItemPOST.ChiPhiChung.IDCongTrinh != undefined && this.ItemPOST.ChiPhiChung.IDCongTrinh != null ? this.ItemPOST.ChiPhiChung.IDCongTrinh : 0,
        "IDThongTinUser": this.dataitem.IDThongTinUser,
      },
      "ThongTinChung": {
        "ID": this.type == 'themmoi' ? 0 : this.ItemPOST.ThongTinChung.ID,
        "Ten": this.ItemPOST.ThongTinChung.Ten != undefined && this.ItemPOST.ThongTinChung.Ten != null ? this.ItemPOST.ThongTinChung.Ten : "",
        "IDCongTrinh": this.type == 'themmoi' ? 0 : this.ItemPOST.ChiPhiChung.ID,
        "IDdmCapCongTrinh": this.ItemPOST.ThongTinChung.IDdmCapCongTrinh != undefined ? this.ItemPOST.ThongTinChung.IDdmCapCongTrinh : 0,
        "IDdmNhomCongTrinh": this.ItemPOST.ThongTinChung.IDdmNhomCongTrinh != undefined ? this.ItemPOST.ThongTinChung.IDdmNhomCongTrinh : 0,
        "IDdmChiTieu": this.ItemPOST.ID,
        "DiaDiem": this.ItemPOST.ThongTinChung.DiaDiem != undefined && this.ItemPOST.ThongTinChung.DiaDiem != null ? this.ItemPOST.ThongTinChung.DiaDiem : "",
        "CongSuat": this.ItemPOST.ThongTinChung.CongSuat != undefined && this.ItemPOST.ThongTinChung.CongSuat != null ? this.ItemPOST.ThongTinChung.CongSuat : "",
        "NangLuc": this.ItemPOST.ThongTinChung.NangLuc != undefined && this.ItemPOST.ThongTinChung.NangLuc != null ? this.ItemPOST.ThongTinChung.NangLuc : "",
        "QuyChuan": this.ItemPOST.ThongTinChung.QuyChuan != undefined && this.ItemPOST.ThongTinChung.QuyChuan != null ? this.ItemPOST.ThongTinChung.QuyChuan : "",
        "TieuChuan": this.ItemPOST.ThongTinChung.TieuChuan != undefined && this.ItemPOST.ThongTinChung.TieuChuan != null ? this.ItemPOST.ThongTinChung.TieuChuan : "",
        "ThoiGianSuDung": this.ItemPOST.ThongTinChung.ThoiGianSuDung != undefined && this.ItemPOST.ThongTinChung.ThoiGianSuDung != null ? this.ItemPOST.ThongTinChung.ThoiGianSuDung : "",
        "DienTich": this.ItemPOST.ThongTinChung.DienTich != undefined && this.ItemPOST.ThongTinChung.DienTich != null ? this.ItemPOST.ThongTinChung.DienTich : "",
        "CoChe": this.ItemPOST.ThongTinChung.CoChe != undefined && this.ItemPOST.ThongTinChung.CoChe != null ? this.ItemPOST.ThongTinChung.CoChe : "",
        "ThongTinKhac": this.ItemPOST.ThongTinChung.ThongTinKhac != undefined && this.ItemPOST.ThongTinChung.ThongTinKhac != null ? this.ItemPOST.ThongTinChung.ThongTinKhac : "",
        "Nam": this.Nam,
        "IDdmTinh": this.IDdmTinh,       
        "IDThongTinUser": this.dataitem.IDThongTinUser, 
        "IDdmGiaiDoanDuAn":this.ItemPOST.ThongTinChung.IDdmGiaiDoanDuAn,
        "NamXayDung":this.ItemPOST.ThongTinChung.NamXayDung,
        "NamSuDung":this.ItemPOST.ThongTinChung.NamSuDung,
        "ThangSuDung":this.ItemPOST.ThongTinChung.ThangSuDung,
        "ChuDauTu":this.ItemPOST.ThongTinChung.ChuDauTu,
        "ThoiGianLapDuLieuUnix": this.MyService.ghepdateUnix(this.ItemPOST.ThongTinChung.ThoiGianLapDuLieu),

      },
      "ThongTinKinhTe": {
        "ID": this.type == 'themmoi' ? 0 : this.ItemPOST.ThongTinKinhTe.ID,
        "IDdmChiTieu": this.ItemPOST.ID,
        "IDCongTrinh": this.type == 'themmoi' ? 0 : this.ItemPOST.ChiPhiChung.ID,
        "NguonVon": this.ItemPOST.ThongTinKinhTe.NguonVon != undefined && this.ItemPOST.ThongTinKinhTe.NguonVon != null ? this.ItemPOST.ThongTinKinhTe.NguonVon : "",
        "ChiTieuTaiChinhKinhTe": this.ItemPOST.ThongTinKinhTe.ChiTieuTaiChinhKinhTe != undefined && this.ItemPOST.ThongTinKinhTe.ChiTieuTaiChinhKinhTe != null ? this.ItemPOST.ThongTinKinhTe.ChiTieuTaiChinhKinhTe : "",
        "HinhThucDauTu": this.ItemPOST.ThongTinKinhTe.HinhThucDauTu != undefined && this.ItemPOST.ThongTinKinhTe.HinhThucDauTu != null ? this.ItemPOST.ThongTinKinhTe.HinhThucDauTu : "",
        "TyGiaNgoaiTe": this.ItemPOST.ThongTinKinhTe.TyGiaNgoaiTe != undefined && this.ItemPOST.ThongTinKinhTe.TyGiaNgoaiTe != null ? this.ItemPOST.ThongTinKinhTe.TyGiaNgoaiTe : 0,
        "Nam": this.Nam,
        "IDThongTinUser": this.dataitem.IDThongTinUser,
        "ThongTinKhac": this.ItemPOST.ThongTinKinhTe.ThongTinKhac != undefined && this.ItemPOST.ThongTinKinhTe.ThongTinKhac != null ? this.ItemPOST.ThongTinKinhTe.ThongTinKhac :"",
      },
      "listChiPhiKhacTrenCuc": this.ItemPOST.listChiPhiKhacTrenCuc,
      "listChiPhiTuVanDauTuTrenCuc": this.ItemPOST.listChiPhiTuVanDauTuTrenCuc,
      "listChiPhiQuanLyDuAnTrenCuc": this.ItemPOST.listChiPhiQuanLyDuAnTrenCuc,
    };
    let fileDinhKems : any = [];
    this.obj.FileUpload.forEach(element => {
      let dataFile : any = {
        "ID": 0,
        "TenGoc": element.NameLocal,
        "TenGui": element.Name,
        "NoiDung": element.NoiDung,
      }
      fileDinhKems.push(dataFile)
    });
    data.fileDinhKems = fileDinhKems
    return data;
  }
  XacNhan() {
    if(this.ItemPOST.ThongTinChung.Ten == undefined || this.ItemPOST.ThongTinChung.Ten == null || this.ItemPOST.ThongTinChung.Ten == ""){
      this.toastr.error("Bạn chưa nhập tên công trình");
    }
    else{
      this.SuatVonDauTuService.SetChiPhiCongTrinh(this.SetData()).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.Onclose();
        }
        else {
          this.toastr.error(res.message);
        }
      });
    }
  }
  Onclose() {
    this.activeModal.close();
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }
  GetListdmDungChung() {
    this.listDanhMucDungChung = [];
    
    this.DanhmucService.GetListdmDungChungCongTrinhDienHinh('CongTrinhDienHinh', 0, this.dataitem.IDUser).subscribe(res => {
          this.listDanhMucDungChung = res;
    });
  }
  LuaChonDanhMucdungChung(){
    const modalRef = this.modalService.open(HienthicongtrinhdaidienmodalComponent, { backdrop: 'static',size:'xl' });
    modalRef.componentInstance.LoaiDanhMucDungChung = 'CongTrinhDienHinh';
    modalRef.componentInstance.listDanhMucDungChungCopy = this.listDanhMucDungChung;
    modalRef.componentInstance.Nam = this.Nam;
    modalRef.componentInstance.LoaiCongTrinh = 'SuatVon';
    modalRef.componentInstance.IDThongTinUser = this.dataitem.IDThongTinUser;
    modalRef.result.then((data) => {
      let IDThongTinChung: any = this.ItemPOST.ThongTinChung.ID;
      let IDChiPhiChung: any = this.ItemPOST.ChiPhiChung.ID;
      let IDThongTinKinhTe: any = this.ItemPOST.ThongTinKinhTe.ID;
      
      this.ItemPOST.ThongTinChung = data.data.ThongTinChung;
      this.ItemPOST.ChiPhiChung = data.data.ChiPhiChung;
      this.ItemPOST.ThongTinKinhTe = data.data.ThongTinKinhTe;
      this.ItemPOST.ThongTinChung.ID = IDThongTinChung || 0;
      this.ItemPOST.ChiPhiChung.ID = IDChiPhiChung || 0;
      this.ItemPOST.ThongTinKinhTe.ID = IDThongTinKinhTe || 0;
      if(this.ItemPOST.ChiPhiChung.HeSoDieuChinhThoiGian == undefined || this.ItemPOST.ChiPhiChung.HeSoDieuChinhThoiGian == null)
        this.ItemPOST.ChiPhiChung.HeSoDieuChinhThoiGian = 1;
      else
        this.ItemPOST.ChiPhiChung.HeSoDieuChinhThoiGian = this.ItemPOST.ChiPhiChung.HeSoDieuChinhThoiGian.toString().replace('.',',');
      if(this.ItemPOST.ChiPhiChung.HeSoDieuChinhKhuVuc == undefined || this.ItemPOST.ChiPhiChung.HeSoDieuChinhKhuVuc == null)
        this.ItemPOST.ChiPhiChung.HeSoDieuChinhKhuVuc = 1;
      else
        this.ItemPOST.ChiPhiChung.HeSoDieuChinhKhuVuc = this.ItemPOST.ChiPhiChung.HeSoDieuChinhKhuVuc.toString().replace('.',',');
      if(this.ItemPOST.ChiPhiChung.DonViCongSuat == undefined || this.ItemPOST.ChiPhiChung.DonViCongSuat == null)
        this.ItemPOST.ChiPhiChung.DonViCongSuat = 1;
      else
        this.ItemPOST.ChiPhiChung.DonViCongSuat = this.ItemPOST.ChiPhiChung.DonViCongSuat.toString().replace('.',',');
      this.ItemPOST.listChiPhiKhacTrenCuc = data.data.listChiPhiKhacTrenCuc;
      this.ItemPOST.listChiPhiTuVanDauTuTrenCuc = data.data.listChiPhiTuVanDauTuTrenCuc;
      this.ItemPOST.listChiPhiQuanLyDuAnTrenCuc = data.data.listChiPhiQuanLyDuAnTrenCuc;
    }, (reason) => {
    });
  }
  saveDanhMucdungChung(){
    if(this.ItemPOST.ThongTinChung.Ten == undefined || this.ItemPOST.ThongTinChung.Ten == null || this.ItemPOST.ThongTinChung.Ten == ""){
      this.toastr.error("Bạn chưa nhập tên công trình");
    }
    else{
      let data:any={
        "Ten":this.ItemPOST.ThongTinChung.Ten,
        "LoaiDanhMuc": 'CongTrinhDienHinh',
        "IDUser": this.dataitem.IDUser,
        "dmCongTrinhDienHinh": this.SetData()
      }
      this.DanhmucService.KiemTraTrungdmDungChung(this.ItemPOST.ThongTinChung.Ten, this.dataitem.IDUser).subscribe(resGhiDe => {
        if (resGhiDe.State === 0) {
          const modalRef = this.modalService.open(ThongbaoComponent, { backdrop: 'static' });
          modalRef.componentInstance.thongbao = "Bạn có chắc chắn muốn ghi đè?";
          modalRef.result.then((any) => {
            data.ID = resGhiDe.objectReturn.ID;
            this.DanhmucService.SaveCongTrinhDienHinh(data).subscribe(res => {
              if (res.State === 1) {
                this.toastr.success(res.message);
                this.Onclose();
              }
              else {
                this.toastr.error(res.message);      
              }
            });      
          }, (reason) => {
          });
        }
        else {
          this.DanhmucService.SaveCongTrinhDienHinh(data).subscribe(res => {
            if (res.State === 1) {
              this.toastr.success(res.message);
            }
            else {
              this.toastr.error(res.message);      
            }
          }); 
        }
      });
    }
  }
  taixuong(tenfile) {
    this.MyService.taixuong(tenfile);
  }
  GetListNam() {
    var listNam = [];
    this.listNam = [];
    for(let i = 1970; i <= 2050; i++){
      let data: any={
        "ma": i,
        "ten":i
      }
      listNam.push(data)
    }
    this.listNam = listNam;
  }
  GetListThang() {
    var listThang = [];
    this.listThang = [];
    for(let i = 1; i <= 12; i++){
      let data: any={
        "ma": i,
        "ten":i
      }
      listThang.push(data)
    }
    this.listThang = listThang;
  }
  XoaListDauTu(item, isSua = false) {
    if(isSua = true){
      this.ItemPOST.ChiPhiChung.ChiPhiTuVanDauTu = 0;
      this.ItemPOST.listChiPhiTuVanDauTuTrenCuc.forEach(element => {
        this.ItemPOST.ChiPhiChung.ChiPhiTuVanDauTu += parseInt(element.GiaTri || 0)
      });
    }
    else{
      this.ItemPOST.ChiPhiChung.ChiPhiTuVanDauTu -= (item.GiaTri || 0);
      item.isXoa = true;
    }
  }
  XoaListKhac(item, isSua = false) {
    if(isSua = true){
      this.ItemPOST.ChiPhiChung.ChiPhiKhac = 0;
      this.ItemPOST.listChiPhiKhacTrenCuc.forEach(element => {
        this.ItemPOST.ChiPhiChung.ChiPhiKhac += parseInt(element.GiaTri || 0)
      });
    }
    else{
      this.ItemPOST.ChiPhiChung.ChiPhiKhac -= (item.GiaTri || 0); 
      item.isXoa = true;
    }
  }
  XoaListQuanLiDuAn(item, isSua = false) {
    if(isSua = true){
      this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn = 0;
      this.ItemPOST.listChiPhiQuanLyDuAnTrenCuc.forEach(element => {
        this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn += parseInt(element.GiaTri || 0)
      });
    }
    else{
      this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn -= (item.GiaTri || 0);
      item.isXoa = true;
    }
  }
  ThemMoiQLDA(){
    let data: any = {
      Ten: "",
      GiaTri: 0,
      LoaiTinhCSG: 11,
      DonviTinh: "",
      ThueGiaTriGiaTang: 0,
      GiaTriSauThue: 0,
    }
    this.ItemPOST.listChiPhiQuanLyDuAnTrenCuc.unshift(data);
  }
  ThemMoiTuVanDauTu(){
    let data: any = {
      Ten: "",
      GiaTri: 0,
      LoaiTinhCSG: 11,
      DonviTinh: "",
      ThueGiaTriGiaTang: 0,
      GiaTriSauThue: 0,
    }
    this.ItemPOST.listChiPhiTuVanDauTuTrenCuc.unshift(data);
  }
  ThemMoiKhac(){
    let data: any = {
      Ten: "",
      GiaTri: 0,
      LoaiTinhCSG: 11,
      DonviTinh: "",
      ThueGiaTriGiaTang: 0,
      GiaTriSauThue: 0,
    }
    this.ItemPOST.listChiPhiKhacTrenCuc.unshift(data);
  }
}