import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HostService, httpOptions } from './host.service';

@Injectable({
  providedIn: 'root'
})
export class SuatVonDauTuService {

  constructor(
    private http: HttpClient
  ) { }

  public suatvondautuUrl = `${HostService.serverUrl}SuatVonDauTu`;

  GetListChiPhiCongTrinh(data) {
    let url = `${this.suatvondautuUrl}/GetListChiPhiCongTrinh`;
    return this.http.post<any>(url, data, httpOptions);
  }

  GetChiPhiCongTrinh(ID) {
    let url = `${this.suatvondautuUrl}/GetChiPhiCongTrinh?ID=${ID}`;
    return this.http.get<any>(url, httpOptions);
  }

  SetChiPhiCongTrinh(data) {
    let url = `${this.suatvondautuUrl}/SetChiPhiCongTrinh`;
    return this.http.post<any>(url, data, httpOptions);
  }
  DeleteChiPhiCongTrinh(data) {
    let url = `${this.suatvondautuUrl}/DeleteChiPhiCongTrinh`;
    return this.http.post<any>(url, data, httpOptions);
  }

  GetListHeSoDieuChinh(data) {
    let url = `${this.suatvondautuUrl}/GetListHeSoDieuChinh`;
    return this.http.post<any>(url, data, httpOptions);
  }
  SetHeSoDieuChinh(data) {
    let url = `${this.suatvondautuUrl}/SetHeSoDieuChinh`;
    return this.http.post<any>(url, data, httpOptions);
  }
  TinhListSuatVonDauTu(data) {
    let url = `${this.suatvondautuUrl}/TinhListSuatVonDauTu`;
    return this.http.post<any>(url, data, httpOptions);
  }
  GetListSuatVonDauTu(data) {
    let url = `${this.suatvondautuUrl}/GetListSuatVonDauTu`;
    return this.http.post<any>(url, data, httpOptions);
  }
  GetListGiaBoPhanKetCau(data) {
    let url = `${this.suatvondautuUrl}/GetListGiaBoPhanKetCau`;
    return this.http.post<any>(url, data, httpOptions);
  }
  ExportGiaBoPhan(data) {
    let url = `${this.suatvondautuUrl}/ExportGiaBoPhan`;
    return this.http.post<any>(url, data, httpOptions);
  }
  GetListHeSoKhoanMucChiPhi(data) {
    let url = `${this.suatvondautuUrl}/GetListHeSoKhoanMucChiPhi`;
    return this.http.post<any>(url, data, httpOptions);
  }
  TinhGiaBoPhanKetCauCongTrinh(data) {
    let url = `${this.suatvondautuUrl}/TinhGiaBoPhanKetCauCongTrinh`;
    return this.http.post<any>(url, data, httpOptions);
  }
  ImportdmCongTrinhDienHinh(FileName, IDThongTinUser, KhuVuc) {
    let url = `${this.suatvondautuUrl}/ImportdmCongTrinhDienHinh?FileName=${FileName}&IDThongTinUser=${IDThongTinUser}&KhuVuc=${KhuVuc}`;
    return this.http.get<any>(url, httpOptions);
  }
  ExportdmCongTrinhDienHinh(data) {
    let url = `${this.suatvondautuUrl}/ExportdmCongTrinhDienHinh`;
    return this.http.post<any>(url, data, httpOptions);
  }
  ExportSuatVon(data) {
    let url = `${this.suatvondautuUrl}/ExportSuatVon`;
    return this.http.post<any>(url, data, httpOptions);
  }
  XuatBaoCaoSuatVonWord(data) {
    let url = `${this.suatvondautuUrl}/XuatBaoCaoSuatVonWord`;
    return this.http.post<any>(url, data, httpOptions);
  }
  XuatBaoCaoGiaBoPhanWord(data) {
    let url = `${this.suatvondautuUrl}/XuatBaoCaoGiaBoPhanWord`;
    return this.http.post<any>(url, data, httpOptions);
  }
  GetListChiPhiCongTrinhBoPhanKetCau(data) {
    let url = `${this.suatvondautuUrl}/GetListChiPhiCongTrinhBoPhanKetCau`;
    return this.http.post<any>(url, data, httpOptions);
  }
  SetChiPhiCongTrinhBoPhanKetCau(data) {
    let url = `${this.suatvondautuUrl}/SetChiPhiCongTrinhBoPhanKetCau`;
    return this.http.post<any>(url, data, httpOptions);
  }
  ImportdmCongTrinhDienHinhBPKC(FileName, IDThongTinUser, KhuVuc) {
    let url = `${this.suatvondautuUrl}/ImportdmCongTrinhDienHinhBPKC?FileName=${FileName}&IDThongTinUser=${IDThongTinUser}&KhuVuc=${KhuVuc}`;
    return this.http.get<any>(url, httpOptions);
  }
  ExportdmCongTrinhDienHinhBPKC(data) {
    let url = `${this.suatvondautuUrl}/ExportdmCongTrinhDienHinhBPKC`;
    return this.http.post<any>(url, data, httpOptions);
  }
  GetCongTrinhDienHinhGiaBoPhanKetCau(ID) {
    let url = `${this.suatvondautuUrl}/GetCongTrinhDienHinhGiaBoPhanKetCau?ID=${ID}`;
    return this.http.get<any>(url, httpOptions);
  }   
  ExportCongBoFull(data) {
    let url = `${this.suatvondautuUrl}/ExportCongBoFull`;
    return this.http.post<any>(url, data, httpOptions);
  }
  SetFileMau(FileName, TenFileGoc) {
    let url = `${this.suatvondautuUrl}/SetFileMau?FileName=${FileName}&TenFileGoc=${TenFileGoc}`;
    return this.http.get<any>(url, httpOptions);
  }
  fileHelp() {
    let url = `${this.suatvondautuUrl}/fileHelp`;
    return this.http.get<any>(url, httpOptions);
  }
}
