export function FormatNumber(number?: any): any {
  if (number !== undefined && number !== null) {
    return number.toString().replace('.', ',');
  } else {
    return number;
  }
}
export function FormatStringToNumber(number?: any): any {
  if (number !== undefined && number !== null) {
    return number.toString().replace(',', '.');
  } else {
    return number;
  }
}