import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../services/danhmuc.service';
import { MyService } from './../../services/my.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CapnhapthongtinuserComponent } from './NgbModal/capnhapthongtinuser/capnhapthongtinuser.component';
import { LuaChonCSGComponent } from 'src/app/login/login/NgbModal/luachon/luachon.component';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment';
   
@Component({
  selector: 'app-thongtinuser',
  templateUrl: './thongtinuser.component.html',
  styleUrls: ['./thongtinuser.component.css']
})
export class ThongtinuserComponent implements OnInit {

  dataitem: any = {};
  data: any = {}
  currentUser: any = {};
  listIPOS: any = [];
  IDUSER : any= "";
  listnam: any = [];
  objloaddata: any = {}
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private DanhmucService: DanhmucService,
    private MyService: MyService,
    private toastr: ToastrService,
    private authService: AuthenticationService,
    public spinner: NgxSpinnerService,
  ) {
  }
  ngOnInit() {
    this.data = {
      CurrentPage: 1,
      PageSize: 25,
      sFilter: "",
      IDUser: this.IDUSER,
    };
    this.dataitem.paging={};
    this.dataitem.TotalItem = 0;
    this.IDUSER = Object.assign({}, JSON.parse(localStorage.getItem('currentUser'))).Id;
    this.getnam();
  }
  getnam() {
    let data: any = { 
      ma: 0,
      nam: 'Tất cả' }
    this.listnam.push(data);
    for (let i = moment().year() - 20; i <= moment().year() + 20; i++) {
      let data: any = { 
        ma: i,
        nam: i }
      this.listnam.push(data);
    }
    this.objloaddata.Nam = 0;
    this.GetListThongTinUser();
  } 
  GetListThongTinUser() {
    let data = {
      CurrentPage: this.data.CurrentPage,
      PageSize: 25,
      sFilter: this.dataitem.sFilter,
      IDUser: this.IDUSER,
    };
    this.DanhmucService.GetListThongTinUser(data).subscribe(res => {
      this.listIPOS = res.items;
      this.dataitem.paging = res.paging;
      this.dataitem.TotalItem = res.paging.TotalItem;
    });
  }
  themmoi() {
    const modalRef = this.modalService.open(LuaChonCSGComponent, {size:'sm', backdrop: 'static', centered: true});
    modalRef.componentInstance.type = 'themmoi';  
    modalRef.result.then((data) => {
      this.GetListThongTinUser();
    }, (reason) => {
      // không
    });
  }

  capnhat(item) {
    const modalRef = this.modalService.open(CapnhapthongtinuserComponent, { backdrop: 'static' });
    modalRef.componentInstance.type = 'capnhat';
    modalRef.componentInstance.item = item;
    modalRef.result.then((data) => {
      this.GetListThongTinUser();
    }, (reason) => {
      // không
    });
  }
  luachonSuatVon(item) {
    this.MyService.checklogin = true;                
    this.router.navigate(['/SuatVonDauTu/NhapDinhMucSuatVonDanDung']);   
    localStorage.setItem('thongtinuser', JSON.stringify(item));     
  }
  luachonGiaBoPhan(item) {
    this.MyService.checklogin = true;                
    this.router.navigate(['/GiaXayDung/GiaBoPhanKetCauCongTrinhDanDung']);   
    localStorage.setItem('thongtinuser', JSON.stringify(item));     
  }
  xoa(item){
    const modalRef = this.modalService.open(ThongbaoComponent, { backdrop: 'static' });
    modalRef.componentInstance.thongbao = "Bạn có chắc chắn muốn xóa?";
    modalRef.result.then((any) => {
      this.spinner.show();
      this.DanhmucService.DeleteThongTinUser(item).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.GetListThongTinUser();
        }
        else {
          this.toastr.error(res.message);      
        }
        this.spinner.hide();
      });      
    }, (reason) => {
      // không
    });
  }
  refresh(){
    this.objloaddata.sFilter = "";
    this.objloaddata.Nam =0;
    this.GetListThongTinUser()
  }
}
