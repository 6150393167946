import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HostService } from './host.service';
import { Router } from '@angular/router';
import { MyService } from './../services/my.service';


const httpOptions = {
  headers: new HttpHeaders({
    //'Content-Type':  'application/x-www-form-urlencoded',
    'Content-Type': 'application/json',
    //'Accept':  'application/json',
    //'Origin': 'http://serverduan:2626'

    //,'Authorization': 'my-auth-token',
  })
  , withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  loginurl = `${HostService.serverUrl}DanhMuc/DangNhap`;

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(
    private http: HttpClient,
    public router: Router,
    private MyService: MyService,
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  getCurrentUser() {
    let url = `${HostService.serverUrl}api/DanhMuc/getCurrentUser`;
    return this.http.get<any>(url, httpOptions)
      .pipe(map(obj => {
        if (obj) {
          localStorage.setItem('infocurrentUserHH', JSON.stringify(obj));
          this.currentUserSubject.next(obj);
        }
        return obj;
      }));
  }

  login(username: string, password: string) {
    let data = { "UserName": username, "Password": password, "RememberMe": true }

    return this.http.post<any>(this.loginurl, data, httpOptions)
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user) {
          // if (user) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          if (this.MyService.currentUser.State != undefined && this.MyService.currentUser.State != 1) {
            this.MyService.checklogin = true;
          }
          // this.getCurrentUser()
        }
        // console.log(user)
        return user;
      }));
  }
  logout() {
    // remove user from local storage to log user out
    // let url = '/SmartEOSAPI/Account/LogOff'
    // let data = {};
    // this.http.post<any>(url, data, httpOptions)
    localStorage.removeItem('currentUser');
    localStorage.removeItem('infocurrentUserHH');
    this.currentUserSubject.next(null);
    this.router.navigate(['/Login']);
  }
}
