import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../../../services/danhmuc.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { ThemMoiDuAnDanhMucDungChungComponent } from '../themmoiduancongtrinhdienhinh/themmoiduancongtrinhdienhinh.component';
import { ThongTinBanDauComponent } from '../luachontinh/luachontinh.component';
import { CongboComponent } from 'src/app/giaxaydung/dscongbo/congbo/congbo.component';
@Component({
  selector: 'app-luachoncsg',
  templateUrl: './luachon.component.html',
  styleUrls: ['./luachon.component.css']
})
export class LuaChonCSGComponent implements OnInit {

  type: any = '';
  item: any = {};
  ItemPOST: any = {};
  thongtinuser: any = {};
  header: any = "";
  IDParent: any = {};
  dataitem: any = {};
  dataitemThongTinUser: any = {};
  ListItemPost: any = [];
  ListLoaiCongTrinhSVDT: any = [];
  ListLoaiCongTrinhGBP: any = [];
  

  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private DanhmucService: DanhmucService,
    private toastr: ToastrService,
    private router: Router,
  ) {}
  ngOnInit() {
    this.ItemPOST.IDThongTinUser = 0;
    this.listData();
    this.ItemPOST.luachon = '0';
    this.GetlistdmLoaiCongTrinhSuatVon();
  }

  checkdata() {
    if (this.ItemPOST.Ma != undefined && this.ItemPOST.Ma != null && this.ItemPOST.Ma != "" &&
      this.ItemPOST.Ten != undefined && this.ItemPOST.Ten != null && this.ItemPOST.Ten != ""
    ) {
      return true;
    }
    else {
      return false;
    }
  }
  listData() {
    let data: any = {
      CurrentPage: 0,
    };
    this.DanhmucService.GetListThongTinUser(data).subscribe(res => {
      this.ListItemPost = res;
    });
  }
  GetlistdmLoaiCongTrinhSuatVon(){
    let listSV: any = []
    let listGBP: any = []
    this.DanhmucService.GetListdmLoaiCongTrinhThietLapBanDau().subscribe(res => {
      res.forEach(element => {
        if(element.LoaiDanhMuc == "SuatVon")
          listSV.push(element);
        else if(element.LoaiDanhMuc == "GiaBoPhan")
          listGBP.push(element);
      });
      this.ListLoaiCongTrinhSVDT = listSV;
      this.ListLoaiCongTrinhGBP = listGBP;
    });
  }
  themmoi() {
    this.activeModal.close();
    const modalRef = this.modalService.open(ThongTinBanDauComponent, {size:'lg', backdrop: 'static' });
    modalRef.componentInstance.type = 'themmoi';  
    modalRef.componentInstance.isReload = true;  
    modalRef.componentInstance.ListLoaiCongTrinhSVDT = this.ListLoaiCongTrinhSVDT;  
    modalRef.componentInstance.ListLoaiCongTrinhGBP = this.ListLoaiCongTrinhGBP;  
    modalRef.result.then((data) => {
    }, (reason) => {
      // không
    });
  }
  luaChonLoaiCSG() {
    this.ListItemPost.forEach(element => {
      if (element.ID == this.ItemPOST.IDThongTinUser) {
        this.dataitemThongTinUser = element;    
      }
    });
    this.router.navigate(['/DanhMuc/DanhMucTinh']);  
    window.location.reload(); 

  }
  Onclose() {
    this.router.navigate(['/ThongTinUser']);  
    location.reload(); 
    this.activeModal.close();
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }
  CongTrinhDienHinh(){
    const modalRef = this.modalService.open(ThemMoiDuAnDanhMucDungChungComponent, {size:'fullscreen',backdrop: 'static', scrollable: true});
    modalRef.componentInstance.type = "themmoi";
    modalRef.componentInstance.LoaidanhMuc = "Loaicongtrinh";
    modalRef.result.then(() => {
    this.activeModal.close();
    }, (reason) => {
    });
  }
  XacNhan(){
    if(parseInt(this.ItemPOST.luachon) === 0)
      this.themmoi();
    else if(parseInt(this.ItemPOST.luachon) === 2)
      this.Onclose();
    else if(parseInt(this.ItemPOST.luachon) === 3)
      this.CongTrinhDienHinh();
      else if(parseInt(this.ItemPOST.luachon) === 4)
      this.CongBo();
  }
  CongBo(){
    let item: any ={
      ID: 0,
      Ten: this.dataitem.TenUser,
      fileUploadFileDinhKem: [],
      fileUpload: [],
      isThayThe: false,
      IDThongTinUser: 0
    }
    const modalRef = this.modalService.open(CongboComponent, { backdrop: 'static', size:'xl' });
    modalRef.componentInstance.ItemPOST = JSON.parse(JSON.stringify(item));
    modalRef.componentInstance.Nam = this.dataitem.Nam;
    modalRef.result.then((data) => {
    this.activeModal.close();
    this.router.navigate(['/ThongTinUser']);
    location.reload();
    }, (reason) => {
    });
  }
}
