import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DanhmucService } from './../../services/danhmuc.service';
import { MyService } from './../../services/my.service';
import { FileUploader, FileItem, ParsedResponseHeaders } from "ng2-file-upload";
import { HostService } from 'src/app/services/host.service';
import { SuatVonDauTuService } from './../../services/suatvondautu.service';

@Component({
  selector: 'app-filemau',
  templateUrl: './filemau.component.html',
  styleUrls: ['./filemau.component.css']
})
export class FilemauComponent implements OnInit {
  listIPOS: any = [];
  uploader: FileUploader;
item: any = {};
  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    private DanhmucService: DanhmucService,
    private MyService: MyService,
    private SuatVonDauTuService: SuatVonDauTuService,

  ) { }

  ngOnInit() {
    this.uploader = new FileUploader({
      url: `${HostService.serverUrl}FileUploader/Post`,
      headers: [{ name: 'Accept', value: 'application/json' }],
      autoUpload: true,
    });
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = true;
    };
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteItem = (item, response, status, headers) => this.onCompleteItem(item, response, status, headers);

    this.GetListfile();
  }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
  }
  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {

  }

  onCompleteItem = (item: any, response: any, status: any, headers: any) => {
    console.log(item)
    let res = JSON.parse(response);
    res[0].Id = 0;
    console.log(res)
    this.SuatVonDauTuService.SetFileMau(res[0].Name, this.item.TenFileMau).subscribe(res => {
      if (res.State === 1) {
        this.toastr.success(res.message);
      }
      else {
        this.toastr.error(res.message);
      }
    });

    // if(res[0].Name !== undefined && res[0].Name !== null){
    //     this.toastr.success("Cập nhật thành công");
    // }
    // else
    //     this.toastr.error("Lỗi cập nhật")
  };
  GetListfile() {
    this.DanhmucService.GetListFileMau().subscribe(res => {
      this.listIPOS = res;
    });
  }
  
  capnhat(item){
    
  }
  taixuong(tenfile) {
    console.log(tenfile)
    this.MyService.taixuong(tenfile);
  }
  setItem(item) {
    this.item = item;
  }
}
