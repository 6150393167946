import { Component, OnInit } from '@angular/core';
import { DanhmucService } from 'src/app/services/danhmuc.service';
import { HostService } from 'src/app/services/host.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  CheckUserAD: boolean = false;
  constructor(
    private DanhmucService: DanhmucService,
  ) { }

  ngOnInit() {
    this.CheckUser();
  }
  CheckUser() {
    this.DanhmucService.CheckUser().subscribe(res => {
      this.CheckUserAD = res;
    });
  }
  fileHelp() {
    window.open(`${HostService.serverUrl}SVDT_help/TieuDe.htm`);
  }
  dangNhap() {
    window.open(`${HostService.serverUrl}SVDT_help/1.htm`);
  }
  thongTinSuatVon() {
    window.open(`${HostService.serverUrl}SVDT_help/2.htm`);
  }
  suatVonDauTu() {
    window.open(`${HostService.serverUrl}SVDT_help/3.htm`);
  }
  thongTinGiaBoPhan() {
    window.open(`${HostService.serverUrl}SVDT_help/4.htm`);
  }
  giaBoPhanKetCau() {
    window.open(`${HostService.serverUrl}SVDT_help/5.htm`);
  }
  congBo() {
    window.open(`${HostService.serverUrl}SVDT_help/6.htm`);
  }
  danhMuc() {
    window.open(`${HostService.serverUrl}SVDT_help/7.htm`);
  }
}
