import { Component, OnInit, DebugNode } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MyService } from './../../../../services/my.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { LuaChonCSGComponent } from '../luachon/luachon.component';
import { DanhmucService } from './../../../../services/danhmuc.service';
import { NgxSpinnerService } from "ngx-spinner";
import { LuachondanhmucloaicongtrinhdungchungComponent } from './luachondanhmucloaicongtrinhdungchung/luachondanhmucloaicongtrinhdungchung.component';

@Component({
  selector: 'app-luachontinh',
  templateUrl: './luachontinh.component.html',
  styleUrls: ['./luachontinh.component.css']
})
export class ThongTinBanDauComponent implements OnInit {
  type: any = '';
  TeniconCongTrinh: any = '';
  TeniconVatLieu: any = '';
  item: any = {};
  ItemPOST: any = {};
  thongtinuser: any = {};
  header: any = "";
  IDParent: any = {};
  dataitem: any = {};
  dataitemThongTinUser: any = {};
  ListtreeSV: any = [];
  ListtreeGBP: any = [];
  ListLoaiCongTrinhSVDT: any = [];
  ListLoaiCongTrinhGBP: any = [];
  checkListtree: any = [];
  itemSELeCT: any = {};
  ListLoaiCongTrinhSet: any = [];
  IDLoaiCongTrinh: any = 0;
  USER: any = {};
  Checkicon: any = 0;
  isReload: any = false;
  CheckboxAllCongTrinhSV: any = false
  CheckboxAllCongTrinhGBP: any = false
  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private MyService: MyService,
    private toastr: ToastrService,
    private router: Router,
    private DanhmucService: DanhmucService,
    public spinner: NgxSpinnerService,
  ) { }
  ngOnInit() {
    this.Checkicon = 0;
    this.TeniconVatLieu = 'Chọn tất cả';
    this.TeniconCongTrinh = 'Chọn tất cả';
    this.USER = Object.assign({}, JSON.parse(localStorage.getItem('currentUser')));
    this.ListLoaiCongTrinhSVDT.filter(obj => {
      obj.collspan = true;
      obj.check = false;
      obj.select = false;
    });
    this.ListLoaiCongTrinhGBP.filter(obj => {
      obj.collspan = true;
      obj.check = false;
      obj.select = false;
    });
    this.ListtreeSV = this.MyService.unflatten(this.ListLoaiCongTrinhSVDT);
    this.ListtreeGBP = this.MyService.unflatten(this.ListLoaiCongTrinhGBP);
    // this.checkParentTrue();
  }
  checkParentTrue() {
    this.ListtreeSV.forEach(element => {
      element.check = true;
    });
    this.ListtreeGBP.forEach(element => {
      element.check = true;
    });
  }
  CheckGiaTriIcon(Giatri) {
    this.Checkicon = Giatri;
  }
  SetData() {
    let data: any = {
      "ID": this.type == 'themmoi' ? 0 : this.item.ID,
      "IDUser": this.USER.Id,
      "Ten": this.ItemPOST.Ten,
      "NgayTao": this.type == 'themmoi' ? null : this.item.property,
      "MoTa": this.ItemPOST.MoTa !== undefined && this.ItemPOST.MoTa !== "" ? this.ItemPOST.MoTa : "",
      "Nam": this.ItemPOST.Nam !== undefined && this.ItemPOST.Nam !== "" ? this.ItemPOST.Nam : "",
      "NamTinh": this.ItemPOST.NamTinh !== undefined && this.ItemPOST.NamTinh !== "" ? this.ItemPOST.NamTinh : "",
      "GhiChu": this.ItemPOST.GhiChu !== undefined && this.ItemPOST.GhiChu !== "" ? this.ItemPOST.GhiChu : "",
      "NoiDung": this.ItemPOST.NoiDung !== undefined && this.ItemPOST.NoiDung !== "" ? this.ItemPOST.NoiDung : "",
      "LoaiTinhCSG": this.ItemPOST.LoaiTinhCSG !== undefined && this.ItemPOST.LoaiTinhCSG !== "" ? this.ItemPOST.LoaiTinhCSG : "",
      "IDdmDungChungSV": this.ItemPOST.IDdmDungChungSV || 0,
      "IDdmDungChungGBP": this.ItemPOST.IDdmDungChungGBP || 0,
    }
    return data;
  }
  XacNhan() {
    if (this.ItemPOST.Ten == null || this.ItemPOST.Ten == undefined) {
      this.toastr.error("Bạn chưa nhập tên");
    }
    else if (this.ItemPOST.Nam == null || this.ItemPOST.Nam == undefined) {
      this.toastr.error("Bạn chưa nhập năm tính");
    }
    else if (this.ItemPOST.Nam < 0) {
      this.toastr.error("Bạn nhập sai định dạng năm");
    }
    else {
      let listdmLoaiCongTrinhSVDT: any = []
      this.ListtreeSV.forEach(element => {
        if (element.check == true)
          listdmLoaiCongTrinhSVDT.push(element)
        element.children.forEach(chid => {
          if (chid.check == true)
            listdmLoaiCongTrinhSVDT.push(chid)
        });
      });
      let listdmLoaiCongTrinhGBP: any = []
      this.ListtreeGBP.forEach(element => {
        if (element.check == true)
          listdmLoaiCongTrinhGBP.push(element)
        element.children.forEach(chid => {
          if (chid.check == true)
            listdmLoaiCongTrinhGBP.push(chid)
        });
      });
      if (listdmLoaiCongTrinhSVDT.length == 0) {
        this.toastr.error("Bạn chưa chọn công trình suất vốn đầu tư");
      }
      else if (listdmLoaiCongTrinhSVDT.length == 0) {
        this.toastr.error("Bạn chưa chọn công trình bộ phận kết cấu");
      }
      else {
        this.spinner.show();
        let data: any = {}
        data = this.SetData(),
          data.listdmLoaiCongTrinhSVDT = listdmLoaiCongTrinhSVDT,
          data.listdmLoaiCongTrinhGBP = listdmLoaiCongTrinhGBP,
          this.router.navigate(['/SuatVonDauTu/NhapDinhMucSuatVonDanDung']);
        this.DanhmucService.SetThongTinUser(data).subscribe(res => {
          if (res.State === 1) {
            debugger
            this.activeModal.close();
            this.toastr.success(res.message);
            localStorage.setItem('thongtinuser', JSON.stringify(res.objectReturn));
            location.reload()
          }
          else {
            this.toastr.error(res.message);
          }
          this.spinner.hide();
        });
      }
    }
  }
  CheckTREESV(item) {
    item.check = !item.check;
    if (item.IDParent == 0) {
      if (item.check) {
        item.children.forEach(element => {
          element.check = true;
        });
      }
      else {
        item.children.forEach(element => {
          element.check = false;
        });
      }
    }
    else {
      if (item.check == true) {
        this.ListtreeSV.forEach(element => {
          if (element.ID == item.IDParent)
            element.check = true
        });
      }
    }
  }
  CheckTREEGBP(item) {
    item.check = !item.check;
    if (item.IDParent == 0) {
      if (item.check) {
        item.children.forEach(element => {
          element.check = true;
        });
      }
      else {
        item.children.forEach(element => {
          element.check = false;
        });
      }
    }
    else {
      if (item.check == true) {
        this.ListtreeGBP.forEach(element => {
          if (element.ID == item.IDParent)
            element.check = true
        });
      }
    }
  }
  Onclose() {
    this.router.navigate(['/ThongTinUser']);
    location.reload();
    this.activeModal.close();
  }

  dequyCheckBox(listItem, checkbox) {
    listItem.forEach(element => {
      element.check = checkbox;
      this.dequyCheckBox(element.children, checkbox)
    });
  }
  CheckAllCongTrinhSV() {
    this.CheckboxAllCongTrinhSV = !this.CheckboxAllCongTrinhSV;
    if (this.CheckboxAllCongTrinhSV == true) {
      this.TeniconCongTrinh = 'Bỏ chọn tất cả';
      this.ListtreeSV.forEach(element => {
        element.check = true;
        element.children.forEach(chid => {
          chid.check = true;
        });
      });
    }
    else {
      this.TeniconCongTrinh = 'Chọn tất cả';
      this.ListtreeSV.forEach(element => {
        element.check = false;
        element.children.forEach(chid => {
          chid.check = false;
        });
      });
      this.ListLoaiCongTrinhSet = []
    }
  }
  CheckAllCongTrinhGBP() {
    this.CheckboxAllCongTrinhGBP = !this.CheckboxAllCongTrinhGBP;
    if (this.CheckboxAllCongTrinhGBP == true) {
      this.TeniconVatLieu = 'Bỏ chọn tất cả';
      this.ListtreeGBP.forEach(element => {
        element.check = true;
        element.children.forEach(chid => {
          chid.check = true;
        });
      });
    }
    else {
      this.TeniconVatLieu = 'Chọn tất cả';
      this.ListtreeGBP.forEach(element => {
        element.check = false;
        element.children.forEach(chid => {
          chid.check = false;
        });
      });
      this.ListLoaiCongTrinhSet = []
    }
  }

  QuayLai() {
    if (this.isReload == true) {
      this.activeModal.close();
      const modalRef = this.modalService.open(LuaChonCSGComponent, { size: 'sm', backdrop: 'static', centered: true });
      modalRef.result.then((data) => {
      }, (reason) => {
        // không
      });
    }
    else
      this.activeModal.dismiss();
  }

  LuaChonDanhMucSV() {
    const modalRef = this.modalService.open(LuachondanhmucloaicongtrinhdungchungComponent,
      { backdrop: 'static', scrollable: true, size: 'lg' });
    modalRef.componentInstance.loaiDanhMuc = 'SuatVon';
    modalRef.result.then((data) => {
      this.CheckboxAllCongTrinhSV = false;
      this.ListtreeSV = data.data.Listtree;
      this.ItemPOST.IDdmDungChungSV = data.data.IDdmDungChung;
      }, (reason) => {
    })
  }
  LuaChonDanhMucGBP() {
    const modalRef = this.modalService.open(LuachondanhmucloaicongtrinhdungchungComponent,
      { backdrop: 'static', scrollable: true, size: 'lg' });
    modalRef.componentInstance.loaiDanhMuc = 'GiaBoPhan';
    modalRef.result.then((data) => {
      this.CheckboxAllCongTrinhGBP = false;
      this.ListtreeGBP = data.data.Listtree;
      this.ItemPOST.IDdmDungChungGBP = data.data.IDdmDungChung;
      }, (reason) => {
    })
  }
}
