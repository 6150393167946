import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { ToastrService } from 'ngx-toastr';
import { HienThiVLNCMComponent } from './NgbModal/hienthi-vlncm/hienthi-vlncm.component';
import { DanhmucService } from './../services/danhmuc.service';
import { ThemMoiDuAnDanhMucDungChungComponent } from '../login/login/NgbModal/themmoiduancongtrinhdienhinh/themmoiduancongtrinhdienhinh.component';
import { element } from 'protractor';
import { ViewloaicongtrinhdungchungComponent } from './viewloaicongtrinhdungchung/viewloaicongtrinhdungchung.component';
import { CongboComponent } from '../giaxaydung/dscongbo/congbo/congbo.component';
import { HostService } from '../services/host.service';

@Component({
  selector: 'app-danhmucdungchung',
  templateUrl: './danhmucdungchung.component.html',
  styleUrls: ['./danhmucdungchung.component.css']
})
export class DanhMucDungChungComponent implements OnInit {

  Listtree: any = [];
  data: any = {};
  listtinh: any = [];
  listloaidanhmuc: any = [];
  listkhuvuc: any = [];  
  List: any = [];
  listIPOS: any = [];
  itemSELeCT: any = {};
  quyenxoa: boolean = false;
  CheckUserAD: boolean = false;
  dataitem: any = {CurrentPage: 1,
    PageSize: 25};
  checkListtree: any = [];
  Listpost: any = [];
  TimChaTree: any = [];
  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    private DanhmucService: DanhmucService,
  ) { }

  ngOnInit() {
    // this.dataitem.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
    // this.dataitem.Nam = JSON.parse(localStorage.getItem('thongtinuser')).NamTinh;
    // this.dataitem.NamGoc = JSON.parse(localStorage.getItem('thongtinuser')).Nam;
    // this.dataitem.TenUser = JSON.parse(localStorage.getItem('thongtinuser')).Ten;
    this.dataitem.IDUser = JSON.parse(localStorage.getItem('currentUser')).UserName;
    this.GetListdmLoaidanhMuc();
    this.GetListdmDungChung();
  }

  GetListdmLoaidanhMuc() {
    this.listloaidanhmuc = [];
    let data1: any = { Ma: 'LoaiCongTrinh', Ten: 'Loại công trình' }
    let data3: any = { Ma: 'CongTrinhDienHinh', Ten: 'Công trình đại diện' }
    let data4: any = { Ma: 'CongBo', Ten: 'Công bố suất vốn' }
    this.listloaidanhmuc.push(data1)
    this.listloaidanhmuc.push(data3)
    this.listloaidanhmuc.push(data4)
    this.data.LoaiDanhMuc = this.listloaidanhmuc[0].Ma;
  }
  GetListdmDungChung() {
      this.DanhmucService.GetListdmDungChungCongTrinhDienHinh(this.data.LoaiDanhMuc, this.dataitem.CurrentPage, this.dataitem.IDUser).subscribe(res => {
        this.listIPOS = res.items;
        this.dataitem.paging = res.paging;
        this.dataitem.TotalItem = res.paging.TotalItem;
        this.dataitem.CurrentPage = res.paging.CurrentPage;
      });
  }
  xoa(item){
    const modalRef = this.modalService.open(ThongbaoComponent, { backdrop: 'static' });
    modalRef.componentInstance.thongbao = "Bạn có chắc chắn muốn xóa?";
    modalRef.result.then((any) => {
      this.DanhmucService.DeletedmDungChungSVDT(item.IDdmDungChung).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.GetListdmDungChung();
        }
        else {
          this.toastr.error(res.message);      
        }
      });      
    }, (reason) => {
    });
  }
  capnhat(item){
    this.DanhmucService.GetdsDungChungCongTrinhDienHinhSVDT(item.IDdsDungChungLoaiCongTrinh).subscribe(res => {
      console.log(res)
      res.ChiPhiChung.HeSoDieuChinhKhuVuc = (res.ChiPhiChung.HeSoDieuChinhKhuVuc || 0).toString().replace('.',',');
      res.ChiPhiChung.HeSoDieuChinhThoiGian = (res.ChiPhiChung.HeSoDieuChinhThoiGian|| 0).toString().replace('.',',');
      res.ChiPhiChung.DonViCongSuat = (res.ChiPhiChung.DonViCongSuat|| 0).toString().replace('.',',');
      if(res.HeSoMayThiCong != null)
        res.HeSoMayThiCong = res.HeSoMayThiCong.toString().replace('.',',');
      if(res.HeSoNhanCong != null)
        res.HeSoNhanCong = res.HeSoNhanCong.toString().replace('.',',');
      if(res.HeSoVatLieu != null)
        res.HeSoVatLieu = res.HeSoVatLieu.toString().replace('.',',');
      res.listChiPhiVatLieu.forEach(element => {
        if(element.KhoiLuong != null)
          element.KhoiLuong = element.KhoiLuong.toString().replace('.',',');
      });
      res.listChiPhiNhanCong.forEach(element => {
        if(element.KhoiLuong != null)
          element.KhoiLuong = element.KhoiLuong.toString().replace('.',',');
      });
      res.listChiPhiMay.forEach(element => {
        if(element.KhoiLuong != null)
          element.KhoiLuong = element.KhoiLuong.toString().replace('.',',');
      });
      res.listChiPhiCongViec.forEach(element => {
        if(element.KhoiLuong != null)
          element.KhoiLuong = element.KhoiLuong.toString().replace('.',',');
      });
      const modalRef = this.modalService.open(ThemMoiDuAnDanhMucDungChungComponent, {size:'fullscreen', backdrop: 'static' });
      modalRef.componentInstance.item = res;
      modalRef.componentInstance.isView = true;
      modalRef.componentInstance.header = "Cập nhật";
      modalRef.result.then((data) => {
      }, (reason) => {
    });
  });
  }
  viewLoaiCongTrinh(item){
    const modalRef = this.modalService.open(ViewloaicongtrinhdungchungComponent,
      { backdrop: 'static', scrollable: true, size: 'lg' });
    modalRef.componentInstance.item = item;
    modalRef.result.then((data) => {
      }, (reason) => {
    })
  }
  DeleteLoaiCongTrinhDungChung(item){
    const modalRef = this.modalService.open(ThongbaoComponent, { backdrop: 'static' });
    modalRef.componentInstance.thongbao = "Bạn có chắc chắn muốn xóa?";
    modalRef.result.then((any) => {
      this.DanhmucService.DeletedmDungChung(item.ID).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.GetListdmDungChung();
        }
        else {
          this.toastr.error(res.message);      
        }
      });      
    }, (reason) => {
    });
  }
  editCongBo(item) {
    this.DanhmucService.GetdsCongBo(item.ID).subscribe(res => {
      const modalRef = this.modalService.open(CongboComponent, { backdrop: 'static', size:'xl' });
      modalRef.componentInstance.ItemPOST = JSON.parse(JSON.stringify(res));
      modalRef.componentInstance.listtinh = this.listtinh;
      // modalRef.componentInstance.listthoigian = this.listthoigian;
      modalRef.result.then((data) => {
        this.GetListdmDungChung();
      }, (reason) => {
        // không
      });
    });
  }
  xoaCongBo(item){
    const modalRef = this.modalService.open(ThongbaoComponent, { backdrop: 'static' });
    modalRef.componentInstance.thongbao = "Bạn có chắc chắn muốn xóa?";
    modalRef.result.then((any) => {
      this.DanhmucService.DeleteCongBo(item).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.GetListdmDungChung();
        }
        else {
          this.toastr.error(res.message);      
        }
      });      
    }, (reason) => {
      // không
    });
  }
  taixuong(item) {
    window.open(HostService.taianh + item);
  }
  CongBoChiSoGia(){
    let item: any ={
      ID: 0,
      Ten: this.dataitem.TenUser,
      fileUploadFileDinhKem: [],
      fileUpload: [],
      isThayThe: false,
      IDThongTinUser: 0
    }
    const modalRef = this.modalService.open(CongboComponent, { backdrop: 'static', size:'xl' });
    modalRef.componentInstance.ItemPOST = JSON.parse(JSON.stringify(item));
    modalRef.componentInstance.Nam = this.dataitem.Nam;
    modalRef.result.then((data) => {
      this.GetListdmDungChung();
    }, (reason) => {
    });
  }
}
