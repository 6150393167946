import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../../../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { MyService } from './../../../../services/my.service';
import * as moment from 'moment';

@Component({
  selector: 'app-capnhatvatlieu',
  templateUrl: './capnhatvatlieu.component.html',
  styleUrls: ['./capnhatvatlieu.component.css']
})
export class CapnhatvatlieuComponent implements OnInit {

  Listtree: any = [];
  itemSELeCT: any = {};
  Listpost: any = [];
  checkListtree: any = [];
  IDdmChiTieu: any = 0;
  IDCongTrinh: any = 0;
  Nam: any = 0;
  type: any = '';
  listvatlieu: any = [];
  listnhancong: any = [];
  listmaythicong: any = [];
  listchiphimuasam: any = [];
  IDdsLoaiCongTrinh: any = 0;
  item: any = {};
  constructor(
    private activeModal: NgbActiveModal,
    private ApiService: ApiService,
    private toastr: ToastrService,
    private MyService: MyService,
  ) { }

  ngOnInit() {
  }

  Onclose() {
    this.activeModal.close(
      {
        data: this.item
      }
    );
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }

}
