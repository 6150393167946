import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HostService, httpOptions } from './host.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient
  ) { }

  public Url = `${HostService.serverUrl}ChiSoGia`;

  GetGia(data) {
    let api = `${this.Url}/GetGia`;
    return this.http.post<any>(api, data, httpOptions);
  }

  SetGia(data) {
    let api = `${this.Url}/SetGia`;
    return this.http.post<any>(api, data, httpOptions);
  }

  ImportGia(FileName, IDdmKhuVuc) {
    let api = `${this.Url}/ImportGia?FileName=${FileName}&IDdmKhuVuc=${IDdmKhuVuc}`;
    return this.http.get<any>(api, httpOptions);
  }

  ImportTytrongCongTrinh(FileName, IDdmKhuVuc, Nam) {
    let api = `${this.Url}/ImportTytrongCongTrinh?FileName=${FileName}&IDdmKhuVuc=${IDdmKhuVuc}&Nam=${Nam}`;
    return this.http.get<any>(api, httpOptions);
  }

  ImportTytrongVLNCM(FileName, IDdmKhuVuc, Nam) {
    let api = `${this.Url}/ImportTytrongVLNCM?FileName=${FileName}&IDdmKhuVuc=${IDdmKhuVuc}&Nam=${Nam}`;
    return this.http.get<any>(api, httpOptions);
  }

  ImportTytrongDiaPhuong(FileName, IDdmKhuVuc, Nam) {
    let api = `${this.Url}/ImportTytrongDiaPhuong?FileName=${FileName}&IDdmKhuVuc=${IDdmKhuVuc}&Nam=${Nam}`;
    return this.http.get<any>(api, httpOptions);
  }

  GetListTyTrongCongTrinh(data) {
    let api = `${this.Url}/GetListTyTrongCongTrinh`;
    return this.http.post<any>(api, data, httpOptions);
  }

  SetTyTrongCongTrinh(data) {
    let api = `${this.Url}/SetTyTrongCongTrinh`;
    return this.http.post<any>(api, data, httpOptions);
  }

  ExportTyTrongCongTrinh(data) {
    let api = `${this.Url}/ExportTyTrongCongTrinh`;
    return this.http.post<any>(api, data, httpOptions);
  }

  ExportTyTrongDiaPhuong(data) {
    let api = `${this.Url}/ExportTyTrongDiaPhuong`;
    return this.http.post<any>(api, data, httpOptions);
  }

  ExportTyTrongPhanXayDung(data) {
    let api = `${this.Url}/ExportTyTrongPhanXayDung`;
    return this.http.post<any>(api, data, httpOptions);
  }

  ExportGia(data) {
    let api = `${this.Url}/ExportGia`;
    return this.http.post<any>(api, data, httpOptions);
  }

  GetHeaderTyTrongPhanXayDung(data) {
    let api = `${this.Url}/GetHeaderTyTrongPhanXayDung`;
    return this.http.post<any>(api, data, httpOptions);
  }

  GetHeaderTyTrongPhanThietBi(data) {
    let api = `${this.Url}/GetHeaderTyTrongPhanThietBi`;
    return this.http.post<any>(api, data, httpOptions);
  }

  GetListTyTrongPhanXayDung(data) {
    let api = `${this.Url}/GetListTyTrongPhanXayDung`;
    return this.http.post<any>(api, data, httpOptions);
  }
  // GetListVLNCMCongTrinh(data) {
  //   let api = `${this.Url}/GetListTyTrongPhanXayDung`;
  //   return this.http.post<any>(api, data, httpOptions);
  // }
  SetdsCongTrinhVatLieu(data) {
    let api = `${this.Url}/SetdsCongTrinhVatLieu`;
    return this.http.post<any>(api, data, httpOptions);
  }
  SetTyTrongPhanXayDung(data) {
    let api = `${this.Url}/SetTyTrongPhanXayDung`;
    return this.http.post<any>(api, data, httpOptions);
  }

  // GetdsVatLieuCongNhanMay(data) {
  //   let api = `${this.Url}/GetdsVatLieuCongNhanMay`;
  //   return this.http.post<any>(api, data, httpOptions);
  // }
  
  GetdsVatLieuNhanCongMay(data) {
    let api = `${this.Url}/GetdsVatLieuNhanCongMay`;
    return this.http.post<any>(api, data, httpOptions);
  }

  SetdsVatLieuNhanCongMay(data) {
    let api = `${this.Url}/SetdsVatLieuNhanCongMay`;
    return this.http.post<any>(api, data, httpOptions);
  }

  GetdsLoaiCongTrinh(data) {
    let api = `${this.Url}/GetdsLoaiCongTrinh`;
    return this.http.post<any>(api, data, httpOptions);
  }

  SetdsLoaiCongTrinh(data) {
    let api = `${this.Url}/SetdsLoaiCongTrinh`;
    return this.http.post<any>(api, data, httpOptions);
  }
  GetListdsVatLieuCongNhanMayTheoKhuVuc(data) {
    let api = `${this.Url}/GetListdsVatLieuCongNhanMayTheoKhuVuc`;
    return this.http.post<any>(api, data, httpOptions);
  }
  GetListVatLieuTheoCongTrinh(data) {
    let api = `${this.Url}/GetListVatLieuTheoCongTrinh`;
    return this.http.post<any>(api, data, httpOptions);
  }
  GetHeaderTyTrongDiaPhuong(data) {
    let api = `${this.Url}/GetHeaderTyTrongDiaPhuong`;
    return this.http.post<any>(api, data, httpOptions);
  }

  GetListTyTrongDiaPhuong(data) {
    let api = `${this.Url}/GetListTyTrongDiaPhuong`;
    return this.http.post<any>(api, data, httpOptions);
  }

  SetTyTrongDiaPhuong(data) {
    let api = `${this.Url}/SetTyTrongDiaPhuong`;
    return this.http.post<any>(api, data, httpOptions);
  }

  GetChiSoGiaKhuVuc(data) {
    let api = `${this.Url}/GetChiSoGiaKhuVuc`;
    return this.http.post<any>(api, data, httpOptions);
  }

  GetChiSoGiaDiaPhuongKhuVuc(data) {
    let api = `${this.Url}/GetChiSoGiaDiaPhuongKhuVuc`;
    return this.http.post<any>(api, data, httpOptions);
  }

  GetHeaderChiSoGiaDiaPhuongCongTrinh(data) {
    let api = `${this.Url}/GetHeaderChiSoGiaDiaPhuongCongTrinh`;
    return this.http.post<any>(api, data, httpOptions);
  }

  GetChiSoGiaDiaPhuongCongTrinh(data) {
    let api = `${this.Url}/GetChiSoGiaDiaPhuongCongTrinh`;
    return this.http.post<any>(api, data, httpOptions);
  }

  SetTyTrongVLNCM(data) {
    let api = `${this.Url}/SetTyTrongVLNCM`;
    return this.http.post<any>(api, data, httpOptions);
  }

  // ////////////////////////////////////////

  GetGiaThietBi(data) {
    let api = `${this.Url}/GetGiaThietBi`;
    return this.http.post<any>(api, data, httpOptions);
  }

  SetGiaThietBi(data) {
    let api = `${this.Url}/SetGiaThietBi`;
    return this.http.post<any>(api, data, httpOptions);
  }

  ImportGiaThietBi(FileName, IDdmKhuVuc) {
    let api = `${this.Url}/ImportGiaThietBi?FileName=${FileName}&IDdmKhuVuc=${IDdmKhuVuc}`;
    return this.http.get<any>(api, httpOptions);
  }

  ExportGiaThietBi(data) {
    let api = `${this.Url}/ExportGiaThietBi`;
    return this.http.post<any>(api, data, httpOptions);
  }

  // -------------------------------

  GetListTyTrongPhanThietBi(data) {
    let api = `${this.Url}/GetListTyTrongPhanThietBi`;
    return this.http.post<any>(api, data, httpOptions);
  }

  SetTyTrongThietBi(data) {
    let api = `${this.Url}/SetTyTrongThietBi`;
    return this.http.post<any>(api, data, httpOptions);
  }

  ImportTytrongThietBi(FileName, IDdmKhuVuc, Nam) {
    let api = `${this.Url}/ImportTytrongThietBi?FileName=${FileName}&IDdmKhuVuc=${IDdmKhuVuc}&Nam=${Nam}`;
    return this.http.get<any>(api, httpOptions);
  }

  ExportTyTrongPhanThietBi(data) {
    let api = `${this.Url}/ExportTyTrongPhanThietBi`;
    return this.http.post<any>(api, data, httpOptions);
  }

  TinhChiSoGia(data) {
    let api = `${this.Url}/TinhChiSoGia`;
    return this.http.post<any>(api, data, httpOptions);
  }
  
}