import { Component, OnInit, HostListener } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SuatVonDauTuService } from './../../../../services/suatvondautu.service';
import { DanhmucService } from './../../../../services/danhmuc.service';
import { ToastrService } from 'ngx-toastr';
import { FileUploader, FileItem, ParsedResponseHeaders } from "ng2-file-upload";
import { HostService } from './../../../../services/host.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { MyService } from './../../../../services/my.service';
import { FormatNumber, FormatStringToNumber } from 'src/app/services/convertnumbe.service';
import { HienthicongtrinhdaidienmodalComponent } from 'src/app/danhmucdungchung/NgbModal/hienthicongtrinhdaidienmodal/hienthicongtrinhdaidienmodal.component';

declare var $: any;

@Component({
  selector: 'app-capnhatcongtrinhdienhinh',
  templateUrl: './capnhatcongtrinhdienhinh.component.html',
  styleUrls: ['./capnhatcongtrinhdienhinh.component.css']
})
export class CapnhatcongtrinhdienhinhComponent implements OnInit {
  type: any = '';
  item: any = {};
  USER: any = {};
  ItemPOST: any = {};
  dsCongTrinh: any = {};
  header: any = "";
  IDParent: any = {};
  obj: any = {};
  uploader: FileUploader;
  listcapcongtrinh: any = [];
  listdmgiaidoanduan: any = [];
  listnhomcongtrinh: any = [];
  objchitieu: any = {};
  Nam: any = 0;
  IDdmTinh: any = 0;
  listDanhMucDungChung: any = [];
  listPhuongPhapTinh: any = [];
  KhuVuc: any = 0;
  listNam: any = [];
  listThang: any = [];
  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private SuatVonDauTuService: SuatVonDauTuService,
    private DanhmucService: DanhmucService,
    private toastr: ToastrService,
    private MyService: MyService,
  ) { }

  ngOnInit() {
    // this.reAdjust();
    this.USER.UserName = JSON.parse(localStorage.getItem('currentUser')).UserName;
    this.obj.FileUpload = [];
    let data: any = {
      Ma: 'DinhLuong',
      Ten: 'Phương pháp định lượng các yếu tố đầu vào'
    }
    let data1: any = {
      Ma: 'ThongKe',
      Ten: 'Phương pháp khảo sát giá bộ phận kết cấu công trình'
    }
    this.listPhuongPhapTinh.push(data)
    this.listPhuongPhapTinh.push(data1)
    if (this.type == 'themmoi') {
      this.header = "Thêm mới";
      this.ItemPOST = {
        "ChiPhiChung": {
        },
        "ThongTinChung": {
        },
        "ThongTinKinhTe": {
        },
        "listChiPhiVatLieu": [],
        "listChiPhiNhanCong": [],
        "listChiPhiMay": [],
        "listChiPhiCongViec": [],
        "fileDinhKems": [],
      }
      this.ItemPOST.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
      this.ItemPOST.PhuongPhapTinh = this.listPhuongPhapTinh[0].Ma;
      this.ItemPOST.HeSoMayThiCong = 1;
      this.ItemPOST.HeSoVatLieu = 1;
      this.ItemPOST.HeSoNhanCong = 1;
    }
    else {
      this.header = "Cập nhật";
      this.ItemPOST = Object.assign({}, this.item);
      let dataFiles: any = [];
      this.ItemPOST.fileDinhKems.forEach(element => {
        let dataFile: any = {
          "NameLocal": element.TenGoc,
          "Name": element.TenGui,
          "DuongDan": element.DuongDan,
          "NoiDung": element.NoiDung,
        }
        dataFiles.push(dataFile)
      });
      this.obj.FileUpload = dataFiles;
      if(this.ItemPOST.ThongTinChung.ThoiGianLapDuLieuUnix !== 0 && this.ItemPOST.ThongTinChung.ThoiGianLapDuLieuUnix !== undefined
        && this.ItemPOST.ThongTinChung.ThoiGianLapDuLieuUnix !== null){
        this.ItemPOST.ThongTinChung.ThoiGianLapDuLieu =  this.MyService.unixToDate(this.ItemPOST.ThongTinChung.ThoiGianLapDuLieuUnix);
      }
    }
    this.obj.FileUploaded = [];
    this.uploader = new FileUploader({
      url: `${HostService.serverUrl}FileUploader/Post`,
      headers: [{ name: 'Accept', value: 'application/json' }],
      autoUpload: true,
    });
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = true;
    };
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteItem = (item, response, status, headers) => this.onCompleteItem(item, response, status, headers);
    this.GetListCapCongTrinh();
    this.GetListNhomCongTrinh();
    this.GetListdmDungChung();
    this.GetListdmGiaiDoanDuAn();
    this.GetListNam()
    this.GetListThang()
  }

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {

  }

  onCompleteItem = (item: any, response: any, status: any, headers: any) => {
    console.log(response)
    let res = JSON.parse(response);
    // res[0].Id = 0;
    // this.obj.FileUpload = [];
    this.obj.FileUpload.push(res[0]);
  };

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
  }
  GetListCapCongTrinh() {
    let data: any = {
      CurrentPage: 0,
    };
    this.DanhmucService.GetListdmCapCongTrinh(data).subscribe(res => {
      this.listcapcongtrinh = res
    });
  }
  GetListNhomCongTrinh() {
    let data: any = {
      CurrentPage: 0,
    };
    this.DanhmucService.GetListdmNhomCongTrinh(data).subscribe(res => {
      this.listnhomcongtrinh = res
    });
  }
  GetListdmGiaiDoanDuAn() {
    let data: any = {
      CurrentPage: 0,
    };
    this.DanhmucService.GetListdmGiaiDoanDuAn(data).subscribe(res => {
      this.listdmgiaidoanduan = res
    });
  }
  TinhGiaTri(item) {
    console.log( parseInt(item.KhoiLuong))
    item.KhoiLuong = FormatStringToNumber(item.KhoiLuong);
    item.GiaTri = Math.round((item.DonGia || 0) * parseFloat(item.KhoiLuong || 0));
    item.GiaTriSauThue = Math.round(item.GiaTri * (100 + parseInt(item.ThueGiaTriGiaTang || 0))/ 100);
  }
  
  SetData() {
    let data: any =
    {
      "ID": this.type == 'themmoi' ? 0 : this.ItemPOST.ID,
      "Ten": this.ItemPOST.ThongTinChung.Ten != undefined && this.ItemPOST.ThongTinChung.Ten != null ? this.ItemPOST.ThongTinChung.Ten : "",
      "IDParent": this.type == 'themmoi' ? this.IDParent.ID : this.IDParent.IDParent,
      "Loai": 1,
      "check": true,
      "IDdmTinh": this.IDdmTinh,
      "IDThongTinUser": this.ItemPOST.IDThongTinUser,
      "LoaiDanhMuc": "GiaBoPhan",
      "PhuongPhapTinh": this.ItemPOST.PhuongPhapTinh,
      "ChiPhiChung": {
        "ID": this.type == 'themmoi' ? 0 : this.ItemPOST.ChiPhiChung.ID,
        "IDCongTrinh": this.type == 'themmoi' ? 0 : this.ItemPOST.ThongTinChung.ID,
        "IDdmChiTieu": this.ItemPOST.ID,
        "ChiPhiXayDung": this.ItemPOST.ChiPhiChung.ChiPhiXayDung != undefined && this.ItemPOST.ChiPhiChung.ChiPhiXayDung != null ? this.ItemPOST.ChiPhiChung.ChiPhiXayDung : 0,
        "ChiPhiThietBi": this.ItemPOST.ChiPhiChung.ChiPhiThietBi != undefined && this.ItemPOST.ChiPhiChung.ChiPhiThietBi != null ? this.ItemPOST.ChiPhiChung.ChiPhiThietBi : 0,
        "ChiPhiQuanLiDuAn": this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn != undefined && this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn != null ? this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn : 0,
        "ChiPhiTuVanDauTu": this.ItemPOST.ChiPhiChung.ChiPhiTuVanDauTu != undefined && this.ItemPOST.ChiPhiChung.ChiPhiTuVanDauTu != null ? this.ItemPOST.ChiPhiChung.ChiPhiTuVanDauTu : 0,
        "ChiPhiKhac": this.ItemPOST.ChiPhiChung.ChiPhiKhac != undefined && this.ItemPOST.ChiPhiChung.ChiPhiKhac != null ? this.ItemPOST.ChiPhiChung.ChiPhiKhac : 0,
        "ChiPhiDuPhong": this.ItemPOST.ChiPhiChung.ChiPhiDuPhong != undefined && this.ItemPOST.ChiPhiChung.ChiPhiDuPhong != null ? this.ItemPOST.ChiPhiChung.ChiPhiDuPhong : 0,
        "DonViCongSuat": this.ItemPOST.ChiPhiChung.DonViCongSuat != undefined && this.ItemPOST.ChiPhiChung.DonViCongSuat != null ? this.ItemPOST.ChiPhiChung.DonViCongSuat : 0,
        "HeSoDieuChinhThoiGian": this.ItemPOST.ChiPhiChung.HeSoDieuChinhThoiGian != undefined && this.ItemPOST.ChiPhiChung.HeSoDieuChinhThoiGian != null ? this.ItemPOST.ChiPhiChung.HeSoDieuChinhThoiGian : 0,
        "HeSoDieuChinhKhuVuc": this.ItemPOST.ChiPhiChung.HeSoDieuChinhKhuVuc != undefined && this.ItemPOST.ChiPhiChung.HeSoDieuChinhKhuVuc != null ? this.ItemPOST.ChiPhiChung.HeSoDieuChinhKhuVuc : 0,
        "VonDauTu": this.ItemPOST.ChiPhiChung.VonDauTu != undefined && this.ItemPOST.ChiPhiChung.VonDauTu != null ? this.ItemPOST.ChiPhiChung.VonDauTu : 0,
        "DuToan": this.ItemPOST.ChiPhiChung.DuToan != undefined && this.ItemPOST.ChiPhiChung.DuToan != null ? this.ItemPOST.ChiPhiChung.DuToan : 0,
        "Nam": this.Nam,
        "Ten": this.ItemPOST.ThongTinChung.Ten != undefined && this.ItemPOST.ThongTinChung.Ten != null ? this.ItemPOST.ThongTinChung.Ten : "",
      },
      "ThongTinChung": {
        "ID": this.type == 'themmoi' ? 0 : this.ItemPOST.ThongTinChung.ID,
        "Ten": this.ItemPOST.ThongTinChung.Ten != undefined && this.ItemPOST.ThongTinChung.Ten != null ? this.ItemPOST.ThongTinChung.Ten : "",
        "IDdmCapCongTrinh": this.ItemPOST.ThongTinChung.IDdmCapCongTrinh != undefined ? this.ItemPOST.ThongTinChung.IDdmCapCongTrinh : 0,
        "IDdmNhomCongTrinh": this.ItemPOST.ThongTinChung.IDdmNhomCongTrinh != undefined ? this.ItemPOST.ThongTinChung.IDdmNhomCongTrinh : 0,
        "IDdmChiTieu": this.ItemPOST.ID,
        "IDdmTinh": this.IDdmTinh,
        "DiaDiem": this.ItemPOST.ThongTinChung.DiaDiem != undefined && this.ItemPOST.ThongTinChung.DiaDiem != null ? this.ItemPOST.ThongTinChung.DiaDiem : "",
        "CongSuat": this.ItemPOST.ThongTinChung.CongSuat != undefined && this.ItemPOST.ThongTinChung.CongSuat != null ? this.ItemPOST.ThongTinChung.CongSuat : "",
        "NangLuc": this.ItemPOST.ThongTinChung.NangLuc != undefined && this.ItemPOST.ThongTinChung.NangLuc != null ? this.ItemPOST.ThongTinChung.NangLuc : "",
        "QuyChuan": this.ItemPOST.ThongTinChung.QuyChuan != undefined && this.ItemPOST.ThongTinChung.QuyChuan != null ? this.ItemPOST.ThongTinChung.QuyChuan : "",
        "TieuChuan": this.ItemPOST.ThongTinChung.TieuChuan != undefined && this.ItemPOST.ThongTinChung.TieuChuan != null ? this.ItemPOST.ThongTinChung.TieuChuan : "",
        "ThoiGianSuDung": this.ItemPOST.ThongTinChung.ThoiGianSuDung != undefined && this.ItemPOST.ThongTinChung.ThoiGianSuDung != null ? this.ItemPOST.ThongTinChung.ThoiGianSuDung : "",
        "Loai": 1,
        "DienTich": this.ItemPOST.ThongTinChung.DienTich != undefined && this.ItemPOST.ThongTinChung.DienTich != null ? this.ItemPOST.ThongTinChung.DienTich : "",
        "CoChe": this.ItemPOST.ThongTinChung.CoChe != undefined && this.ItemPOST.ThongTinChung.CoChe != null ? this.ItemPOST.ThongTinChung.CoChe : "",
        "ThongTinKhac": this.ItemPOST.ThongTinChung.ThongTinKhac != undefined && this.ItemPOST.ThongTinChung.ThongTinKhac != null ? this.ItemPOST.ThongTinChung.ThongTinKhac : "",
        "GiaiPhapKetCau": this.ItemPOST.ThongTinChung.GiaiPhapKetCau != undefined && this.ItemPOST.ThongTinChung.GiaiPhapKetCau != null ? this.ItemPOST.ThongTinChung.GiaiPhapKetCau : "",
        "CongNgheThiCong": this.ItemPOST.ThongTinChung.CongNgheThiCong != undefined && this.ItemPOST.ThongTinChung.CongNgheThiCong != null ? this.ItemPOST.ThongTinChung.CongNgheThiCong : "",
        "Nam": this.Nam,
        "IDdmGiaiDoanDuAn": this.ItemPOST.ThongTinChung.IDdmGiaiDoanDuAn,
        "NamXayDung": this.ItemPOST.ThongTinChung.NamXayDung,
        "NamSuDung": this.ItemPOST.ThongTinChung.NamSuDung,
        "ThangSuDung": this.ItemPOST.ThongTinChung.ThangSuDung,
        "ChuDauTu": this.ItemPOST.ThongTinChung.ChuDauTu,
        "ThoiGianLapDuLieuUnix": this.MyService.ghepdateUnix(this.ItemPOST.ThongTinChung.ThoiGianLapDuLieu),
      },
      "ThongTinKinhTe": {
        "ID": this.type == 'themmoi' ? 0 : this.ItemPOST.ThongTinKinhTe.ID,
        "IDdmChiTieu": this.ItemPOST.ID,
        "IDCongTrinh": this.type == 'themmoi' ? 0 : this.ItemPOST.ThongTinChung.ID,
        "NguonVon": this.ItemPOST.ThongTinKinhTe.NguonVon != undefined && this.ItemPOST.ThongTinKinhTe.NguonVon != null ? this.ItemPOST.ThongTinKinhTe.NguonVon : "",
        "ChiTieuTaiChinhKinhTe": this.ItemPOST.ThongTinKinhTe.ChiTieuTaiChinhKinhTe != undefined && this.ItemPOST.ThongTinKinhTe.ChiTieuTaiChinhKinhTe != null ? this.ItemPOST.ThongTinKinhTe.ChiTieuTaiChinhKinhTe : "",
        "HinhThucDauTu": this.ItemPOST.ThongTinKinhTe.HinhThucDauTu != undefined && this.ItemPOST.ThongTinKinhTe.HinhThucDauTu != null ? this.ItemPOST.ThongTinKinhTe.HinhThucDauTu : "",
        "TyGiaNgoaiTe": this.ItemPOST.ThongTinKinhTe.TyGiaNgoaiTe != undefined && this.ItemPOST.ThongTinKinhTe.TyGiaNgoaiTe != null ? this.ItemPOST.ThongTinKinhTe.TyGiaNgoaiTe : 0,
        "Nam": this.Nam,
        "ThongTinKhac": this.ItemPOST.ThongTinKinhTe.ThongTinKhac != undefined && this.ItemPOST.ThongTinKinhTe.ThongTinKhac != null ? this.ItemPOST.ThongTinKinhTe.ThongTinKhac : "",
      },
      "listChiPhiVatLieu": this.ItemPOST.listChiPhiVatLieu,
      "listChiPhiNhanCong": this.ItemPOST.listChiPhiNhanCong,
      "listChiPhiMay": this.ItemPOST.listChiPhiMay,
      "listChiPhiCongViec": this.ItemPOST.listChiPhiCongViec,
      "HeSoVatLieu": this.ItemPOST.HeSoVatLieu,
      "HeSoNhanCong": this.ItemPOST.HeSoNhanCong,
      "HeSoMayThiCong": this.ItemPOST.HeSoMayThiCong,
      "KhuVuc": this.KhuVuc,
      

    };
    let fileDinhKems: any = [];
    this.obj.FileUpload.forEach(element => {
      let dataFile: any = {
        "ID": 0,
        "TenGoc": element.NameLocal,
        "TenGui": element.Name,
        "NoiDung": element.NoiDung,
      }
      fileDinhKems.push(dataFile)
    });
    data.fileDinhKems = fileDinhKems
    return data;
  }

  XacNhan() {
    if (this.ItemPOST.ThongTinChung.Ten == undefined || this.ItemPOST.ThongTinChung.Ten == null || this.ItemPOST.ThongTinChung.Ten == "") {
      this.toastr.error("Bạn chưa nhập tên công trình");
    }
    else {
      this.SuatVonDauTuService.SetChiPhiCongTrinhBoPhanKetCau(this.SetData()).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.Onclose();
        }
        else {
          this.toastr.error(res.message);
        }
      });
    }
  }
  Onclose() {
    this.activeModal.close();
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }
  GetdsVatLieu(index) {
    if (index == -1) {
      let data: any = {
        TendmVatLieuNhanCongMay: "",
        DonGia: 0,
        KhoiLuong: '0',
        DonviTinh: "",
        ThueGiaTriGiaTang: 0,
        GiaTriSauThue: 0,
      }
      this.ItemPOST.listChiPhiVatLieu.unshift(data);
    }
    else {
      this.ItemPOST.listChiPhiVatLieu.splice(index, 1);
    }
  }
  GetdsNhanCong(index) {
    if (index == -1) {
      let data: any = {
        TendmVatLieuNhanCongMay: "",
        DonGia: 0,
        KhoiLuong: '0',
        DonviTinh: "",
        ThueGiaTriGiaTang: 0,
        GiaTriSauThue: 0,
      }
      this.ItemPOST.listChiPhiNhanCong.unshift(data);
    }
    else {
      this.ItemPOST.listChiPhiNhanCong.splice(index, 1);
    }
  }
  GetdsMayThiCong(index) {
    if (index == -1) {
      let data: any = {
        TendmVatLieuNhanCongMay: "",
        DonGia: 0,
        KhoiLuong: '0',
        DonviTinh: "",
        ThueGiaTriGiaTang: 0,
        GiaTriSauThue: 0,
      }
      this.ItemPOST.listChiPhiMay.unshift(data);
    }
    else {
      this.ItemPOST.listChiPhiMay.splice(index, 1);
    }
  }
  GetdsCongViec(index) {
    if (index == -1) {
      let data: any = {
        TendmVatLieuNhanCongMay: "",
        DonGia: 0,
        KhoiLuong: '0',
        DonviTinh: "",
        ThueGiaTriGiaTang: 0,
        GiaTriSauThue: 0,
      }
      this.ItemPOST.listChiPhiCongViec.unshift(data);
    }
    else {
      this.ItemPOST.listChiPhiCongViec.splice(index, 1);
    }
  }
  changetabthongtinchiphicongtrinh() {

  }
  GetListdmDungChung() {
    this.listDanhMucDungChung = [];
    this.DanhmucService.GetListdmDungChungCongTrinhDienHinh('CongTrinhDienHinh', 0, this.USER.UserName).subscribe(res => {
      this.listDanhMucDungChung = res;
    });
  }
  LuaChonDanhMucdungChung() {
    const modalRef = this.modalService.open(HienthicongtrinhdaidienmodalComponent, { backdrop: 'static', size:'xl' });
    modalRef.componentInstance.LoaiDanhMucDungChung = 'CongTrinhDienHinh';
    modalRef.componentInstance.listDanhMucDungChungCopy = this.listDanhMucDungChung;
    modalRef.componentInstance.Nam = this.Nam;
    modalRef.componentInstance.LoaiDanhMuc = "GiaBoPhanKetCau";
    modalRef.componentInstance.IDThongTinUser = this.objchitieu.IDThongTinUser;
    modalRef.result.then((data) => {
      let IDThongTinChung: any = this.ItemPOST.ThongTinChung.ID;
      let IDChiPhiChung: any = this.ItemPOST.ChiPhiChung.ID;
      let IDThongTinKinhTe: any = this.ItemPOST.ThongTinKinhTe.ID;
      this.ItemPOST.ThongTinChung = data.data.ThongTinChung;
      this.ItemPOST.ChiPhiChung = data.data.ChiPhiChung;
      this.ItemPOST.PhuongPhapTinh = 'DinhLuong';
      this.ItemPOST.ThongTinKinhTe = data.data.ThongTinKinhTe;
      this.ItemPOST.listChiPhiVatLieu = data.data.listChiPhiVatLieu;
      this.ItemPOST.listChiPhiNhanCong = data.data.listChiPhiNhanCong;
      this.ItemPOST.listChiPhiMay = data.data.listChiPhiMay;
      this.ItemPOST.listChiPhiCongViec = data.data.listChiPhiCongViec;
      this.ItemPOST.ThongTinChung.ID = IDThongTinChung;
      this.ItemPOST.ChiPhiChung.ID = IDChiPhiChung;
      this.ItemPOST.ThongTinKinhTe.ID = IDThongTinKinhTe;
      this.ItemPOST.HeSoVatLieu = data.data.HeSoVatLieu;
      this.ItemPOST.HeSoNhanCong = data.data.HeSoNhanCong;
      this.ItemPOST.HeSoMayThiCong = data.data.HeSoMayThiCong;
      this.converSo();
    }, (reason) => {
    });
  }
  saveDanhMucdungChung() {
    if (this.ItemPOST.ThongTinChung.Ten == undefined || this.ItemPOST.ThongTinChung.Ten == null || this.ItemPOST.ThongTinChung.Ten == "") {
      this.toastr.error("Bạn chưa nhập tên công trình");
    }
    else {
      let data: any = {
        "Ten": this.ItemPOST.ThongTinChung.Ten,
        "LoaiDanhMuc": 'CongTrinhDienHinh',
        "dmCongTrinhDienHinh": this.SetData()
      }
      this.DanhmucService.KiemTraTrungdmDungChung(this.ItemPOST.ThongTinChung.Ten, this.USER.UserName).subscribe(resGhiDe => {
        if (resGhiDe.State === 0) {
          const modalRef = this.modalService.open(ThongbaoComponent, { backdrop: 'static' });
          modalRef.componentInstance.thongbao = "Bạn có chắc chắn muốn ghi đè?";
          modalRef.result.then((any) => {
            data.ID = resGhiDe.objectReturn.ID;
            this.DanhmucService.SaveCongTrinhDienHinh(data).subscribe(res => {
              if (res.State === 1) {
                this.toastr.success(res.message);
                this.Onclose();
              }
              else {
                this.toastr.error(res.message);
              }
            });
          }, (reason) => {
          });
        }
        else {
          this.DanhmucService.SaveCongTrinhDienHinh(data).subscribe(res => {
            if (res.State === 1) {
              this.toastr.success(res.message);
            }
            else {
              this.toastr.error(res.message);
            }
          });
        }
      });
    }
  }
  taixuong(tenfile) {
    console.log(tenfile)
    this.MyService.taixuong(tenfile);
  }
  GetListNam() {
    var listNam = [];
    this.listNam = [];
    for (let i = 1970; i <= 2050; i++) {
      let data: any = {
        "ma": i,
        "ten": i
      }
      listNam.push(data)
    }
    this.listNam = listNam;
  }
  GetListThang() {
    var listThang = [];
    this.listThang = [];
    for (let i = 1; i <= 12; i++) {
      let data: any = {
        "ma": i,
        "ten": i
      }
      listThang.push(data)
    }
    this.listThang = listThang;
  }
  converSo(){
    this.ItemPOST.listChiPhiVatLieu.forEach(element => {
      this.TinhGiaTri(element);
      element.KhoiLuong = FormatNumber(element.KhoiLuong);
    });
    this.ItemPOST.listChiPhiVatLieu.forEach(element => {
      this.TinhGiaTri(element)
      element.KhoiLuong = FormatNumber(element.KhoiLuong);
    });
    this.ItemPOST.listChiPhiNhanCong.forEach(element => {
      this.TinhGiaTri(element);
      element.KhoiLuong = FormatNumber(element.KhoiLuong);
    });
    this.ItemPOST.listChiPhiMay.forEach(element => {
      this.TinhGiaTri(element);
      element.KhoiLuong = FormatNumber(element.KhoiLuong);
    });
    this.ItemPOST.listChiPhiCongViec.forEach(element => {
      this.TinhGiaTri(element);
      element.KhoiLuong = FormatNumber(element.KhoiLuong);
    });
    if (this.ItemPOST.HeSoVatLieu == undefined || this.ItemPOST.HeSoVatLieu == null) {
      this.ItemPOST.HeSoVatLieu = 1;
    }
    else {
      this.ItemPOST.HeSoVatLieu = FormatNumber(this.ItemPOST.HeSoVatLieu);
    }
    if (this.ItemPOST.HeSoNhanCong == undefined || this.ItemPOST.HeSoNhanCong == null)
      this.ItemPOST.HeSoNhanCong = 1;
    else
      this.ItemPOST.HeSoNhanCong = FormatNumber(this.ItemPOST.HeSoNhanCong);

    if (this.ItemPOST.HeSoMayThiCong == undefined || this.ItemPOST.HeSoMayThiCong == null)
      this.ItemPOST.HeSoMayThiCong = 1;
    else
      this.ItemPOST.HeSoMayThiCong = FormatNumber(this.ItemPOST.HeSoMayThiCong);
  }
}
