import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from 'src/app/services/danhmuc.service';
import { MyService } from 'src/app/services/my.service';

@Component({
  selector: 'app-viewloaicongtrinhdungchung',
  templateUrl: './viewloaicongtrinhdungchung.component.html',
  styleUrls: ['./viewloaicongtrinhdungchung.component.css']
})
export class ViewloaicongtrinhdungchungComponent implements OnInit {
  item: any = {};
  listdmLoaiCongTrinh : any = [];
  Listtree: any = [];
  constructor(
    private activeModal: NgbActiveModal,
    private DanhmucService: DanhmucService,
    private MyService: MyService,
  ) { }

  ngOnInit() {
    this.dsLoaiCongTrinh();
  }
  Onclose() {
    this.activeModal.close();
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }
  dsLoaiCongTrinh(){
    this.DanhmucService.getListdmLoaiCongTrinhDungChung(this.item.ID).subscribe(res => {
      this.listdmLoaiCongTrinh = res
      this.listdmLoaiCongTrinh.filter(obj => {
        obj.collspan = true;
        obj.check = false;
        obj.select = false;
      });
      this.Listtree = this.MyService.unflatten(this.listdmLoaiCongTrinh);
  })
}
}
