import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './../../services/authentication.service';
import { Router } from '@angular/router';
import { MyService } from './../../services/my.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DoimatkhauComponent } from 'src/app/danhmuc/danhmucuser/NgbModal/doimatkhau/doimatkhau.component';
import { ThemMoiDuAnDanhMucDungChungComponent } from 'src/app/login/login/NgbModal/themmoiduancongtrinhdienhinh/themmoiduancongtrinhdienhinh.component';
import { ThongTinBanDauComponent } from 'src/app/login/login/NgbModal/luachontinh/luachontinh.component';
import { DanhmucService } from 'src/app/services/danhmuc.service';
import { CongboComponent } from 'src/app/giaxaydung/dscongbo/congbo/congbo.component';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public checkLocalStorage: boolean = false;
  currentUser: any = {};
  ListLoaiCongTrinhSVDT: any  = [];
  ListLoaiCongTrinhGBP: any  = [];
  constructor(
    private modalService: NgbModal,
    public router: Router,
    private authService: AuthenticationService,
    private MyService: MyService,
    private DanhmucService: DanhmucService,
  ) {
    this.currentUser = Object.assign({}, JSON.parse(localStorage.getItem('currentUser')));
  }

  ngOnInit(): void {
    if (localStorage.getItem('currentUser') !== null) {
      this.MyService.checklogin = true;
    }
    else {
      this.MyService.checklogin = false;
      this.router.navigate(['/Login']);
    }
    this.GetlistdmLoaiCongTrinhSuatVon();

  }

  OutputLogin(e) {
    this.checkLocalStorage = e;
    this.currentUser = Object.assign({}, JSON.parse(localStorage.getItem('currentUser')));
  }

  logout() {
    this.MyService.checklogin = false;
    this.authService.logout();
  }
  ThongTin() {
    const modalRef = this.modalService.open(DoimatkhauComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.type = "capnhat";
    modalRef.componentInstance.item = Object.assign({}, JSON.parse(localStorage.getItem('currentUser')));
    modalRef.result.then((data) => {
      this.logout();
    }, (reason) => {
      // không
    });
  }
  GetlistdmLoaiCongTrinhSuatVon(){
    let listSV: any = []
    let listGBP: any = []
    this.DanhmucService.GetListdmLoaiCongTrinhThietLapBanDau().subscribe(res => {
      res.forEach(element => {
        if(element.LoaiDanhMuc == "SuatVon")
          listSV.push(element);
        else if(element.LoaiDanhMuc == "GiaBoPhan")
          listGBP.push(element);
      });
      this.ListLoaiCongTrinhSVDT = listSV;
      this.ListLoaiCongTrinhGBP = listGBP;
    });
  }
  themmoi() {
    const modalRef = this.modalService.open(ThongTinBanDauComponent, {size:'lg', backdrop: 'static' });
    modalRef.componentInstance.type = 'themmoi';  
    modalRef.componentInstance.isReload = true;  
    modalRef.componentInstance.ListLoaiCongTrinhSVDT = this.ListLoaiCongTrinhSVDT;  
    modalRef.componentInstance.ListLoaiCongTrinhGBP = this.ListLoaiCongTrinhGBP;  
    modalRef.result.then((data) => {
    }, (reason) => {
      // không
    });
  }
  CongTrinhDienHinh(){
    this.closemenu();

    const modalRef = this.modalService.open(ThemMoiDuAnDanhMucDungChungComponent, {size:'fullscreen',backdrop: 'static', scrollable: true});
    modalRef.componentInstance.type = "themmoi";
    modalRef.componentInstance.LoaidanhMuc = "Loaicongtrinh";
    modalRef.result.then(() => {
    }, (reason) => {
    });
  }
  downloadHDSD() {
    this.DanhmucService.GetHDSD()
      .subscribe((res: any) => {
        this.MyService.taixuong(res.DuongDan);
      });
  }
  myFunction() {
    document.getElementById("myDropdown").classList.toggle("show");
  }
  CongBo(){
    this.closemenu();

    let item: any ={
      ID: 0,
      fileUploadFileDinhKem: [],
      fileUpload: [],
      isThayThe: false,
      IDThongTinUser: 0
    }
    const modalRef = this.modalService.open(CongboComponent, { backdrop: 'static', size:'xl' });
    modalRef.componentInstance.ItemPOST = JSON.parse(JSON.stringify(item));
    modalRef.result.then((data) => {
    }, (reason) => {
    });
  }
  closemenu() {
    var dropdowns = document.getElementsByClassName("dropdown-content");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains('show')) {
        openDropdown.classList.remove('show');
      }
    }
  }
}
