import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../services/danhmuc.service';
import { SuatVonDauTuService } from './../../services/suatvondautu.service';
import { MyService } from './../../services/my.service';
import { ToastrService } from 'ngx-toastr';
import { TreeNode } from './../../scriptAll/TreeNode';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-giabophanketcaucongtrinh',
  templateUrl: './giabophanketcaucongtrinh.component.html',
  styleUrls: ['./giabophanketcaucongtrinh.component.css']
})
export class GiabophanketcaucongtrinhComponent implements OnInit {
  listItem: TreeNode[];
  listtinh: any = [];
  listnhomcongtrinh: any = [];
  listloaicongtrinh: any = [];
  listloaiheso: any = [];
  listnam: any = [];
  listloaicap1: any = [];
  listloaicap2: any = [];
  listloaicap3: any = [];
  objloaddata: any = {};
  title: any = {}
  files: TreeNode[];
  listkhuvuc:any = [];
  header: "";
  TenThongTinUser:"";
  listChuongCongTrinh: any = [];
  constructor(
    private modalService: NgbModal,
    private SuatVonDauTuService: SuatVonDauTuService,
    private MyService: MyService,
    private toastr: ToastrService,
    private DanhmucService: DanhmucService,
    private activatedRoute: ActivatedRoute,private router: Router,
    public spinner: NgxSpinnerService,
  ) {
    this.router.events.pipe( filter(event => event instanceof NavigationEnd)).subscribe(() => {  
      const rt = this.getChild(this.activatedRoute);  
      rt.data.subscribe(data => {  
        this.title = data.title;
      });  
    });
   }

  ngOnInit() {
    this.objloaddata.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
    this.objloaddata.Nam = JSON.parse(localStorage.getItem('thongtinuser')).Nam;
    this.TenThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).Ten;
    this.GetListdmKhuVuc();
    this.GetListdmLoaiCongTrinhParent();
  }
  GetListdmKhuVuc() {
    let data1: any = {
      Ma: 1,
      Ten: "Khu vực 1",
    }
    let data2: any = {
      Ma: 2,
      Ten: "Khu vực 2",
    }
    let data3: any = {
      Ma: 3,
      Ten: "Khu vực 3",
    }
    let data4: any = {
      Ma: 4,
      Ten: "Toàn quốc",
    }
    this.listkhuvuc.push(data1);
    this.listkhuvuc.push(data2);
    this.listkhuvuc.push(data3);
    this.listkhuvuc.push(data4);
    this.objloaddata.KhuVuc = this.listkhuvuc[0].Ma;
  }
  GetListdmLoaiCongTrinhParent() {
    this.DanhmucService.GetListdmLoaiCongTrinhParent(this.objloaddata.IDThongTinUser,'GiaBoPhan').subscribe(res => {
      this.listChuongCongTrinh = res;
      this.objloaddata.IDdmLoaiCongTrinh = this.listChuongCongTrinh[0].ID;
      this.refresh();
    });
  }
  getChild(activatedRoute: ActivatedRoute) {  
    if (activatedRoute.firstChild) {  
      return this.getChild(activatedRoute.firstChild);  
    } else {  
      return activatedRoute;  
    }  
  } 
  refresh() {
    this.objloaddata = {
      IDdmTinh: this.objloaddata.IDdmTinh,
      IDdmLoaiCongTrinh: this.objloaddata.IDdmLoaiCongTrinh,
      Nam: this.objloaddata.Nam,
      IDThongTinUser: this.objloaddata.IDThongTinUser,
      KhuVuc: this.objloaddata.KhuVuc,
    };   
    this.loaddata();
  }

  loaddata() {
    this.SuatVonDauTuService.GetListGiaBoPhanKetCau(this.objloaddata).subscribe(res => {
      this.files = res;
    });
  }
  TinhGiaBoPhanKetCauCongTrinh() {
    let data: any = {
      IDdmLoaiCongTrinh: this.objloaddata.IDdmLoaiCongTrinh,
      IDThongTinUser: this.objloaddata.IDThongTinUser,
      Nam: this.objloaddata.Nam,
      KhuVuc: this.objloaddata.KhuVuc,
    };
    this.spinner.show();
    this.SuatVonDauTuService.TinhGiaBoPhanKetCauCongTrinh(data).subscribe(res => {
      if (res.State === 1) {
        this.toastr.success(res.message);
        this.refresh();
      }
      else {
        this.toastr.error(res.message);
      }
      this.spinner.hide();
    });
  }
  xuatfile() {   
    this.SuatVonDauTuService.ExportGiaBoPhan(this.objloaddata).subscribe(res => {
      if (res.TenFile !== undefined) {
        this.MyService.taixuong(res.TenFile);
      }
      else {
        this.toastr.error(res.message);
      }
    });
  }
  xuatWord() {
    let data: any =
    {
      IDdmLoaiCongTrinh: this.objloaddata.IDdmLoaiCongTrinh,
      IDdmTinh: this.objloaddata.IDdmTinh,
      Nam: this.objloaddata.Nam,
      IDThongTinUser: this.objloaddata.IDThongTinUser,
      KhuVuc: this.objloaddata.KhuVuc,
    };
    this.SuatVonDauTuService.XuatBaoCaoGiaBoPhanWord(data).subscribe(res => {
      if (res.TenFile !== undefined) {
        this.MyService.taixuong(res.TenFile);
      }
      else {
        this.toastr.error(res.message);
      }
    });
  }
}
