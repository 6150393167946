import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from './../../../../services/api.service';
import { DanhmucService } from './../../../../services/danhmuc.service';
import { Router } from "@angular/router";
import { LuaChonCSGComponent } from 'src/app/login/login/NgbModal/luachon/luachon.component';
@Component({
  selector: 'app-capnhapthongtinuser',
  templateUrl: './capnhapthongtinuser.component.html',
  styleUrls: ['./capnhapthongtinuser.component.css']
})
export class CapnhapthongtinuserComponent implements OnInit {
  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private ApiService: ApiService,
    private toastr: ToastrService,
    private DanhmucService: DanhmucService,
    private router: Router,
  ) { }
  
  type: any = '';
  item: any = {};
  ItemPOST: any = {};
  header: any = "";
  IDParent: any = {};
  USER: any = {};
  isReload: any = false;
  ngOnInit() {
    if (this.type == 'themmoi') {
      this.header = "Thêm mới";
    }
    else {
      this.header = "Cập nhật";
      this.ItemPOST = Object.assign({}, this.item);
    }
    this.USER = Object.assign({}, JSON.parse(localStorage.getItem('currentUser')));
  }

  SetData() {
    let data: any = {
      "ID": this.type == 'themmoi' ? 0 : this.item.ID,
      "IDUser": this.USER.Id,
      "Ten": this.ItemPOST.Ten,
      "NgayTao": this.type == 'themmoi' ? null : this.item.property,
      "MoTa": this.ItemPOST.MoTa !== undefined && this.ItemPOST.MoTa !== "" ? this.ItemPOST.MoTa : "",
      "Nam": this.ItemPOST.Nam !== undefined && this.ItemPOST.Nam !== "" ? this.ItemPOST.Nam : "",
      "GhiChu": this.ItemPOST.GhiChu !== undefined && this.ItemPOST.GhiChu !== "" ? this.ItemPOST.GhiChu : "",
      "NoiDung": this.ItemPOST.NoiDung !== undefined && this.ItemPOST.NoiDung !== "" ? this.ItemPOST.NoiDung : "",
    }
    return data;
  }
  XacNhan() {
    if(this.ItemPOST.Ten == undefined || this.ItemPOST.Ten == null){
      this.toastr.error("Bạn chưa nhập tên suất vốn đầu tư");
    }
    else if(this.ItemPOST.Nam == undefined || this.ItemPOST.Nam == null){
      this.toastr.error("Bạn chưa nhập năm tính toán");
    }
    else if(this.ItemPOST.Nam < 0) {
      this.toastr.error("Bạn nhập sai định dạng năm");
    }
    else{
      this.router.navigate(['/SuatVonDauTu/NhapDinhMucSuatVonDanDung']);
      this.DanhmucService.SetThongTinUser(this.SetData()).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          localStorage.setItem('thongtinuser', JSON.stringify(res.objectReturn));
          location.reload()
        }
        else {
          this.toastr.error(res.message);
        }
      });
    }
  }
  Onclose() {
      this.activeModal.close();
  }

  Ondismiss() {
    if(this.isReload == true){
      this.activeModal.close();
      const modalRef = this.modalService.open(LuaChonCSGComponent, { backdrop: 'static' });
      modalRef.result.then((data) => {
      }, (reason) => {
        // không
      });
    }
    else
      this.activeModal.dismiss();
  }

}
